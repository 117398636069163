import React from "react";
import { CANONICAL_PARAMS } from "./utils/params";

export const ParametersContext = React.createContext({
  params: { CANONICAL_PARAMS },
  setParams: (_) => {},
  maxValue: 30,
  setMaxValue: (_) => {
    console.log("warning: setMaxValue not set");
  },
  maxDelta: 29,
  setMaxDelta: (_) => {},
  colorTheme: "0-18",
  setColorTheme: (_) => {},
  paramsHistory: [],
  addParamsToHistory: (_) => {},
  setParamsHistory: (_) => {},
  paramsRef: { current: CANONICAL_PARAMS },
  etichetta: null,
  setEtichetta: (_) => {},
  years: 0,
  setYears: (_) => {},
  videoColorTheme: "0-18",
  videoEtichetta: null,
  snapshotVideoParams: () => {},
  isVideoInSyncWithLogo: true,
});

export const PlayerContext = React.createContext({
  isPlaying: false,
  setIsPlaying: () => {},
  isRecording: false,
  setIsRecording: () => {},
  audioBlob: null,
  setAudioBlob: () => {},
  audioSamples: [],
  setAudioSamples: () => {},
  currentTime: 0,
  setCurrentTime: () => {},
  totalTime: 0,
  setTotalTime: () => {},
});

export const DEFAULT_FEATURES = {
  domanda: 'Cosa ti piace di Milano?',
  print: false,
  micAlwaysActive: false,
  send: false,
  customization: true,
  download: true,
  archive: true,
  showDidascalia: true,
  showDomanda: true,
};

export const FeaturesContext = React.createContext(DEFAULT_FEATURES);

export const MobileScreenContext = React.createContext(null);
