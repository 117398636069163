
export function getSupportedVideoMimeFormat() {
  if (MediaRecorder.isTypeSupported('video/mp4')) {
    return 'video/mp4'
  }
  return 'video/webm'
}

export function getSuppertedVideoExtension() {
  if (MediaRecorder.isTypeSupported('video/mp4')) {
    return 'mp4'
  }
  return 'webm'
}