import { useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import styles from "./CheckBrowser.module.css";

function checkIsChrome() {
  const ua = navigator.userAgent;
  if (ua.match(/chrome/i)) {
    return true;
  }
  if (/CriOS/i.test(ua) && /iphone|ipod|ipad/i.test(ua)) {
    return true;
  } else {
    return false;
  }
}

const isChrome = checkIsChrome()

export default function CheckBrowser() {
  const [modalBrowser, setModalBrowser] = useState(false);
  useEffect(() => {
    if (!isChrome) {
      setModalBrowser(true);
    }
  }, [setModalBrowser]);
  return (
    <Modal
      className={styles.Modal}
      isOpen={modalBrowser}
      toggle={() => setModalBrowser(false)}
      centered
    >
      <ModalHeader
        className={styles.ModalHeader}
        toggle={() => setModalBrowser(false)}
      >
        <div className={styles.TitleModal}>Browser</div>
      </ModalHeader>
      <ModalBody className={styles.ModalBody}>
        <div className={styles.TextModal}>
          Per una migliore esperienza si consiglia di utilizzare Google Chrome.
        </div>
      </ModalBody>
      <ModalFooter className={styles.ModalFooter}>
        <div className="w-100 d-flex justify-content-between align-items-center">
          <div
            onClick={() => setModalBrowser(false)}
            className={styles.ButtonCancel}
          >
            Chiudi
          </div>
          <a
            className={styles.ButtonConfirm}
            target="_blank"
            rel="noreferrer"
            href={"https://www.google.com/intl/it_it/chrome/"}
          >
            Scarica Chrome
          </a>
        </div>
      </ModalFooter>
    </Modal>
  );
}
