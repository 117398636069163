import BoxWithBorders, { BoxContent } from "../BoxWithBorders";
import styles from "./BoxSharing.module.css";
import facebookIcon from "./assets/facebook.svg";
import instagramIcon from "./assets/instagram.svg";
import emailIcon from "./assets/email.svg";

export default function BoxSharing({ toggleBox, isOpen, isModal = false, isPro }) {
  return (
    <BoxWithBorders
      isOpen={isOpen}
      isModal={isModal}
      isPro={isPro}
      showButtons={false}
      title="Condividi"
      toggleBox={toggleBox}
      bodyBox={
        <BoxContent>
          <div>
            <div className={`${styles.SharingWith} mt-5`}>
              Condividi tramite
            </div>
            <div className="mt-3">
              <div className={styles.SharingItem}>
                <div>
                  <img src={facebookIcon} alt="Facebook" />
                </div>
                <div className="ms-2">Facebook</div>
              </div>
              <div className={`${styles.SharingItem} mt-4`}>
                <div>
                  <img src={instagramIcon} alt="Instagram" />
                </div>
                <div className="ms-2">Instagram</div>
              </div>
              <div className={`${styles.SharingItem} mt-4`}>
                <div>
                  <img src={emailIcon} alt="Email" />
                </div>
                <div className="ms-2">Email</div>
              </div>
            </div>
            <div className="mt-5">
              <div className={styles.InstructionCopy}>
                Fai click per copiare il link
              </div>
              <div className={styles.CurrentUrl}>
                https://milano0.18.mgres?imgurl=https%iN
              </div>
            </div>
          </div>
        </BoxContent>
      }
    />
  );
}
