import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Auth from "./Auth";
import Login from "./pages/Login";
import PageNotFound from "./components/PageNotFound";
import { RequireAuth, RequireGuest } from "./routing";
import { AnimatedLogoInstallation } from "./pages/AnimatedLogoInstallation";
import Backend from "./pages/Backend";
import AnimatedLogo from "./pages/AnimatedLogo";
import MobileScreenProvider from "./MobileScreenProvider";
import AnimatedLogoArchive from "./pages/AnimatedLogoArchive";
import { useMemo } from "react";

function redirectToolUser(user) {
  if (!user.is_staff) {
    return "/tool";
  }
}

function inIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

export default function App() {
  const rootNotNeedAuth = useMemo(() => {
    const isInIframe = inIframe();
    return isInIframe;
  }, []);

  return (
    <MobileScreenProvider>
      <Auth>
        <Router>
          <Routes>
            {/* HOME GO TO Login / Backend  */}
            <Route path="/" element={<AnimatedLogo mode="basic" />} />
            {/* LOGIN */}
            <Route
              path="/login"
              element={
                <RequireGuest redirectTo="/backend">
                  <Login />
                </RequireGuest>
              }
            />
            {/* BACKEND */}
            <Route
              path="/backend/*"
              element={
                <RequireAuth redirectTest={redirectToolUser}>
                  <Backend />
                </RequireAuth>
              }
            />
            {/* PRO */}
            <Route
              path="/pro/:id"
              element={
                <RequireAuth redirectTest={redirectToolUser}>
                  <AnimatedLogoArchive />
                </RequireAuth>
              }
            />
            <Route
              path="/pro"
              element={
                <RequireAuth redirectTest={redirectToolUser}>
                  <AnimatedLogo mode="pro" />
                </RequireAuth>
              }
            />
            {/* TOOL */}
            <Route
              path="/tool"
              element={
                rootNotNeedAuth ? (
                  <AnimatedLogo mode="tool" />
                ) : (
                  <RequireAuth>
                    <AnimatedLogo mode="tool" />
                  </RequireAuth>
                )
              }
            />
            {/* Installazione Logo */}
            <Route path="/:token" element={<AnimatedLogoInstallation />} />
            {/* 404 */}
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </Router>
      </Auth>
    </MobileScreenProvider>
  );
}
