import JSZip from "jszip";
import { saveAs } from "file-saver";
import {
  fromSvgStringToImage,
  generateCombosSvgsString,
  makeLogoImage,
} from "../../utils/downloads";
import BoxWithBorders, { BoxContent } from "../BoxWithBorders";
import { useContext, useEffect, useMemo, useState } from "react";
import { ParametersContext, PlayerContext } from "../../contexts";
import styles from "./BoxExport.module.css";
import useDimensions from "react-use-dimensions";
import classNames from "classnames";
import { getSuppertedVideoExtension } from "../../utils/video";
import Logo from "../Logo";
import { COLOR_THEMES } from "../../utils/colors";
import Select from "react-select";

const PADDING = 10;

const OPTIONS_DIMENSIONS = [
  { value: 1500, label: "1500 px" },
  { value: 2000, label: "2000 px" },
  { value: 2500, label: "2500 px" },
  { value: 3000, label: "3000 px" },
  { value: 3500, label: "3500 px" },
];

const MiniCheck = ({ active, value, toggle }) => (
  <div
    onClick={() => toggle(value)}
    className={classNames(styles.Check, {
      [styles.Active]: active,
    })}
  />
);

function BoxExportBody({
  onConfirm,
  toggleBox,
  etichettaPrimaRiga,
  etichettaSecondaRiga,
}) {
  const { audioBlob } = useContext(PlayerContext);
  const { params, colorTheme } = useContext(ParametersContext);
  const [sizeDownload, setSize] = useState({ value: 1500, label: "1500 px" });

  const combos = useMemo(() => {
    const common = {
      etichettaPrimaRiga,
      etichettaSecondaRiga,
      ...params,
    };
    const combosToUse = [
      {
        ...common,
        containerBackground:
          "linear-gradient(to left top, white 0%, black 100%)",
        backgroundColor: "transparent",
        lettereColor: "#FFFFFF",
        maschera0Color: "rgba(255,255,255, 0.4)",
        mascheraPuntoColor: "rgba(255,255,255, 0.45)",
        maschera8Color: "rgba(255,255,255, 0.4)",
        etichettaBackgroundColor: "#FFFFFF",
        etichettaColor: "black",
        mixBlendModeRaggiera: "normal",
        key: "Milano018-bianco",
        colorTheme: ["0-6", "6-14", "14-18", "0-18"],
        etichettaAsMask: true,
      },
      {
        ...common,
        containerBackground: "#C6C6C6",
        backgroundColor: "transparent",
        maschera0Color: "rgba(0,0,0, 0.35)",
        mascheraPuntoColor: "rgba(0,0,0, 0.25)",
        maschera8Color: "rgba(0,0,0, 0.15)",
        etichettaBackgroundColor: "#D0D0D0",
        etichettaColor: "black",
        key: "Milano018-grigi",
        colorTheme: ["0-6", "6-14", "14-18", "0-18"],
      },
      {
        ...common,
        backgroundColor: "#0BC9CD",
        maschera0Color: "#0BC9CD",
        mascheraPuntoColor: "#0BC9CD",
        lettereColor: "white",
        maschera8Color: "#0BC9CD",
        etichettaBackgroundColor: "#337980",
        etichettaColor: "white",
        key: "Milano018-monocromo",
        colorTheme: ["0-6"],
      },
      {
        ...common,
        backgroundColor: "#FF8010",
        maschera0Color: "#F24F00",
        mascheraPuntoColor: "#F24F00",
        lettereColor: "white",
        maschera8Color: "#F24F00",
        etichettaBackgroundColor: "#DC0E00",
        etichettaColor: "white",
        key: "Milano018-monocromo",
        colorTheme: ["6-14"],
      },
      {
        ...common,
        backgroundColor: "#8267C6",
        maschera0Color: "#8267C6",
        mascheraPuntoColor: "#8267C6",
        lettereColor: "white",
        maschera8Color: "#8267C6",
        etichettaBackgroundColor: "#46239B",
        etichettaColor: "white",
        key: "Milano018-monocromo",
        colorTheme: ["14-18"],
      },
      {
        ...common,
        containerBackground:
          "linear-gradient(to left top, white 0%, black 100%)",
        backgroundColor: "transparent",
        maschera0Color: COLOR_THEMES[colorTheme].maschera0StrongColor,
        mascheraPuntoColor: COLOR_THEMES[colorTheme].mascheraPuntoStrongColor,
        lettereColor: "white",
        maschera8Color: COLOR_THEMES[colorTheme].maschera8StrongColor,
        key: "Milano018-colori-bianco",
        colorTheme: ["0-18"],
      },
      {
        ...common,
        containerBackground:
          "linear-gradient(to left top, white 0%, black 100%)",
        backgroundColor: "transparent",
        maschera0Color: COLOR_THEMES[colorTheme].maschera0StrongColor,
        mascheraPuntoColor: COLOR_THEMES[colorTheme].mascheraPuntoStrongColor,
        lettereColor: "white",
        maschera8Color: COLOR_THEMES[colorTheme].maschera8StrongColor,
        etichettaBackgroundColor: "#0bc9cd",
        etichettaColor: "white",
        key: "Milano018-colori-bianco",
        colorTheme: ["0-6"],
      },
      {
        ...common,
        containerBackground:
          "linear-gradient(to left top, white 0%, black 100%)",
        backgroundColor: "transparent",
        maschera0Color: COLOR_THEMES[colorTheme].maschera0StrongColor,
        mascheraPuntoColor: COLOR_THEMES[colorTheme].mascheraPuntoStrongColor,
        lettereColor: "white",
        maschera8Color: COLOR_THEMES[colorTheme].maschera8StrongColor,
        etichettaBackgroundColor: "#ff8010",
        etichettaColor: "white",
        key: "Milano018-colori-bianco",
        colorTheme: ["6-14"],
      },
      {
        ...common,
        containerBackground:
          "linear-gradient(to left top, white 0%, black 100%)",
        backgroundColor: "transparent",
        maschera0Color: COLOR_THEMES[colorTheme].maschera0StrongColor,
        mascheraPuntoColor: COLOR_THEMES[colorTheme].mascheraPuntoStrongColor,
        lettereColor: "white",
        maschera8Color: COLOR_THEMES[colorTheme].maschera8StrongColor,
        etichettaBackgroundColor: "#8367c7",
        etichettaColor: "white",
        key: "Milano018-colori-bianco",
        colorTheme: ["14-18"],
      },
      {
        ...common,
        containerBackground: "#C6C6C6",
        backgroundColor: "transparent",
        maschera0Color: "#DADADA",
        mascheraPuntoColor: "#C6C6C6",
        maschera8Color: "#DADADA",
        etichettaBackgroundColor: "#d0d0d0",
        etichettaColor: "black",
        key: "Milano018-grigi-etichetta",
        outline: true,
        colorTheme: ["0-6", "6-14", "14-18", "0-18"],
      },
      {
        ...common,
        containerBackground: "#C6C6C6",
        backgroundColor: "transparent",
        maschera0Color: COLOR_THEMES[colorTheme].maschera0Color,
        mascheraPuntoColor: COLOR_THEMES[colorTheme].mascheraPuntoColor,
        maschera8Color: COLOR_THEMES[colorTheme].maschera8Color,
        etichettaBackgroundColor:
          COLOR_THEMES[colorTheme].etichettaBackgroundColor,
        etichettaColor: COLOR_THEMES[colorTheme].etichettaColor,
        key: "Milano018-colori-etichetta",
        outline: true,
        colorTheme: ["0-6", "6-14", "14-18", "0-18"],
      },
      {
        ...common,
        containerBackground: "white",
        backgroundColor: "transparent",
        key: "Milano018-colori-nero",
        colorTheme: ["0-18"],
      },
    ];
    return combosToUse;
  }, [colorTheme, etichettaPrimaRiga, etichettaSecondaRiga, params]);

  const [outlineSvgImages, setOutlineSvgImages] = useState(null);
  useEffect(() => {
    const outlineCombos = combos.filter((c) => c.outline);
    async function makeSvgStrings() {
      const out = [];
      let i = 0;
      for await (const str of generateCombosSvgsString(outlineCombos)) {
        const combo = outlineCombos[i];
        out.push({
          str,
          key: combo.key,
          containerBackground: combo.containerBackground,
        });
        i++;
      }
      const outWblobs = await Promise.all(
        out.map((o) =>
          fromSvgStringToImage(o.str, 600, 600).then((blob) => ({
            blob,
            str: o.str,
            key: o.key,
            containerBackground: o.containerBackground,
          }))
        )
      );
      return outWblobs.map(({ blob, ...o }) => ({
        ...o,
        url: URL.createObjectURL(blob),
      }));
    }
    let cancel = false;
    let _urls = [];
    makeSvgStrings().then((out) => {
      if (!cancel) {
        _urls = out.map((o) => o.url);
        setOutlineSvgImages(out);
      }
    });
    return () => {
      cancel = true;
      _urls.forEach((u) => {
        URL.revokeObjectURL(u);
      });
    };
  }, [combos]);

  const variants = [];
  if (audioBlob) {
    variants.push("video");
  }
  const hasCustomization = etichettaPrimaRiga !== null;
  if (hasCustomization) {
    variants.push("custom");
  }

  combos.forEach((combo) => {
    variants.push(combo.key);
  });

  const [ref, dimensions] = useDimensions();

  const count = 3;
  const size =
    dimensions.width > 0
      ? (dimensions.width - (count - 1) * PADDING) / count
      : 0;

  const [selectedVariants, setSelectedVariants] = useState(variants);

  async function save() {
    const zip = new JSZip();
    if (selectedVariants.includes("video")) {
      zip.file(`video.${getSuppertedVideoExtension()}`, audioBlob);
    }
    let tasks = [];
    tasks.push(
      ...combos
        .filter(
          (d) =>
            d.colorTheme.includes(colorTheme) &&
            !d.outline &&
            selectedVariants.includes(d.key)
        )
        .map(async (combo) => {
          const svgNode = document.querySelector(
            `.export-${combo.key} .svg-container > svg`
          );
          const image = await makeLogoImage(
            svgNode,
            sizeDownload.value,
            sizeDownload.value
          );
          zip.file(`${combo.key}.png`, image);
        })
    );
    if (selectedVariants.includes("custom")) {
      tasks.push(
        (async () => {
          const svgNode = document.querySelector(
            ".export-custom .svg-container > svg"
          );
          const image = await makeLogoImage(
            svgNode,
            sizeDownload.value,
            sizeDownload.value
          );
          zip.file("Milano018-colori-nero.png", image);
        })()
      );
    }
    tasks.push(
      ...outlineSvgImages
        .filter((combo) => selectedVariants.includes(combo.key))
        .map(async (combo) => {
          const image = await fromSvgStringToImage(
            combo.str,
            sizeDownload.value,
            sizeDownload.value
          );
          zip.file(`${combo.key}.png`, image);
        })
    );
    await Promise.all(tasks);
    const zipBlob = await zip.generateAsync({ type: "blob" });
    saveAs(zipBlob, "logo.zip");
    onConfirm();
  }

  function toggle(value) {
    if (!selectedVariants.includes(value)) {
      setSelectedVariants((v) => v.concat(value));
    } else {
      setSelectedVariants((v) => v.filter((x) => x !== value));
    }
  }

  return (
    <BoxContent
      showButtons={true}
      titleClass="normal"
      textButtonConfirm="Scarica"
      title="Scarica versioni"
      toggleBox={toggleBox}
      onConfirm={save}
      isDisabled={!outlineSvgImages}
    >
      <div className="mt-3">
        <div className={styles.Question}>Definisci la dimensione</div>
        <div className="mt-3">
          <Select
            isSearchable={false}
            isClearable={false}
            value={sizeDownload}
            classNamePrefix={"dimensions"}
            onChange={(newValue) => {
              setSize(newValue);
            }}
            placeholder={"Seleziona una dimensione.."}
            options={OPTIONS_DIMENSIONS}
          />
        </div>
        <div className={styles.Caption}>Seleziona Contenuti</div>
        <div className={styles.SelectAll}>
          <MiniCheck
            active={selectedVariants.length === variants.length}
            toggle={() => {
              if (
                selectedVariants.length === 0 ||
                selectedVariants.length < variants.length
              ) {
                setSelectedVariants(variants);
              } else {
                setSelectedVariants([]);
              }
            }}
          />
          <div className={styles.SelectAllText}>Seleziona Tutto</div>
        </div>
        <div ref={ref} className={styles.PreviewContainer}>
          {size > 0 && (
            <>
              {variants.includes("video") && (
                <div className={styles.LogoWrapper}>
                  <Logo
                    width={size}
                    height={size}
                    {...params}
                    maschera0Color={COLOR_THEMES[colorTheme].maschera0Color}
                    mascheraPuntoColor={
                      COLOR_THEMES[colorTheme].mascheraPuntoColor
                    }
                    maschera8Color={COLOR_THEMES[colorTheme].maschera8Color}
                    etichettaPrimaRiga={etichettaPrimaRiga}
                    etichettaSecondaRiga={etichettaSecondaRiga}
                    etichettaColor={COLOR_THEMES[colorTheme].etichettaColor}
                    etichettaBackgroundColor={
                      COLOR_THEMES[colorTheme].etichettaBackgroundColor
                    }
                  />
                  {selectedVariants.includes("video") && (
                    <div className={styles.LogoActive} />
                  )}
                  <div
                    className={
                      selectedVariants.includes("video")
                        ? styles.MiniCamera
                        : styles.MiniCameraBlack
                    }
                  />
                  <MiniCheck
                    active={selectedVariants.includes("video")}
                    value={"video"}
                    toggle={toggle}
                  />
                </div>
              )}
              {variants.includes("custom") && (
                <div
                  className={classNames(styles.LogoWrapper, "export-custom")}
                >
                  {selectedVariants.includes("custom") && (
                    <div className={styles.LogoActive} />
                  )}
                  <Logo
                    {...params}
                    width={size}
                    height={size}
                    backgroundColor={"transparent"}
                    maschera0Color={COLOR_THEMES[colorTheme].maschera0Color}
                    mascheraPuntoColor={
                      COLOR_THEMES[colorTheme].mascheraPuntoColor
                    }
                    maschera8Color={COLOR_THEMES[colorTheme].maschera8Color}
                    etichettaPrimaRiga={etichettaPrimaRiga}
                    etichettaSecondaRiga={etichettaSecondaRiga}
                    etichettaColor={COLOR_THEMES[colorTheme].etichettaColor}
                    etichettaBackgroundColor={
                      COLOR_THEMES[colorTheme].etichettaBackgroundColor
                    }
                  />
                  <MiniCheck
                    active={selectedVariants.includes("custom")}
                    value={"custom"}
                    toggle={toggle}
                  />
                </div>
              )}
              {combos
                .filter((d) => d.colorTheme.includes(colorTheme) && !d.outline)
                .map((combo, i) => (
                  <div
                    key={i}
                    className={classNames(
                      styles.LogoWrapper,
                      `export-${combo.key}`
                    )}
                  >
                    {selectedVariants.includes(combo.key) && (
                      <div className={styles.LogoActive} />
                    )}
                    <Logo
                      {...params}
                      {...combo}
                      width={size}
                      height={size}
                      maschera0Color={
                        combo.maschera0Color ||
                        COLOR_THEMES[colorTheme].maschera0Color
                      }
                      mascheraPuntoColor={
                        combo.mascheraPuntoColor ||
                        COLOR_THEMES[colorTheme].mascheraPuntoColor
                      }
                      maschera8Color={
                        combo.maschera8Color ||
                        COLOR_THEMES[colorTheme].maschera8Color
                      }
                      etichettaPrimaRiga={etichettaPrimaRiga}
                      etichettaSecondaRiga={etichettaSecondaRiga}
                      etichettaColor={
                        combo.etichettaColor ||
                        COLOR_THEMES[colorTheme].etichettaColor
                      }
                      etichettaBackgroundColor={
                        combo.etichettaBackgroundColor ||
                        COLOR_THEMES[colorTheme].etichettaBackgroundColor
                      }
                    />
                    <MiniCheck
                      active={selectedVariants.includes(combo.key)}
                      value={combo.key}
                      toggle={toggle}
                    />
                  </div>
                ))}
              {outlineSvgImages
                ? outlineSvgImages.map((combo) => (
                    <div
                      key={combo.key}
                      className={classNames(
                        styles.LogoWrapper,
                        `export-${combo.key}`
                      )}
                    >
                      {selectedVariants.includes(combo.key) && (
                        <div className={styles.LogoActive} />
                      )}
                      <div
                        style={{ backgroundColor: combo.containerBackground }}
                      >
                        <img
                          src={combo.url}
                          alt={`Outline combo ${combo.key}`}
                          key={combo.key}
                          width={size}
                          height={size}
                        />
                      </div>
                      <MiniCheck
                        active={selectedVariants.includes(combo.key)}
                        value={combo.key}
                        toggle={toggle}
                      />
                    </div>
                  ))
                : combos
                    .filter((d) => d.outline)
                    .map((combo) => (
                      <div
                        key={combo.key}
                        style={{ width: size, height: size }}
                        className={classNames(
                          styles.LogoWrapper,
                          `export-${combo.key}`
                        )}
                      >
                        {selectedVariants.includes(combo.key) && (
                          <div className={styles.LogoActive} />
                        )}
                        <MiniCheck
                          active={selectedVariants.includes(combo.key)}
                          value={combo.key}
                          toggle={toggle}
                        />
                      </div>
                    ))}
            </>
          )}
        </div>
      </div>
    </BoxContent>
  );
}

export default function BoxExport({
  onConfirm,
  toggleBox,
  isModal = false,
  isOpen,
  etichettaPrimaRiga,
  etichettaSecondaRiga,
}) {
  return (
    <BoxWithBorders
      isModal={isModal}
      isOpen={isOpen}
      bodyBox={
        <BoxExportBody
          etichettaPrimaRiga={etichettaPrimaRiga}
          etichettaSecondaRiga={etichettaSecondaRiga}
          toggleBox={toggleBox}
          onConfirm={onConfirm}
        />
      }
    />
  );
}
