import { useParams } from "react-router-dom";
import PageNotFound from "../components/PageNotFound";
import { useLogo } from "../hooks/logo";
import AnimatedLogo from "./AnimatedLogo";

export default function AnimatedLogoArchive() {
  const { id } = useParams();
  const [{ data: logo, error }] = useLogo(id);

  if (error) {
    return <PageNotFound />;
  }
  if (!logo) {
    return null;
  }

  return (
    <AnimatedLogo
      mode="pro"
      initialParams={logo.params}
      initialParamsHistory={logo.params_history}
      initialMaxDelta={logo.max_delta}
      initialMaxValue={logo.max_value}
    />
  );
}
