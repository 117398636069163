import { useContext } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { ParametersContext, PlayerContext } from "../../contexts";
import { useLogoCreate } from "../../hooks/logo";
import { makeLogoImage } from "../../utils/downloads";
import { getSuppertedVideoExtension } from "../../utils/video";
import stylesCommon from "../BoxWithBorders/BoxWithBorders.module.css";
import styles from "./UploadModal.module.css";
import closeIcon from "../../assets/close.svg";

export default function UploadModal({ isOpen, toggle, onConfirm }) {
  const {
    params,
    paramsHistory,
    years,
    maxDelta,
    maxValue,
    colorTheme,
    etichetta,
  } = useContext(ParametersContext);
  const { audioBlob, totalTime } = useContext(PlayerContext);
  const [{ pending }, { run }] = useLogoCreate();

  async function save() {
    const fd = new FormData();
    fd.append("params", JSON.stringify(params));
    fd.append("params_history", JSON.stringify(paramsHistory.current));
    if (years !== null) {
      fd.append("years", years);
    }
    fd.append("max_delta", maxDelta);
    fd.append("max_value", maxValue);
    fd.append("theme", colorTheme);
    if (etichetta !== null) {
      fd.append("etichetta", etichetta);
    }
    if (audioBlob) {
      fd.append("video", audioBlob, `video.${getSuppertedVideoExtension()}`);
      fd.append("duration", totalTime);
    }
    const svgNode = document.querySelector(".svg-container > svg");
    const image = await makeLogoImage(svgNode);
    fd.append("image", image, "logo.png");
    fd.append("starred", "1");
    await run.asPromise(fd);
    onConfirm();
  }

  return (
    <Modal className={styles.Modal} isOpen={isOpen} toggle={toggle} centered>
      <ModalHeader className={styles.ModalHeader}>
        <div className={styles.TitleModal}>Salva in archivio</div>
        <div className={styles.CloseBox} onClick={toggle}>
          <img src={closeIcon} alt="Close" />
        </div>
      </ModalHeader>
      <ModalBody className={styles.ModalBody}>
        <div className={styles.TextModal}>
          Conferma per salvare la versione <br /> generata.
        </div>
      </ModalBody>
      <ModalFooter className={styles.ModalFooter}>
        <div className="w-100 d-flex justify-content-between align-items-center">
          <div onClick={toggle} className={stylesCommon.ButtonCancel}>
            Annulla
          </div>
          <div
            className={
              pending
                ? stylesCommon.ButtonConfirmDisabled
                : stylesCommon.ButtonConfirm
            }
            onClick={() => {
              if (!pending) {
                save();
                toggle();
              }
            }}
          >
            Conferma
          </div>
        </div>
      </ModalFooter>
    </Modal>
  );
}
