import { memo } from "react";
import { ReactComponent as Star } from "./star.svg";
import styles from "./LogoCard.module.css";
import { Link } from "react-router-dom";

function LogoCard({ logo, onStarredChange, onDelete, onDownload }) {
  return (
    <div className={styles.LogoCard}>
      <Link to={`/pro/${logo.id}`}>
        <img
          src={logo.image}
          className={`${styles.ImgLogo} img-fluid`}
          alt="Logo"
        />
      </Link>
      <div className="w-100 position-relative pt-1">
        <div className={styles.InfoLogo}>{logo.etichetta}</div>
        <div className={styles.InfoLogo}>{logo.video ? "Video" : ""}</div>
        <div
          onClick={() => onStarredChange(logo.id, !logo.starred)}
          className={styles.Star}
        >
          <Star width={15} fill={logo.starred ? "black" : "none"} />
        </div>
      </div>
      {/* <button onClick={() => onStarredChange(logo.id, !logo.starred)}>
        {logo.starred ? "X" : "o"}
      </button> */}
      <div className="d-flex justify-content-between mt-2">
        <div className={styles.Delete} onClick={() => onDelete(logo)}>
          Elimina
        </div>
        <div className={styles.Download} onClick={() => onDownload(logo)}>
          Scarica
        </div>
      </div>
    </div>
  );
}

export default memo(LogoCard);
