import { useMemo } from "react";
import BoxWithBorders, { BoxContent } from "../BoxWithBorders";
import styles from "./BoxSatelliteBrand.module.css";
import ageRange06 from "./assets/0-6.svg";
import ageRange614 from "./assets/6-14.svg";
import ageRange1418 from "./assets/14-18.svg";
import ageRange018 from "./assets/0-18.svg";

function BoxSatelliteBody({
  toggleBox,
  onConfirm,
  ageRange,
  setAgeRange,
  name,
  setName,
}) {
  const initialEtichetta = useMemo(() => {
    return name || "";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialAgeRange = useMemo(() => {
    return ageRange || "0-18";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <BoxContent
      // isDisabled={labelText === '' || !ageRange}
      title="genera marchio satellite"
      toggleBox={() => {
        setAgeRange(initialAgeRange);
        setName(initialEtichetta);
        toggleBox();
      }}
      onConfirm={() => {
        if (ageRange === "0-18") {
          onConfirm(ageRange, null);
        } else {
          onConfirm(ageRange, name);
        }
      }}
      resetButton={() => {
        setAgeRange(initialAgeRange);
        setName(initialEtichetta);
        toggleBox();
      }}
      isDisabled={ageRange !== "0-18" && name === ""}
    >
      <div className="mt-3">
        <div className={styles.SubtitleBox}>Seleziona fascia d’età</div>
        <div className="mt-3 d-flex align-items-center">
          <div
            className={styles.AgeRangeItem}
            onClick={() => setAgeRange("0-6")}
          >
            <div
              className={
                ageRange && ageRange === "0-6"
                  ? styles.ActiveAgeRange
                  : styles.AgeRange
              }
            >
              <img src={ageRange06} alt="0-6" />
            </div>
            <div className="mt-3">0-6</div>
          </div>
          <div
            className={styles.AgeRangeItem}
            onClick={() => setAgeRange("6-14")}
          >
            <div
              className={
                ageRange && ageRange === "6-14"
                  ? styles.ActiveAgeRange
                  : styles.AgeRange
              }
            >
              <img src={ageRange614} alt="6-14" />
            </div>
            <div className="text-nowrap mt-3">6-14</div>
          </div>
          <div
            className={styles.AgeRangeItem}
            onClick={() => setAgeRange("14-18")}
          >
            <div
              className={
                ageRange && ageRange === "14-18"
                  ? styles.ActiveAgeRange
                  : styles.AgeRange
              }
            >
              <img src={ageRange1418} alt="14-18" />
            </div>
            <div className="text-nowrap mt-3">14-18</div>
          </div>
          <div
            className={styles.AgeRangeItem}
            onClick={() => {
              setAgeRange("0-18");
            }}
          >
            <div
              className={
                ageRange && ageRange === "0-18"
                  ? styles.ActiveAgeRange
                  : styles.AgeRange
              }
            >
              <img src={ageRange018} alt="0-18" />
            </div>
            <div className="text-nowrap mt-3">0-18</div>
          </div>
        </div>
        <div className="mt-5">
          <div
            className={
              ageRange === "0-18"
                ? styles.SubtitleBoxDisabled
                : styles.SubtitleBox
            }
          >
            Inserisci testo etichetta
          </div>
          <div className="mt-3">
            <textarea
              required
              disabled={ageRange === "0-18"}
              maxLength={
                name.indexOf("\n") === -1
                  ? 30
                  : name.split("\n")[0].length > 15
                  ? 30
                  : name.split("\n")[0].length + 16
              }
              className={"input-name w-100"}
              placeholder={"| Inserire massimo 30 caratteri"}
              type={"text"}
              value={name || ""}
              onChange={(e) => {
                if (name.indexOf("\n") !== -1) {
                  if (e.nativeEvent.inputType === "insertLineBreak") return;
                }
                setName(e.target.value);
              }}
            />
            {name.indexOf("\n") === -1 && (
              <div className={styles.MaxLength}>{(name || "").length}/30</div>
            )}
            {name.indexOf("\n") !== -1 && (
              <div className={styles.MaxLength}>
                {(name || "").length - 1}/
                {name.split("\n")[0].length > 15
                  ? 30
                  : name.split("\n")[0].length + 15}
              </div>
            )}
          </div>
        </div>
      </div>
    </BoxContent>
  );
}

export default function BoxSatelliteBrand({
  toggleBox,
  onConfirm,
  ageRange,
  setAgeRange,
  name,
  setName,
  isOpen,
  isPro,
}) {
  return (
    <BoxWithBorders
      isOpen={isOpen}
      isPro={isPro}
      bodyBox={
        <BoxSatelliteBody
          toggleBox={toggleBox}
          onConfirm={onConfirm}
          ageRange={ageRange}
          setAgeRange={setAgeRange}
          name={name}
          setName={setName}
        />
      }
    />
  );
}
