import { Navigate, Route, Routes } from "react-router-dom";
import PageNotFound from "../../components/PageNotFound";
import Archive from "./Archive";
import Installations from "./Installations";
import Layout from "./Layout";

export default function Backend() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Navigate to="installations" />} />
        <Route path="installations" element={<Installations />} />
        <Route path="archive" element={<Archive />} />
      </Route>
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}
