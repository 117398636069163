import { MobileScreenContext } from "./contexts";
import { useIsMobileScreenMedia } from "./hooks/screen";

export default function MobileScreenProvider({ children }) {
  const is = useIsMobileScreenMedia();
  return (
    <MobileScreenContext.Provider value={is}>
      {children}
    </MobileScreenContext.Provider>
  );
}
