import { scaleLinear } from "d3-scale";
import mapValues from "lodash/mapValues";
import random from "lodash/random";
import { interpolateRound } from "d3-interpolate";

export const baseScale = scaleLinear().domain([1, 20]).range([1, 20])//.interpolate(interpolateRound);
export const baseScaleRays = scaleLinear().domain([1, 20]).range([1.0, 2.0]);
export const stretchBase = 5;
export const DEFAULT_RAY_PARAMS = [1.0, 0.04];

export const getBaseScale = (maxValue) =>
  scaleLinear().domain([1, maxValue]).range([1, maxValue])//.interpolate(interpolateRound)

export const getBaseScaleRays = (maxValue, rayParams = DEFAULT_RAY_PARAMS) =>
  scaleLinear()
    .domain([1, maxValue])
    .range([1.0, rayParams[0] + rayParams[1] * maxValue]);

export const CANONICAL_PARAMS = {
  letteraM: 9,
  letteraI: 13,
  letteraL: 14,
  letteraA: 9,
  letteraN: 14,
  letteraO: 5,
  lettera0: 1,
  lettera1: 8,
  lettera8: 4,
  maschera0: 1,
  mascheraPunto: 1,
  maschera8: 1,
};

export const INITIAL_PARAMS = {
  letteraM: 1,
  letteraI: 1,
  letteraL: 1,
  letteraA: 1,
  letteraN: 1,
  letteraO: 1,
  lettera0: 1,
  lettera1: 1,
  lettera8: 1,
  maschera0: 1,
  mascheraPunto: 1,
  maschera8: 1,
};

export const makeMaxParams = (maxValue) => {
  return mapValues(INITIAL_PARAMS, () => maxValue);
};

export function randomizeParams(maxValue, maxDelta) {
  return {
    letteraM: random(maxValue - maxDelta, maxValue),
    letteraI: random(maxValue - maxDelta, maxValue),
    letteraL: random(maxValue - maxDelta, maxValue),
    letteraA: random(maxValue - maxDelta, maxValue),
    letteraN: random(maxValue - maxDelta, maxValue),
    letteraO: random(maxValue - maxDelta, maxValue),
    lettera0: random(maxValue - maxDelta, maxValue),
    lettera1: random(maxValue - maxDelta, maxValue),
    lettera8: random(maxValue - maxDelta, maxValue),
    maschera0: random(maxValue - maxDelta, maxValue),
    mascheraPunto: random(maxValue - maxDelta, maxValue),
    maschera8: random(maxValue - maxDelta, maxValue),
  };
}

export function canonicizeParams(maxValue) {
  return mapValues(CANONICAL_PARAMS, (value, key, object) => {
    return value + (maxValue - 15);
  });
}

export const PAGE_SIZE = 100;
