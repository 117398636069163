import { useContext } from "react";
import { useMediaLayout } from "use-media";
import { MobileScreenContext } from "../contexts";

export function useIsMobileScreenMedia(defaultValue = null) {
  return useMediaLayout(
    {
      minWidth: 150,
      maxWidth: 1000,
    },
    defaultValue
  );
}

export function useIsMobileScreen(defaultValue = null) {
  return useContext(MobileScreenContext) ?? defaultValue;
}
