import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import stylesCommon from "../BoxWithBorders/BoxWithBorders.module.css";
import styles from "./SendModal.module.css";
import closeIcon from "../../assets/close.svg";
import { useMemo, useState } from "react";
import { useSendLogoFromInstallation } from "../../hooks/logo";

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export default function SendModal({ isOpen, toggle, onConfirm }) {
  const [isSending, setIsSending] = useState(false)
  const [sendError, setSendError] = useState(null)
  const [email, setEmail] = useState("");
  const isMailValid = useMemo(() => {
    if (!email) {
      return false;
    }
    return Boolean(validateEmail(email));
  }, [email]);
  const sendLogo = useSendLogoFromInstallation()

  return (
    <Modal
      className={styles.Modal}
      isOpen={isOpen}
      toggle={toggle}
      centered
      onClosed={() => {
        setEmail("")
        setSendError(null)
      }}
    >
      <ModalHeader className={styles.ModalHeader}>
        <div className={styles.TitleModal}>
          Inserisci il tuo indirizzo e-mail
        </div>
        <div className={styles.CloseBox} onClick={toggle}>
          <img src={closeIcon} alt="Close" />
        </div>
      </ModalHeader>
      <ModalBody className={styles.ModalBody}>
        <div className={styles.TextModal}>
          <input
            className="input-name-big w-100"
            placeholder="| esempio@gmail.com"
            type={"email"}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
      </ModalBody>
      <ModalFooter className={styles.ModalFooter}>
        <div className="w-100 d-flex justify-content-between align-items-center">
          <div
            role="button"
            onClick={toggle}
            className={stylesCommon.ButtonCancel}
          >
            Annulla
          </div>
          <div
            role="button"
            className={
              isMailValid && !isSending
                ? stylesCommon.ButtonConfirm
                : stylesCommon.ButtonConfirmDisabled
            }
            onClick={async () => {
              if (isMailValid) {
                setIsSending(true)
                setSendError(null)
                try {
                  await sendLogo(email)
                  toggle()
                } catch (e) {
                  setSendError(e)
                }
                setIsSending(false)
              }
            }}
          >
            Invia
          </div>
        </div>
        {Boolean(sendError) && <div className="text-danger">
          Errore durante l'invio.
        </div>}
      </ModalFooter>
    </Modal>
  );
}
