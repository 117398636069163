import { scaleLinear, scaleSqrt, scalePow } from "d3-scale";
import { sample } from "lodash";
import get from "lodash/get";
import random from "lodash/random";
import mapValues from "lodash/mapValues";
import memoize from "lodash/memoize";

function getDelta(base, instant, mean, scale) {
  if (mean === undefined) {
    return scale(instant - base);
  }

  return scale(instant - mean);
}

function getHistory(samples, index, field) {
  return sample[index] === undefined || samples[index][field] === undefined
    ? samples[samples.length - 1][field]
    : samples[index][field];
}

function makeScale(maxRange) {
  return scaleLinear()
    .domain([-255, 0, 255])
    .range([-maxRange, 0, maxRange])
    .clamp(true);
}

function makeScaleSqrt(maxRange) {
  return scaleSqrt().domain([-255, 0, 255]).range([-maxRange, 0, maxRange]);
}

function deltaParam(param, instant, stat, scale) {
  return param + getDelta(param, instant, stat, scale);
}

export function makeUtils(maxValue) {
  function fix(arg) {
    return Math.min(maxValue, Math.max(1, arg));
  }

  function cumulated(
    startParam,
    param,
    scale,
    vol,
    refVol,
    numSamplesProcessed
  ) {
    if (numSamplesProcessed < 20) {
      return startParam;
    }
    const nextValue = startParam + scale(vol - refVol);

    return fix(
      (numSamplesProcessed * param + nextValue) / (numSamplesProcessed + 1)
    );
  }

  function peak(startParam, scale, vol, refVol) {
    return fix(startParam + scale(vol - refVol));
  }

  function cumulatedPeak(
    startParam,
    param,
    scale,
    vol,
    refVol,
    numSamplesProcessed,
    cumulatedWeight = 0.6
  ) {
    const a = cumulated(
      startParam,
      param,
      scale,
      vol,
      refVol,
      numSamplesProcessed
    );
    const b = peak(startParam, scale, vol, refVol);
    return a * cumulatedWeight + b * (1 - cumulatedWeight);
  }

  return { fix, cumulated, cumulatedPeak, peak };
}

const scaleBase = makeScale(1);
const scaleDouble = makeScale(2);
const scaleDoubleSqrt = makeScaleSqrt(2);
const scaleBoom = makeScale(5);

const makeUtilsMemo = memoize(makeUtils);

/*
This function is called every time a new sound sample is available.
It returns a new set of parameters for the logo.
*/

export function paramsFromSound(
  soundData,
  soundStats,
  params,
  maxValue,
  paramsStart
) {
  // return mapValues(params, key => random(1, maxValue))

  const utils = makeUtilsMemo(maxValue);

  const { fix, cumulatedPeak } = utils;

  const { frequencyData, volume } = soundData;
  const volumeStat = soundStats.stats.volume;
  const frequencyStat = soundStats.stats.frequencyData;

  const historyVolume1 = get(soundStats.lastSamples, `[18].volume`, volume);
  const historyVolume2 = get(soundStats.lastSamples, `[12].volume`, volume);
  const historyVolume3 = get(soundStats.lastSamples, `[2].volume`, volume);

  // const historyStats1 = get(
  //   soundStats.lastSamples,
  //   `[0].frequencyData`,
  //   frequencyData
  // );

  const newM = cumulatedPeak(
    paramsStart.letteraM,
    params.letteraM,
    scaleBase,
    volume,
    volumeStat,
    soundStats.numSamplesProcessed
  );

  const newI = cumulatedPeak(
    paramsStart.letteraI,
    params.letteraI,
    scaleDouble,
    frequencyData[2],
    frequencyStat[2],
    soundStats.numSamplesProcessed
  );
  const newL = cumulatedPeak(
    paramsStart.letteraL,
    params.letteraL,
    scaleDouble,
    volume,
    volumeStat,
    soundStats.numSamplesProcessed
  );
  const newA = cumulatedPeak(
    paramsStart.letteraA,
    params.letteraA,
    scaleDouble,
    frequencyData[3],
    frequencyStat[3],
    soundStats.numSamplesProcessed
  );
  const newN = cumulatedPeak(
    paramsStart.letteraN,
    params.letteraN,
    scaleDouble,
    frequencyData[6],
    frequencyStat[6],
    soundStats.numSamplesProcessed
  );
  const newO = cumulatedPeak(
    paramsStart.letteraO,
    params.letteraO,
    scaleBase,
    historyVolume2,
    volumeStat,
    soundStats.numSamplesProcessed
  );
  const new0 = cumulatedPeak(
    paramsStart.lettera0,
    params.lettera0,
    scaleBase,
    volume,
    volumeStat,
    soundStats.numSamplesProcessed
  );
  const new1 = cumulatedPeak(
    paramsStart.lettera1,
    params.lettera1,
    scaleDouble,
    historyVolume2,
    volumeStat,
    soundStats.numSamplesProcessed
  );
  const new8 = cumulatedPeak(
    paramsStart.lettera8,
    params.lettera8,
    scaleBase,
    frequencyData[6],
    frequencyStat[6],
    soundStats.numSamplesProcessed,
    0.5
  );

  const newMascheraPunto = fix(
    deltaParam(params.mascheraPunto, volume, historyVolume3, scaleBoom)
  );
  const newMaschera8 = fix(
    deltaParam(params.maschera8, volume, historyVolume1, scaleBoom)
  );
  const newMaschera0 = fix(
    deltaParam(params.maschera0, volume, historyVolume2, scaleBoom)
  );

  return {
    ...params,
    letteraM: newM,
    letteraI: newI,
    letteraL: newL,
    letteraA: newA,
    letteraN: newN,
    letteraO: newO,

    lettera0: new0,
    lettera1: new1,
    lettera8: new8,

    maschera0: newMaschera0,
    mascheraPunto: newMascheraPunto,
    maschera8: newMaschera8,
  };
}

// export function getParamsFromHistory(samples, maxValue, initialParams) {
//   if (!samples.length) {
//     return;
//   }

//   const firstDatum = samples[0];
//   const firstTime = new Date(firstDatum.timestamp).getTime();

//   const lastDatum = samples[samples.length - 1];
//   const lastTime = new Date(lastDatum.timestamp).getTime();

//   const total = lastTime - firstTime;

//   const soundStats = new SoundStats();
//   let prevParams = initialParams;

//   return samples.map((sample) => {
//     const nextParams = paramsFromSound(
//       sample,
//       soundStats,
//       prevParams,
//       maxValue,
//       firstDatum
//     );

//     const ts = sample.timestamp;
//     const parsedDate = new Date(ts);
//     const time = parsedDate.getTime();

//     const schedule = time - firstTime;
//     const fraction = schedule / total;

//     prevParams = nextParams;

//     return {
//       params: nextParams,
//       timestamp: sample.timestamp,
//       schedule,
//       fraction,
//     };
//   });
// }
