import classNames from "classnames";
import { useCallback, useEffect, useMemo, useState } from "react";
import arrowRight from "../../assets/right-arrow.svg";
import arrowRightDisabled from "../../assets/right-arrow-light.svg";
import SliderYears from "../SliderYears";
import styles from "./BoxPersonalizationMobile.module.css";

function Reset({ onReset }) {
  return (
    <div
      className={styles.Reset}
      onClick={() => {
        onReset();
      }}
    >
      <div className={styles.ResetIcon} />
      <div className={styles.ResetText}>
        Ripristina marchio non personalizzato
      </div>
    </div>
  );
}

function BoxName({
  name,
  setName,
  setBoxName,
  setBoxYears,
  togglePersonalizza,
  setYears,
  years,
  showReset,
  onReset,
}) {
  const initialEtichetta = useMemo(() => {
    return name || "";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialYears = useMemo(() => {
    return years;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={`mb-5 ${styles.BoxContainerStep}`}>
      <div className="mt-4">
        <div className={styles.Question}>Come ti chiami?</div>
        <div className="mt-3">
          <textarea
            required
            rows={2}
            maxLength={
              name.indexOf("\n") === -1
                ? 20
                : name.split("\n")[0].length > 10
                ? 20
                : name.split("\n")[0].length + 11
            }
            className={"input-name w-100"}
            placeholder={"Inserisci un nome..."}
            type={"text"}
            value={name}
            onChange={(e) => {
              if (name.indexOf("\n") !== -1) {
                if (e.nativeEvent.inputType === "insertLineBreak") return;
              }
              setName(e.target.value);
            }}
          />
        </div>
      </div>
      {showReset && <Reset onReset={onReset} />}
      <div className={styles.ButtonsPersonalization}>
        <div
          className={styles.ButtonCancel}
          onClick={() => {
            setYears(initialYears);
            setName(initialEtichetta);
            togglePersonalizza();
          }}
        >
          Annulla
        </div>
        <div
          className={
            name !== "" ? styles.ButtonNextStep : styles.ButtonNextStepDisabled
          }
          onClick={() => {
            if (name !== "") {
              setBoxYears(true);
              setBoxName(false);
            }
          }}
        >
          Avanti{" "}
          <span className="ms-2">
            <img
              src={name !== "" ? arrowRight : arrowRightDisabled}
              alt="Avanti"
            />
          </span>
        </div>
      </div>
    </div>
  );
}

function BoxYears({
  setYears,
  years,
  name,
  showReset,
  onReset,
  onConfirm,
  setBoxName,
  setBoxYears,
}) {
  const initialYears = useMemo(() => {
    return years;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={`mb-5 ${styles.BoxContainerStep}`}>
      <div className="mt-4">
        <div className={styles.Question}>Quanti anni hai?</div>
        <div className={`${styles.SliderContainer} mt-3`}>
          <SliderYears years={years} setYears={setYears} />
          {years === null && (
            <div className={styles.SelectAgeText}>Seleziona la tua età</div>
          )}
        </div>
      </div>
      {showReset && <Reset onReset={onReset} />}
      <div className={styles.ButtonsPersonalization}>
        <div
          className={styles.ButtonCancel}
          onClick={() => {
            setBoxName(true);
            setBoxYears(false);
            setYears(initialYears);
          }}
        >
          Annulla
        </div>
        <div
          className={
            years ? styles.ButtonConfirm : styles.ButtonConfirmDisabled
          }
          onClick={() => {
            if (years) {
              onConfirm();
            }
          }}
        >
          Conferma{" "}
        </div>
      </div>
    </div>
  );
}

export default function BoxPersonalizationMobile({
  togglePersonalizza,
  onConfirm,
  name,
  setName,
  years,
  setYears,
  isOpen,
}) {
  const [boxName, setBoxName] = useState(true);
  const [boxYears, setBoxYears] = useState(false);

  const [animation, setAnimation] = useState(isOpen ? "open" : "close");
  useEffect(() => {
    // Avoid extra render in modal mode ...
    if (isOpen) {
      setAnimation("open");
    }
  }, [isOpen]);

  const klass = classNames(styles.BoxRightDesktop, {
    [styles.BoxAnimation]: true,
    [styles.BoxClosing]: !isOpen && animation !== "close",
    [styles.BoxClose]: !isOpen && animation === "close",
    [styles.BoxOpening]: isOpen,
    [styles.BoxOpen]: animation === "open",
  });

  const handleReset = useCallback(() => {
    setYears(null);
    setName("");
    togglePersonalizza();
  }, [setName, setYears, togglePersonalizza]);
  const showReset = useMemo(() => {
    if (!isOpen) {
      return false;
    }
    return name !== "" || years !== null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <div
      className={`${styles.BoxPersonalization} pb-5 ${klass}`}
      onTransitionEnd={() => {
        if (!isOpen) {
          setAnimation("close");
          setBoxName(true);
          setBoxYears(false);
        }
      }}
    >
      <div className={styles.DividerMobile}></div>
      <div className={`${styles.TitleBox} pt-4`}>Personalizza</div>
      {boxName && (
        <BoxName
          name={name}
          years={years}
          setBoxName={setBoxName}
          setBoxYears={setBoxYears}
          setName={setName}
          setYears={setYears}
          togglePersonalizza={togglePersonalizza}
          showReset={showReset}
          onReset={handleReset}
        />
      )}
      {boxYears && (
        <BoxYears
          setBoxName={setBoxName}
          setYears={setYears}
          name={name}
          onConfirm={() => onConfirm(name, years)}
          setBoxYears={setBoxYears}
          years={years}
          showReset={showReset}
          onReset={handleReset}
        />
      )}
    </div>
  );
}
