import JSZip from "jszip";
import { saveAs } from "file-saver";
import { useQsFilters } from "../../../hooks/filters";
import { useCallback } from "react";
import Paginator from "../../../components/Backend/Paginator";
import { useLogosList } from "../../../hooks/logo";
import LogoCard from "../../../components/Backend/LogoCard";
import { COLOR_THEMES } from "../../../utils/colors";
import useModalTrigger from "magik-react-hooks/useModalTrigger";
import {
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import styles from "./Archive.module.css";
import stylesCommon from "../Installations/Installations.module.css";

export default function Archive() {
  const { filters, uiFilters, setFilters } = useQsFilters(
    useCallback(
      (params) => ({
        page: +(params.get("page") ?? 1),
        theme: params.get("theme") ?? "",
        starred: params.get("starred") ?? "",
        has_video: params.get("has_video") ?? "",
      }),
      []
    )
  );
  const [{ list, pagination, pending }, { setStar, run, removeLogo }] =
    useLogosList(filters);
  const handleStarChange = useCallback(
    (id, starred) => {
      setStar
        .onSuccess(() => {
          run(filters);
        })
        .run(id, starred);
    },
    [filters, run, setStar]
  );
  const [modalRm, modalRmActions] = useModalTrigger();

  const handleDownload = useCallback(async (logo) => {
    const zip = new JSZip();
    const image = await fetch(logo.image).then((r) => r.blob());
    zip.file(`logo.${logo.image.split(".").pop()}`, image);
    if (logo.video) {
      const video = await fetch(logo.video).then((r) => r.blob());
      zip.file(`video.${logo.video.split(".").pop()}`, video);
    }
    const zipBlob = await zip.generateAsync({ type: "blob" });
    saveAs(zipBlob, "logo.zip");
  }, []);

  return (
    <div className="w-100">
      <div className="w-100 d-flex flex-column justify-content-end mb-4 position-relative">
        <div className="d-flex align-items-center justify-content-end">
          <div className={`${styles.FiltraPer} me-4`}>Filtra per:</div>
          <select
            className={styles.FilterSelect}
            value={uiFilters.theme}
            onChange={(e) =>
              setFilters({
                page: 1,
                theme: e.target.value,
              })
            }
          >
            <option value={""}>Fascia d'età</option>
            {Object.keys(COLOR_THEMES).map((t) => (
              <option value={t} key={t}>
                {t}
              </option>
            ))}
          </select>
          <select
            className={`${styles.FilterSelect} ms-3`}
            value={uiFilters.has_video}
            onChange={(e) =>
              setFilters({
                page: 1,
                has_video: e.target.value,
              })
            }
          >
            <option value={""}>Media</option>
            <option value={"1"}>Con Video</option>
            <option value={"0"}>Senza Video</option>
          </select>
        </div>
        <div className="mt-3 d-flex justify-content-end align-items-center">
          <FormGroup switch>
            <Input
              type="switch"
              checked={Boolean(+uiFilters.starred)}
              onChange={() => {
                setFilters({
                  starred: uiFilters.starred ? "" : "1",
                });
              }}
            />
            <Label className={styles.StarredChoiche} check>
              Mostra solo Preferiti
            </Label>
          </FormGroup>
          {/* <input
            onChange={() =>
              setFilters({
                starred: uiFilters.starred ? "" : "1",
              })
            }
            type="checkbox"
            checked={Boolean(+uiFilters.starred)}
          />
          <label></label> */}
        </div>
        {pending && <div style={{ position: 'absolute', bottom: 0, left: 0 }}>
          <Spinner />
        </div>}
      </div>
      <div
        className="row w-100 mt-4 pt-4"
        style={{ minHeight: 600 }}
      >
        {list &&
          list.map((logo) => (
            <div className="col-md-2" key={logo.id}>
              <LogoCard
                onDownload={handleDownload}
                onDelete={modalRmActions.open}
                logo={logo}
                onStarredChange={handleStarChange}
              />
            </div>
          ))}
        <Paginator
          currentPage={uiFilters.page}
          numPages={pagination.numPages}
          goToPage={(page) => setFilters({ page })}
        />
        <Modal
          className={stylesCommon.Modal}
          isOpen={modalRm.isOpen}
          centered
          toggle={modalRmActions.toggle}
          onClosed={modalRmActions.onClosed}
        >
          <ModalHeader
            toggle={modalRmActions.toggle}
            className={stylesCommon.ModalHeader}
          >
            <div className={stylesCommon.TitleModal}>Elimina logo?</div>
          </ModalHeader>
          <ModalBody className={stylesCommon.ModalBody}>
            <div className={stylesCommon.TextModal}>
              {modalRm.value && <p>Rimuove logo {modalRm.value.theme} ?</p>}
            </div>
          </ModalBody>
          <ModalFooter className={stylesCommon.ModalFooter}>
            <div className="w-100 d-flex justify-content-between align-items-center">
              <div
                onClick={modalRmActions.close}
                className={stylesCommon.ButtonCancel}
              >
                Annulla
              </div>
              <div
                className={stylesCommon.ButtonConfirm}
                onClick={() => {
                  removeLogo
                    .onSuccess(() => {
                      modalRmActions.close();
                    })
                    .run(modalRm.value.id);
                }}
              >
                Elimina
              </div>
            </div>
          </ModalFooter>
        </Modal>
      </div>
    </div>
  );
}
