import ReactSlider from "react-slider";
import BoxWithBorders, { BoxContent } from "../BoxWithBorders";
import styles from "./BoxManual.module.css";
import { CANONICAL_PARAMS } from "../../utils/params";

function SliderLetter({ param, params, setParams, letter, maxValue }) {
  return (
    <div className="d-flex align-items-center flex-column">
      <ReactSlider
        min={1}
        thumbClassName={styles.ThumbSlider}
        className={styles.VerticalSlider}
        trackClassName={"slide"}
        orientation={"vertical"}
        invert
        defaultValue={CANONICAL_PARAMS[param]}
        value={params[param]}
        max={maxValue}
        onChange={(value) =>
          setParams({
            ...params,
            [`${param}`]: value,
          })
        }
      />
      <div className={styles.OptionSlider}>{letter}</div>
    </div>
  );
}

function SliderMask({ param, params, setParams, text, maxValue }) {
  return (
    <div className="d-flex align-items-center flex-row mb-3">
      <div
        style={{ width: 130 }}
        className={`${styles.OptionSlider} text-nowrap me-3`}
      >
        {text}
      </div>
      <ReactSlider
        min={1}
        step={0.1}
        thumbClassName={styles.ThumbSliderHorizontal}
        className={styles.HorizontalSlider}
        trackClassName="slide-hor"
        defaultValue={CANONICAL_PARAMS[param]}
        value={params[param]}
        max={maxValue}
        onChange={(value) =>
          setParams({
            ...params,
            [`${param}`]: value,
          })
        }
      />
    </div>
  );
}

function SliderLong({ value, setValue, text, min, max, step }) {
  return (
    <div className="d-flex flex-column mb-3 mt-2">
      <div className={`${styles.OptionSlider} text-nowrap me-3 mb-2`}>
        {text}
      </div>
      <ReactSlider
        min={min}
        step={step}
        thumbClassName={styles.ThumbSliderHorizontalBlack}
        className={styles.HorizontalSlider}
        trackClassName="slide-long"
        value={value}
        max={max}
        onChange={(value) => setValue(value)}
      />
    </div>
  );
}

function BoxManualBody({
  toggleBox,
  params,
  setParams,
  onConfirm,
  maxDelta,
  setMaxDelta,
  maxValue,
  setMaxValue,
}) {
  return (
    <BoxContent
      resetButton={() => {
        setParams(CANONICAL_PARAMS);
      }}
      onConfirm={onConfirm}
      title="Gestisci manualmente"
      toggleBox={toggleBox}
    >
      <div className="h-100">
        <div className={`${styles.SubtitleBox} mt-3`}>
          Altezza caratteri tipografici
        </div>
        <div
          className="d-flex justify-content-between mt-3"
          style={{ height: 150 }}
        >
          <SliderLetter
            params={params}
            param={"letteraM"}
            letter="M"
            setParams={setParams}
            maxValue={maxValue}
          />
          <SliderLetter
            params={params}
            param={"letteraI"}
            letter="I"
            setParams={setParams}
            maxValue={maxValue}
          />
          <SliderLetter
            params={params}
            param={"letteraL"}
            letter="L"
            setParams={setParams}
            maxValue={maxValue}
          />
          <SliderLetter
            params={params}
            param={"letteraA"}
            letter="A"
            setParams={setParams}
            maxValue={maxValue}
          />
          <SliderLetter
            params={params}
            param={"letteraN"}
            letter="N"
            setParams={setParams}
            maxValue={maxValue}
          />
          <SliderLetter
            params={params}
            param={"letteraO"}
            letter="O"
            setParams={setParams}
            maxValue={maxValue}
          />
          <SliderLetter
            params={params}
            param={"lettera0"}
            letter="0"
            setParams={setParams}
            maxValue={maxValue}
          />
          <SliderLetter
            params={params}
            param={"lettera1"}
            letter=".1"
            setParams={setParams}
            maxValue={maxValue}
          />
          <SliderLetter
            params={params}
            param={"lettera8"}
            letter="8"
            setParams={setParams}
            maxValue={maxValue}
          />
        </div>
        <div style={{ marginTop: 24 }} className={`${styles.SubtitleBox}`}>
          Espansione raggiere
        </div>
        <div className="mt-3">
          <SliderMask
            params={params}
            param={"maschera0"}
            text="Raggiera 0"
            setParams={setParams}
            maxValue={maxValue}
          />
          <SliderMask
            params={params}
            param={"mascheraPunto"}
            text="Raggiera .1"
            setParams={setParams}
            maxValue={maxValue}
          />
          <SliderMask
            params={params}
            param={"maschera8"}
            text="Raggiera 8"
            setParams={setParams}
            maxValue={maxValue}
          />
        </div>
        <div
          style={{ marginTop: 30, marginBottom: 16 }}
          className={`${styles.SubtitleBox}`}
        >
          Parametri assoluti
        </div>
        <div>
          <SliderLong
            value={maxValue}
            setValue={setMaxValue}
            min={13}
            text={"Altezza max caratteri tipografici"}
            max={50}
            step={5}
          />
        </div>
        <div>
          <SliderLong
            value={maxDelta}
            setValue={setMaxDelta}
            text={"Delta tra lettere min e max"}
            min={15}
            max={50}
            step={5}
          />
        </div>
      </div>
    </BoxContent>
  );
}

export default function BoxManual({
  isOpen,
  toggleBox,
  params,
  setParams,
  onConfirm,
  maxDelta,
  setMaxDelta,
  maxValue,
  setMaxValue,
  isPro
}) {
  return (
    <BoxWithBorders
      isOpen={isOpen}
      isPro={isPro}
      bodyBox={
        <BoxManualBody
          toggleBox={toggleBox}
          params={params}
          setParams={setParams}
          onConfirm={onConfirm}
          maxDelta={maxDelta}
          setMaxDelta={setMaxDelta}
          maxValue={maxValue}
          setMaxValue={setMaxValue}
        />
      }
    />
  );
}
