export default function Personalization({ active }) {
  return (
    <svg
      width="21"
      height="18"
      viewBox="0 0 21 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_814_1106)">
        <path
          d="M8.39159 11.4696C10.3014 11.4696 11.8496 9.89151 11.8496 7.94478C11.8496 5.99806 10.3014 4.41992 8.39159 4.41992C6.48179 4.41992 4.93359 5.99806 4.93359 7.94478C4.93359 9.89151 6.48179 11.4696 8.39159 11.4696Z"
          stroke={active ? "black" : "#A0A0A0"}
          strokeWidth="0.75"
          strokeLinejoin="round"
        />
        <path
          d="M15.882 8.06652C15.9254 7.75713 15.7194 7.46983 15.4159 7.42563L14.3752 7.27094C14.3319 6.8842 14.256 6.49746 14.1368 6.12177L15.0256 5.55823C15.2858 5.39249 15.3617 5.04994 15.2099 4.78475L14.2018 3.11624C14.0392 2.85105 13.7032 2.7737 13.443 2.9284L12.5433 3.49193C12.2723 3.22674 11.9687 2.98365 11.6435 2.76265L11.9687 1.74608C12.0663 1.44773 11.9037 1.13834 11.6219 1.03889L9.80071 0.42011C9.50803 0.320663 9.20451 0.486409 9.10695 0.773701L8.7709 1.79028C8.38066 1.76818 8.00126 1.77923 7.62185 1.82343L7.23161 0.82895C7.12321 0.541657 6.79801 0.398011 6.51616 0.508508L4.72754 1.22674C4.4457 1.33724 4.30478 1.66873 4.41318 1.95602L4.80342 2.9505C4.4999 3.18254 4.20722 3.43668 3.94706 3.72398L3.02565 3.21569C2.75464 3.07204 2.4186 3.17149 2.27768 3.44773L1.36711 5.17149C1.22619 5.44773 1.32375 5.79028 1.59475 5.93392L2.51616 6.44221C2.4186 6.82895 2.3644 7.21569 2.35356 7.60243L1.32375 7.81238C1.03107 7.87867 0.835945 8.17702 0.890146 8.47536L1.28039 10.398C1.34543 10.6964 1.63811 10.8952 1.9308 10.84L2.96061 10.6301C3.13405 10.9836 3.32917 11.3151 3.56765 11.6245L2.91725 12.4533C2.73296 12.6964 2.76548 13.0499 3.00397 13.2378L4.51074 14.4533C4.74922 14.6411 5.09611 14.608 5.28039 14.3649L5.9308 13.5251C6.10424 13.6024 6.27768 13.6687 6.46196 13.735C6.64624 13.8013 6.84137 13.8455 7.02565 13.8897L7.05817 14.9505C7.05817 15.2599 7.31833 15.503 7.62185 15.4919L9.54055 15.4367C9.84408 15.4367 10.0826 15.1715 10.0717 14.8621L10.0392 13.7903C10.4078 13.6798 10.7655 13.5361 11.1015 13.3593L11.7953 14.1549C12.0013 14.387 12.3481 14.4091 12.5758 14.1991L14.0175 12.8952C14.2452 12.6853 14.2668 12.3317 14.0609 12.0997L13.3671 11.293C13.5731 10.9726 13.7574 10.6301 13.9091 10.2654L14.9498 10.4201C15.2533 10.4643 15.5351 10.2544 15.5785 9.94497L15.8603 8.01127L15.882 8.06652Z"
          stroke={active ? "black" : "#A0A0A0"}
          strokeWidth="0.75"
          strokeLinejoin="round"
        />
        <path
          d="M18.1681 9.6765L16.0142 7.48096L9.33018 14.2942L11.4841 16.4897L18.1681 9.6765Z"
          fill="white"
          stroke={active ? "black" : "#A0A0A0"}
          strokeWidth="0.75"
          strokeLinejoin="round"
        />
        <path
          d="M19.6482 5.98498C20.2384 6.58661 20.2384 7.57109 19.6482 8.17271L18.1688 9.68069L16.0149 7.48514L17.4943 5.97717C18.0845 5.37554 19.0503 5.37554 19.6405 5.97717L19.6482 5.98498Z"
          fill="white"
          stroke={active ? "black" : "#A0A0A0"}
          strokeWidth="0.75"
          strokeLinejoin="round"
        />
        <path
          d="M9.33465 14.2983L8.26147 17.5912L11.4918 16.4972L9.33465 14.2983Z"
          fill="white"
          stroke={active ? "black" : "#A0A0A0"}
          strokeWidth="0.75"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_814_1106">
          <rect
            width="20"
            height="18"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
