import { useInstallationsList } from "../../../hooks/installation";
import { useQsFilters } from "../../../hooks/filters";
import { useCallback } from "react";
import InstallationCard from "../../../components/Backend/InstallationCard";
import Paginator from "../../../components/Backend/Paginator";
import useModalTrigger from "magik-react-hooks/useModalTrigger";
import { Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap";
import styles from "./Installations.module.css";
import ModalInstallation from "../../../components/Backend/ModalInstallation";

export default function Installations() {
  const { filters, uiFilters, setFilters } = useQsFilters(
    useCallback(
      (params) => ({
        page: +(params.get("page") ?? 1),
      }),
      []
    )
  );
  const [
    { list, pagination, pending },
    { removeInstallation, addInstallation, updateInstallation },
  ] = useInstallationsList(filters);
  const [modalRm, modalRmActions] = useModalTrigger();
  const [modalCreate, modalCreateActions] = useModalTrigger();
  const [modalUpdate, modalUpdateActions] = useModalTrigger();
  return (
    <div className="w-100">
      <div className="w-100 d-flex flex-column justify-content-end pb-4 position-relative">
        <div className={styles.Crea} onClick={() => modalCreateActions.open()}>
          Crea
        </div>
        {pending && (
          <div style={{ position: "absolute", bottom: 0, left: 0 }}>
            <Spinner />
          </div>
         )}
      </div>
      <div
        className="row mt-4 pt-4"
        style={{ borderTop: "1px solid #000000", minHeight: 600 }}
      >
        {list &&
          list.map((installation) => (
            <div className="col-md-4" key={installation.id}>
              <InstallationCard
                onDelete={modalRmActions.open}
                onUpdate={modalUpdateActions.open}
                installation={installation}
              />
            </div>
          ))}
        <Paginator
          currentPage={uiFilters.page}
          numPages={pagination.numPages}
          goToPage={(page) => setFilters({ page })}
        />
        <Modal
          className={styles.Modal}
          isOpen={modalRm.isOpen}
          centered
          toggle={modalRmActions.toggle}
          onClosed={modalRmActions.onClosed}
        >
          <ModalHeader
            className={styles.ModalHeader}
            toggle={modalRmActions.toggle}
          >
            <div className={styles.TitleModal}>Elimina installazione?</div>
          </ModalHeader>
          <ModalBody className={styles.ModalBody}>
            <div className={styles.TextModal}>
              {modalRm.value && (
                <p>Rimuovere installazione {modalRm.value.theme} ?</p>
              )}
            </div>
          </ModalBody>
          <ModalFooter className={styles.ModalFooter}>
            <div className="w-100 d-flex justify-content-between align-items-center">
              <div
                onClick={modalRmActions.close}
                className={styles.ButtonCancel}
              >
                Annulla
              </div>
              <div
                className={styles.ButtonConfirm}
                onClick={() => {
                  removeInstallation
                    .onSuccess(() => {
                      modalRmActions.close();
                    })
                    .run(modalRm.value.id);
                }}
              >
                Elimina
              </div>
            </div>
          </ModalFooter>
        </Modal>
        <ModalInstallation
          isOpen={modalCreate.isOpen}
          toggle={() => modalCreateActions.toggle()}
          save={(values) =>
            addInstallation
              .onSuccess(() => modalCreateActions.close())
              .asPromise(values)
          }
        />
        <ModalInstallation
          isOpen={modalUpdate.isOpen}
          toggle={() => modalUpdateActions.toggle()}
          installation={modalUpdate.value}
          save={(values) =>
            updateInstallation
              .onSuccess(() => modalUpdateActions.close())
              .asPromise(values)
          }
        />
      </div>
    </div>
  );
}
