export default function Microphone({ active }) {
  return (
    <svg
      width="10"
      height="20"
      viewBox="0 0 10 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.08376 18.7915H2.91602"
        stroke={active ? "black" : "#A0A0A0"}
        strokeWidth="0.75"
      />
      <path
        d="M5.00586 14.8887V18.6838"
        stroke={active ? "black" : "#A0A0A0"}
        strokeWidth="0.75"
      />
      <path
        d="M9.50623 10.5244C9.50623 13.0153 7.4861 15.0256 5.00507 15.0256C2.52403 15.0256 0.503906 13.0054 0.503906 10.5244"
        stroke={active ? "black" : "#A0A0A0"}
        strokeWidth="0.75"
      />
      <path
        d="M5.00419 1.23779C6.59283 1.23779 7.88729 2.53225 7.88729 4.12089V9.80863C7.88729 11.3973 6.59283 12.6917 5.00419 12.6917C3.41554 12.6917 2.12109 11.3973 2.12109 9.80863V4.12089C2.12109 2.53225 3.41554 1.23779 5.00419 1.23779Z"
        stroke={active ? "black" : "#A0A0A0"}
        strokeWidth="0.75"
      />
    </svg>
  );
}
