import { rj, useRunRj } from "react-rocketjump";
import rjList, {
  nextPreviousPaginationAdapter,
} from "react-rocketjump/plugins/list";
import { api } from "../api";
import { PAGE_SIZE } from "../utils/server";

const InstallationByTokenState = rj({
  effect: (token) => {
    return api.get(`/installations/${token}/bytoken`);
  },
});

export function useInstallationByToken(token) {
  return useRunRj(InstallationByTokenState, [token], true);
}

const InstallationsListState = rj(
  rjList({
    pageSize: PAGE_SIZE,
    pagination: nextPreviousPaginationAdapter,
  }),
  {
    effectCaller: "configured",
    effect: (token) => (filters) => {
      return api.auth(token).get("/installations", filters);
    },
    computed: {
      list: "getList",
      pagination: "getPagination",
      numPages: "getNumPages",
      pending: "isPending",
      error: "getError",
    },
    mutations: {
      removeInstallation: {
        effect: (t) => (id) =>
          api
            .auth(t)
            .mapResponse(() => ({
              id,
            }))
            .delete(`/installations/${id}`),
        updater: "deleteItem",
      },
      addInstallation: {
        effect: (t) => (entity) =>
          api.auth(t).post("/installations", entity),
        updater: "insertItem",
      },
      updateInstallation: {
        effect: (t) => (entity) =>
          api
            .auth(t)
            .put(
              `/installations/${entity.id}/`,
              entity
            ),
        updater: "updateItem",
      },
    },
  }
);

export function useInstallationsList(params) {
  return useRunRj(InstallationsListState, [params], false);
}
