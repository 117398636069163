export function splitEtichetta(deEtichetta) {
  if (
    deEtichetta &&
    deEtichetta.indexOf("\n") !== -1 &&
    deEtichetta.indexOf("\n") < 15
  ) {
    const etiA = deEtichetta.split("\n");
    return [etiA[0], etiA[1].slice(0, 16)];
  } else if (deEtichetta && deEtichetta.length <= 15) {
    return [deEtichetta.slice(0, 15), null];
  } else if (deEtichetta && deEtichetta.length > 15) {
    return [deEtichetta.slice(0, 15), deEtichetta.slice(15, 30)];
  } else {
    return [null, null];
  }
}

export function splitEtichettaMobile(deEtichetta) {
  if (
    deEtichetta &&
    deEtichetta.indexOf("\n") !== -1 &&
    deEtichetta.indexOf("\n") < 10
  ) {
    const etiA = deEtichetta.split("\n");
    return [etiA[0], etiA[1].slice(0, 10)];
  } else if (deEtichetta && deEtichetta.length <= 10) {
    return [deEtichetta.slice(0, 10), null];
  } else if (deEtichetta && deEtichetta.length > 10) {
    return [deEtichetta.slice(0, 10), deEtichetta.slice(10, 20)];
  } else {
    return [null, null];
  }
}
