import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { useMaybeSaveLogoFromInstallation } from '../../hooks/logo'
import stylesCommon from '../BoxWithBorders/BoxWithBorders.module.css'
import styles from './PrintingModal.module.css'

export default function PrintingModal({ isOpen, toggle }) {
  const saveLogoFromInstallation = useMaybeSaveLogoFromInstallation()
  return (
    <Modal className={styles.Modal} isOpen={isOpen} toggle={toggle} centered>
      <ModalHeader className={styles.ModalHeader} toggle={toggle}>
        <div className={styles.TitleModal}>Sei sicuro/a ?</div>
      </ModalHeader>
      <ModalBody className={styles.ModalBody}>
        <div className={styles.TextModal}>
          Conferma e stampa la tua versione del marchio Milano 0.18.
        </div>
      </ModalBody>
      <ModalFooter className={styles.ModalFooter}>
        <div className="w-100 d-flex justify-content-between align-items-center">
          <div onClick={toggle} className={stylesCommon.ButtonCancel}>
            Annulla
          </div>
          <div
            className={stylesCommon.ButtonConfirm}
            onClick={async () => {
              await saveLogoFromInstallation()
              window.print()
            }}
          >
            Conferma
          </div>
        </div>
      </ModalFooter>
    </Modal>
  )
}
