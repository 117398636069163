import {
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import styles from "./ModalInstallation.module.css";
import { Field, Formik } from "formik";
import ageRange06 from "./assets/0-6.svg";
import ageRange614 from "./assets/6-14.svg";
import ageRange1418 from "./assets/14-18.svg";
import ageRange018 from "./assets/0-18.svg";
import SaveToArchive from "../InstallationCard/components/SaveToArchive";
import SendEmail from "../InstallationCard/components/SendEmail";
import Personalization from "../InstallationCard/components/Personalization";
import Printer from "../InstallationCard/components/Printer";
import Microphone from "../InstallationCard/components/Microphone";
import Download from "../InstallationCard/components/Download";

function BlockAgeRange({ value, setFieldValue }) {
  return (
    <div className="mt-3 d-flex align-items-center justify-content-between">
      <div
        className={styles.AgeRangeItem}
        onClick={() => setFieldValue("theme", "0-6")}
      >
        <div
          className={
            value && value === "0-6" ? styles.ActiveAgeRange : styles.AgeRange
          }
        >
          <img src={ageRange06} alt="0-6" />
        </div>
        <div className="mt-3">0-6</div>
      </div>
      <div
        className={styles.AgeRangeItem}
        onClick={() => setFieldValue("theme", "6-14")}
      >
        <div
          className={
            value && value === "6-14" ? styles.ActiveAgeRange : styles.AgeRange
          }
        >
          <img src={ageRange614} alt="6-14" />
        </div>
        <div className="text-nowrap mt-3">6-14</div>
      </div>
      <div
        className={styles.AgeRangeItem}
        onClick={() => setFieldValue("theme", "14-18")}
      >
        <div
          className={
            value && value === "14-18" ? styles.ActiveAgeRange : styles.AgeRange
          }
        >
          <img src={ageRange1418} alt="14-18" />
        </div>
        <div className="text-nowrap mt-3">14-18</div>
      </div>
      <div
        className={styles.AgeRangeItem}
        onClick={() => {
          setFieldValue("theme", "0-18");
          setFieldValue("etichetta", "");
        }}
      >
        <div
          className={
            value && value === "0-18" ? styles.ActiveAgeRange : styles.AgeRange
          }
        >
          <img src={ageRange018} alt="0-18" />
        </div>
        <div className="text-nowrap mt-3">0-18</div>
      </div>
    </div>
  );
}

const initialValues = {
  name: "",
  etichetta: "",
  domanda: "Cosa ti piace di Milano?",
  theme: "0-18",
  feature_mic_always_active: false,
  feature_print: false,
  feature_customization: false,
  feature_archive: false,
  feature_send: false,
  feature_download: false,
  show_domanda: true,
  show_didascalia: true,
};

export default function ModalInstallations({
  isOpen,
  toggle,
  installation,
  save,
}) {
  return (
    <Modal className={styles.Modal} isOpen={isOpen} toggle={toggle} centered>
      <ModalHeader toggle={toggle} className={styles.ModalHeader}>
        {installation ? `Modifica ${installation.name}` : "Crea installazione"}
      </ModalHeader>
      <Formik
        initialValues={installation ? installation : initialValues}
        validate={(values) => {}}
        onSubmit={(values, { setSubmitting }) => {
          save(values);
        }}
      >
        {({ values, handleSubmit, setFieldValue }) => (
          <>
            <ModalBody className={styles.ModalBody}>
              <div>
                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <label className={styles.Label} htmlFor="name">
                      Nome
                    </label>
                    <div>
                      <Field
                        id="name"
                        name="name"
                        className="input-name w-100"
                        placeholder="| Inserisci il nome installazione"
                      />
                    </div>
                  </div>
                  <div className="mb-4">
                    <label className={styles.Label} htmlFor="domanda">
                      <div className="d-flex justify-content-between align-items-center">
                        <div>Domanda</div>
                        <FormGroup switch>
                          <Input
                            type="switch"
                            checked={values.show_domanda}
                            onChange={() => {
                              setFieldValue(
                                "show_domanda",
                                !values.show_domanda
                              );
                              if (values.show_domanda) {
                                setFieldValue("domanda", "");
                              }
                            }}
                          />
                          <Label className={styles.LabelSwitch} check>
                            <span className={styles.ShowDidascalia}>
                              Mostra Domanda
                            </span>
                          </Label>
                        </FormGroup>
                      </div>
                    </label>
                    <div>
                      <Field
                        disabled={!values.show_domanda}
                        id="domanda"
                        name="domanda"
                        className="input-name w-100"
                        placeholder="| Inserisci la tua domanda"
                      />
                    </div>
                    <FormGroup switch>
                      <Input
                        disabled={!values.show_domanda}
                        type="switch"
                        checked={values.show_didascalia}
                        onChange={() => {
                          setFieldValue(
                            "show_didascalia",
                            !values.show_didascalia
                          );
                        }}
                      />
                      <Label className={`${styles.LabelSwitch} mt-2`} check>
                        <span className={styles.ShowDidascalia}>
                          Mostra Didascalia
                        </span>
                      </Label>
                    </FormGroup>
                  </div>
                  <div className="mb-4">
                    <label className={styles.Label} htmlFor="theme">
                      SELEZIONA FASCIA D’ETà
                    </label>
                    <div>
                      <BlockAgeRange
                        value={values.theme}
                        setFieldValue={setFieldValue}
                      />
                    </div>
                  </div>
                  <div className="mb-3">
                    <label className={styles.Label} htmlFor="etichetta">
                      Etichetta
                    </label>
                    <div>
                      <Field
                        id="etichetta"
                        name="etichetta"
                        disabled={values.theme === "0-18"}
                        className="input-name w-100"
                        placeholder="| Inserisci l'etichetta del logo"
                      />
                    </div>
                  </div>
                  <div className={`mb-4 ${styles.OptionsBlock}`}>
                    <label className={styles.Label} htmlFor="etichetta">
                      Opzioni
                    </label>
                    <div className="mt-2">
                      <FormGroup switch>
                        <Input
                          type="switch"
                          checked={values.feature_mic_always_active}
                          onChange={() => {
                            setFieldValue(
                              "feature_mic_always_active",
                              !values.feature_mic_always_active
                            );
                            if (!values.feature_mic_always_active) {
                              setFieldValue("feature_send", false);
                              setFieldValue("feature_archive", false);
                              setFieldValue("feature_customization", false);
                              setFieldValue("feature_download", false);
                              setFieldValue("feature_print", false);
                            }
                          }}
                        />
                        <Label className={styles.LabelSwitch} check>
                          <Microphone active />
                          <span className="ms-2">Microfono sempre attivo</span>
                        </Label>
                      </FormGroup>
                    </div>
                    <div className="mt-2">
                      <FormGroup switch>
                        <Input
                          type="switch"
                          disabled={values.feature_mic_always_active}
                          checked={values.feature_print}
                          onChange={() => {
                            setFieldValue(
                              "feature_print",
                              !values.feature_print
                            );
                            
                          }}
                        />
                        <Label className={styles.LabelSwitch} check>
                          <Printer active />
                          <span className="ms-2">Stampa</span>
                        </Label>
                      </FormGroup>
                    </div>
                    <div className="mt-2">
                      <FormGroup switch>
                        <Input
                          type="switch"
                          disabled={values.feature_mic_always_active}
                          checked={values.feature_customization}
                          onChange={() => {
                            setFieldValue(
                              "feature_customization",
                              !values.feature_customization
                            );
                          }}
                        />
                        <Label className={styles.LabelSwitch} check>
                          <Personalization active />
                          <span className="ms-2">Personalizza</span>
                        </Label>
                      </FormGroup>
                    </div>
                    <div className="mt-2">
                      <FormGroup switch>
                        <Input
                          disabled={values.feature_mic_always_active}
                          type="switch"
                          checked={values.feature_send}
                          onChange={() => {
                            setFieldValue("feature_send", !values.feature_send);
                          }}
                        />
                        <Label className={styles.LabelSwitch} check>
                          <SendEmail active />
                          <span className="ms-2">Invia</span>
                        </Label>
                      </FormGroup>
                    </div>
                    <div className="mt-2">
                      <FormGroup switch>
                        <Input
                          disabled={values.feature_mic_always_active}
                          type="switch"
                          checked={values.feature_archive}
                          onChange={() => {
                            setFieldValue(
                              "feature_archive",
                              !values.feature_archive
                            );

                          }}
                        />
                        <Label className={styles.LabelSwitch} check>
                          <SaveToArchive active />
                          <span className="ms-2">Archivio</span>
                        </Label>
                      </FormGroup>
                    </div>
                    <div className="mt-2">
                      <FormGroup switch>
                        <Input
                          disabled={values.feature_mic_always_active}
                          type="switch"
                          checked={values.feature_download}
                          onChange={() => {
                            setFieldValue(
                              "feature_download",
                              !values.feature_download
                            );
                          }}
                        />
                        <Label className={styles.LabelSwitch} check>
                          <Download active />
                          <span className="ms-2">Scarica</span>
                        </Label>
                      </FormGroup>
                    </div>
                  </div>
                </form>
              </div>
            </ModalBody>
            <ModalFooter className={styles.ModalFooter}>
              <div className="w-100 d-flex justify-content-between align-items-center">
                <div onClick={toggle} className={styles.ButtonCancel}>
                  Annulla
                </div>
                <div
                  className={
                    values.name !== ""
                      ? styles.ButtonConfirm
                      : styles.ButtonConfirmDisabled
                  }
                  onClick={() => {
                    if (values.name !== "") {
                      handleSubmit();
                    }
                  }}
                >
                  Conferma
                </div>
              </div>
            </ModalFooter>
          </>
        )}
      </Formik>
    </Modal>
  );
}
