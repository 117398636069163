import { Link, NavLink, Outlet } from "react-router-dom";
import { useAuthActions, useAuthUser } from "use-eazy-auth";
import styles from "./Layout.module.css";

export default function Layout() {
  const { user } = useAuthUser();
  const { logout } = useAuthActions();
  return (
    <div className="px-5 pt-5">
      <header className="px-2 d-flex justify-content-between align-items-start">
        <div>
          <div className="m-0 p-0">
            <div className={styles.SloganMilano}>Milano 0.18</div>
            <div className={styles.SubSlogan}>
              Servizi per il benessere <br /> di bambine e bambini, <br />{" "}
              ragazze e ragazzi
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center">
          <Link
            to={user.is_staff ? "/pro" : "/tool"}
            className={`${styles.MenuItem} me-3`}
          >
            Tool
          </Link>
          <NavLink
            to="installations"
            className={(navData) =>
              navData.isActive
                ? `${styles.MenuItemActive} me-3`
                : `${styles.MenuItem} me-3`
            }
          >
            Installazioni
          </NavLink>
          <NavLink
            to="archive"
            className={(navData) =>
              navData.isActive
                ? `${styles.MenuItemActive} me-3`
                : `${styles.MenuItem} me-3`
            }
          >
            Archivio
          </NavLink>
          <button className={styles.ButtonLogout} onClick={() => logout()}>
            Logout
          </button>
        </div>
      </header>
      <div className="container-fluid">
        <Outlet />
      </div>
    </div>
  );
}
