import classNames from "classnames";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import { useContext, useState } from "react";
import useDimensions from "react-use-dimensions";
import { ParametersContext, PlayerContext } from "../../contexts";
import { useMaybeSaveLogoFromInstallation } from "../../hooks/logo";
import { COLOR_THEMES } from "../../utils/colors";
import { makeLogoImage } from "../../utils/downloads";
import { splitEtichetta } from "../../utils/text";
import { getSuppertedVideoExtension } from "../../utils/video";
import BoxWithBorders, { BoxContent } from "../BoxWithBorders";
import Logo from "../Logo";
import styles from "./BoxSave.module.css";

const PADDING = 10;

const MiniCheck = ({ active, value, toggle }) => (
  <div
    onClick={() => toggle(value)}
    className={classNames(styles.Check, {
      [styles.Active]: active,
    })}
  />
);

function BoxSaveBody({
  onConfirm,
  toggleBox,
  etichettaPrimaRiga,
  etichettaSecondaRiga,
}) {
  const { audioBlob } = useContext(PlayerContext);
  const { params, colorTheme, videoColorTheme, videoEtichetta } =
    useContext(ParametersContext);
  const [videoEtichetta1, videoEtichetta2] = splitEtichetta(videoEtichetta);

  const [ref, dimensions] = useDimensions();

  const variants = ["canonical"];
  if (audioBlob) {
    variants.push("video");
  }
  const hasCustomization = etichettaPrimaRiga !== null;
  if (hasCustomization) {
    variants.push("custom");
  }

  const count = variants.length;
  const size =
    dimensions.width > 0
      ? (dimensions.width - (count - 1) * PADDING) / count
      : 0;

  const [selectedVariants, setSelectedVariants] = useState(variants);
  const maybeSaveLogoFromInstallation = useMaybeSaveLogoFromInstallation();

  async function save() {
    const zip = new JSZip();
    if (selectedVariants.includes("video")) {
      zip.file(`video.${getSuppertedVideoExtension()}`, audioBlob);
    }
    if (selectedVariants.includes("custom")) {
      const svgNode = document.querySelector(
        ".export-custom .svg-container > svg"
      );
      const image = await makeLogoImage(svgNode);
      zip.file("custom.png", image);
    }
    if (selectedVariants.includes("canonical")) {
      const svgNode = document.querySelector(
        ".export-canonical .svg-container > svg"
      );
      const image = await makeLogoImage(svgNode);
      zip.file("canonical.png", image);
    }
    const zipBlob = await zip.generateAsync({ type: "blob" });
    saveAs(zipBlob, "logo.zip");
    try {
      await maybeSaveLogoFromInstallation();
    } catch (err) {
      console.error("Failed to save logo from installtion...", err);
    }
    onConfirm();
  }

  function toggle(value) {
    if (!selectedVariants.includes(value)) {
      setSelectedVariants((v) => v.concat(value));
    } else {
      setSelectedVariants((v) => v.filter((x) => x !== value));
    }
  }

  const [acceptPrivacy, setAcceptPrivacy] = useState(false);

  return (
    <BoxContent
      showButtons={true}
      titleClass="normal"
      title="Scarica"
      toggleBox={toggleBox}
      isDisabled={selectedVariants.length === 0 || !acceptPrivacy}
      onConfirm={save}
      textButtonConfirm={"SCARICA"}
    >
      <div className="d-flex h-100 flex-column justify-content-between">
        <div>
          <div className={styles.Caption}>Seleziona Contenuti</div>
          <div className={styles.SelectAll}>
            <MiniCheck
              active={selectedVariants.length === variants.length}
              toggle={() => {
                if (
                  selectedVariants.length === 0 ||
                  selectedVariants.length < variants.length
                ) {
                  setSelectedVariants(variants);
                } else {
                  setSelectedVariants([]);
                }
              }}
            />
            <div className={styles.SelectAllText}>Seleziona Tutto</div>
          </div>
          <div ref={ref} className={styles.PreviewContainer}>
            {size > 0 && (
              <>
                {variants.includes("video") && (
                  <div className={styles.LogoWrapper}>
                    <Logo
                      maschera0Color={
                        COLOR_THEMES[videoColorTheme].maschera0Color
                      }
                      mascheraPuntoColor={
                        COLOR_THEMES[videoColorTheme].mascheraPuntoColor
                      }
                      maschera8Color={
                        COLOR_THEMES[videoColorTheme].maschera8Color
                      }
                      etichettaPrimaRiga={videoEtichetta1}
                      etichettaSecondaRiga={videoEtichetta2}
                      etichettaColor={
                        COLOR_THEMES[videoColorTheme].etichettaColor
                      }
                      etichettaBackgroundColor={
                        COLOR_THEMES[videoColorTheme].etichettaBackgroundColor
                      }
                      width={size}
                      height={size}
                      {...params}
                    />
                    {selectedVariants.includes("video") && (
                      <div className={styles.LogoActive} />
                    )}
                    <div
                      className={
                        selectedVariants.includes("video")
                          ? styles.MiniCamera
                          : styles.MiniCameraBlack
                      }
                    />
                    <MiniCheck
                      active={selectedVariants.includes("video")}
                      value={"video"}
                      toggle={toggle}
                    />
                  </div>
                )}
                <div
                  className={classNames(styles.LogoWrapper, "export-canonical")}
                >
                  <Logo
                    containerBackground={"white"}
                    backgroundColor="transparent"
                    {...params}
                    width={size}
                    height={size}
                  />
                  {selectedVariants.includes("canonical") && (
                    <div className={styles.LogoActive} />
                  )}
                  <MiniCheck
                    active={selectedVariants.includes("canonical")}
                    value={"canonical"}
                    toggle={toggle}
                  />
                </div>
                {variants.includes("custom") && (
                  <div
                    className={classNames(styles.LogoWrapper, "export-custom")}
                  >
                    {selectedVariants.includes("custom") && (
                      <div className={styles.LogoActive} />
                    )}
                    <Logo
                      {...params}
                      width={size}
                      height={size}
                      containerBackground={"white"}
                      backgroundColor="transparent"
                      maschera0Color={COLOR_THEMES[colorTheme].maschera0Color}
                      mascheraPuntoColor={
                        COLOR_THEMES[colorTheme].mascheraPuntoColor
                      }
                      maschera8Color={COLOR_THEMES[colorTheme].maschera8Color}
                      etichettaPrimaRiga={etichettaPrimaRiga}
                      etichettaSecondaRiga={etichettaSecondaRiga}
                      etichettaColor={COLOR_THEMES[colorTheme].etichettaColor}
                      etichettaBackgroundColor={
                        COLOR_THEMES[colorTheme].etichettaBackgroundColor
                      }
                    />
                    <MiniCheck
                      active={selectedVariants.includes("custom")}
                      value={"custom"}
                      toggle={toggle}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        <div className={styles.PrivacyLabel}>
          Scaricando il marchio la tua registrazione verrà archiviata in forma
          rigorosamente anonima.{" "}
          <strong>
            Prima di procedere clicca{" "}
            <a
              className="link-privacy"
              target={"_blank"}
              href="/Informativa Milano 0.18.pdf"
            >
              qui
            </a>{" "}
            per leggere l’informativa.
          </strong>
          <div className="mt-2 d-flex align-items-center">
            <MiniCheck
              active={acceptPrivacy}
              value={"custom"}
              toggle={() => setAcceptPrivacy(!acceptPrivacy)}
            />
            <div className={styles.ReadInformativa}>
              Ho letto l'informativa
            </div>
          </div>
        </div>
      </div>
    </BoxContent>
  );
}

export default function BoxSave({
  isOpen,
  onConfirm,
  toggleBox,
  etichettaPrimaRiga,
  etichettaSecondaRiga,
  isModal = false,
  isPro,
}) {
  return (
    <BoxWithBorders
      isPro={isPro}
      isOpen={isOpen}
      isModal={isModal}
      bodyBox={
        <BoxSaveBody
          isOpen={isOpen}
          onConfirm={onConfirm}
          toggleBox={toggleBox}
          etichettaPrimaRiga={etichettaPrimaRiga}
          etichettaSecondaRiga={etichettaSecondaRiga}
        />
      }
    />
  );
}
