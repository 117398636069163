export default function SaveToArchive({ active }) {
  return (
    <svg
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_814_1114)">
        <path
          d="M14.1457 0.901367H2.25066L1.20508 1.82394V14.1539L2.27527 15.0982H16.2738L17.2948 14.1973V3.67995L14.1457 0.901367Z"
          stroke={active ? "black" : "#A0A0A0"}
          strokeWidth="0.75"
          strokeLinejoin="round"
        />
        <path
          d="M13.0879 0.901367H4.04663V7.46794H13.0879V0.901367Z"
          stroke={active ? "black" : "#A0A0A0"}
          strokeWidth="0.75"
          strokeLinejoin="round"
        />
        <path
          d="M3.86206 12.4717H14.6377"
          stroke={active ? "black" : "#A0A0A0"}
          strokeWidth="0.75"
          strokeLinejoin="round"
        />
        <path
          d="M6.11328 2.37793V5.93799"
          stroke={active ? "black" : "#A0A0A0"}
          strokeWidth="0.75"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_814_1114">
          <rect
            width="17"
            height="15"
            fill="white"
            transform="translate(0.75 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
