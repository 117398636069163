import { useState, useMemo, useEffect } from "react";
import styles from "./BoxSatelliteBrandMobile.module.css";
import arrowRight from "../../assets/right-arrow.svg";
import ageRange06 from "../BoxSatelliteBrand/assets/0-6.svg";
import ageRange614 from "../BoxSatelliteBrand/assets/6-14.svg";
import ageRange1418 from "../BoxSatelliteBrand/assets/14-18.svg";
import ageRange018 from "../BoxSatelliteBrand/assets/0-18.svg";
import classNames from "classnames";

function BoxAgeRange({
  ageRange,
  setAgeRange,
  setBoxAge,
  setBoxLabel,
  toggleBox,
  onConfirm,
  setName,
  name,
}) {
  const initialEtichetta = useMemo(() => {
    return name || "";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialAgeRange = useMemo(() => {
    return ageRange || "0-18";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className={`mb-5`}>
      <div className="mt-4">
        <div className={styles.SubtitleBox}>Seleziona fascia d'eta</div>
        <div className="mt-3 d-flex align-items-center">
          <div
            className={styles.AgeRangeItem}
            onClick={() => setAgeRange("0-6")}
          >
            <div
              className={
                ageRange && ageRange === "0-6"
                  ? styles.ActiveAgeRange
                  : styles.AgeRange
              }
            >
              <img src={ageRange06} alt="0-6" />
            </div>
            <div className="mt-3">0-6</div>
          </div>
          <div
            className={styles.AgeRangeItem}
            onClick={() => setAgeRange("6-14")}
          >
            <div
              className={
                ageRange && ageRange === "6-14"
                  ? styles.ActiveAgeRange
                  : styles.AgeRange
              }
            >
              <img src={ageRange614} alt="6-14" />
            </div>
            <div className="text-nowrap mt-3">6-14</div>
          </div>
          <div
            className={styles.AgeRangeItem}
            onClick={() => setAgeRange("14-18")}
          >
            <div
              className={
                ageRange && ageRange === "14-18"
                  ? styles.ActiveAgeRange
                  : styles.AgeRange
              }
            >
              <img src={ageRange1418} alt="14-18" />
            </div>
            <div className="text-nowrap mt-3">14-18</div>
          </div>
          <div
            className={styles.AgeRangeItem}
            onClick={() => setAgeRange("0-18")}
          >
            <div
              className={
                ageRange && ageRange === "0-18"
                  ? styles.ActiveAgeRange
                  : styles.AgeRange
              }
            >
              <img src={ageRange018} alt="0-18" />
            </div>
            <div className="text-nowrap mt-3">0-18</div>
          </div>
        </div>
      </div>
      <div className={styles.ButtonsPersonalization}>
        <div
          className={styles.ButtonCancel}
          onClick={() => {
            setAgeRange(initialAgeRange);
            setName(initialEtichetta);
            toggleBox();
          }}
        >
          Annulla
        </div>
        <div
          className={
            ageRange ? styles.ButtonNextStep : styles.ButtonNextStepDisabled
          }
          onClick={() => {
            if (ageRange) {
              if (ageRange === "0-18") {
                onConfirm();
              } else {
                setBoxLabel(true);
                setBoxAge(false);
              }
            }
          }}
        >
          {ageRange === "0-18" ? "Conferma" : "Avanti"}
          {ageRange !== "0-18" && (
            <span className="ms-2">
              <img src={arrowRight} alt="Avanti" />
            </span>
          )}
        </div>
      </div>
    </div>
  );
}

function BoxLabel({
  setName,
  name,
  onConfirm,
  setBoxAge,
  setBoxLabel,
  ageRange,
}) {
  const initialEtichetta = useMemo(() => {
    return name || "";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="mb-5" style={{ height: 125 }}>
      <div className="mt-4">
        <div className={styles.SubtitleBox}>Inserisci testo etichetta</div>
        <div className="mt-3">
          <textarea
            required
            maxLength={
              name.indexOf("\n") === -1
                ? 20
                : name.split("\n")[0].length > 10
                ? 20
                : name.split("\n")[0].length + 11
            }
            className={"input-name w-100"}
            placeholder={"| Inserire massimo 20 caratteri"}
            type={"text"}
            value={name || ""}
            onChange={(e) => {
              if (name.indexOf("\n") !== -1) {
                if (e.nativeEvent.inputType === "insertLineBreak") return;
              }
              setName(e.target.value);
            }}
          />
          {name.indexOf("\n") === -1 && (
            <div className={styles.MaxLength}>{(name || "").length}/20</div>
          )}
          {name.indexOf("\n") !== -1 && (
            <div className={styles.MaxLength}>
              {(name || "").length - 1}/
              {name.split("\n")[0].length > 10
                ? 20
                : name.split("\n")[0].length + 10}
            </div>
          )}
        </div>
      </div>
      <div className={styles.ButtonsPersonalization}>
        <div
          className={styles.ButtonCancel}
          onClick={() => {
            setBoxAge(true);
            setName(initialEtichetta);
            setBoxLabel(false);
          }}
        >
          Annulla
        </div>
        <div
          className={
            name !== "" ? styles.ButtonConfirm : styles.ButtonConfirmDisabled
          }
          onClick={() => {
            if (name !== "") {
              onConfirm(ageRange, name);
            }
          }}
        >
          Conferma{" "}
        </div>
      </div>
    </div>
  );
}

export default function BoxSatelliteBrandMobile({
  toggleBox,
  onConfirm,
  ageRange,
  setAgeRange,
  name,
  setName,
  isOpen,
}) {
  const [boxAge, setBoxAge] = useState(true);
  const [boxLabel, setBoxLabel] = useState(false);

  const [animation, setAnimation] = useState(isOpen ? "open" : "close");
  useEffect(() => {
    // Avoid extra render in modal mode ...
    if (isOpen) {
      setAnimation("open");
    }
  }, [isOpen]);

  const klass = classNames(styles.BoxRightDesktop, {
    [styles.BoxAnimation]: true,
    [styles.BoxClosing]: !isOpen && animation !== "close",
    [styles.BoxClose]: !isOpen && animation === "close",
    [styles.BoxOpening]: isOpen,
    [styles.BoxOpen]: animation === "open",
  });

  return (
    <div
      onTransitionEnd={() => {
        if (!isOpen) {
          setAnimation("close");
          setBoxLabel(false);
          setBoxAge(true);
        }
      }}
      className={`${styles.BoxPersonalization} pb-5 ${klass}`}
    >
      <div className={styles.DividerMobile}></div>
      <div className={`${styles.TitleBox} pt-4`}>Genera marchio satellite</div>
      {boxAge && (
        <BoxAgeRange
          ageRange={ageRange}
          setAgeRange={setAgeRange}
          setBoxLabel={setBoxLabel}
          setBoxAge={setBoxAge}
          setName={setName}
          onConfirm={() => onConfirm(ageRange, name)}
          toggleBox={toggleBox}
          name={name}
        />
      )}
      {boxLabel && (
        <BoxLabel
          setBoxAge={setBoxAge}
          onConfirm={() => onConfirm(ageRange, name)}
          setName={setName}
          setBoxLabel={setBoxLabel}
          ageRange={ageRange}
          toggleBox={toggleBox}
          name={name}
        />
      )}
    </div>
  );
}
