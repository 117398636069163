import { useContext } from "react";
import { FeaturesContext } from "../../contexts";
import styles from "./BoxTextAbout.module.css";

export default function BoxTextAbout() {
  const features = useContext(FeaturesContext);
  return (
    <div className="mt-4 pt-4 pt-md-0 mt-md-0">
      <div className={styles.Title}>{features.domanda}</div>
      <div className={`mt-4 ${styles.Subtitle}`}>
        Milano 0.18 reagisce alla tua voce!
      </div>
      {features.showDidascalia && (
        <>
          <div className={`mt-3 ${styles.Text}`}>
            Per creare il tuo marchio tieni premuto il pulsante
            con il microfono mentre rispondi alla domanda.
          </div>
          <div className={`mt-3 ${styles.Text}`}>
            Quando hai finito, scarica il tuo marchio e condividilo con gli
            amici.
          </div>
        </>
      )}
    </div>
  );
}
