import React, { useEffect, useId, useRef, useState } from "react";

function Lettere({ outline = false }) {
  return (
    <g className={`lettere ${outline ? "lettere-outline" : ""}`}>
      <g id="Lettera-M-fix">
        <g>
          <rect
            vectorEffect="non-scaling-stroke"
            x="204.4"
            y="341.2"
            width="10"
            height="13.5"
          />
        </g>
        <g>
          <rect
            vectorEffect="non-scaling-stroke"
            x="233.6"
            y="341.2"
            width="10"
            height="13.5"
          />
        </g>
        <g>
          <rect
            vectorEffect="non-scaling-stroke"
            x="175.1"
            y="341.2"
            width="10"
            height="13.5"
          />
        </g>
      </g>
      <g id="Lettera-M-stretch_00000150093680230277679490000009841072804215433126_">
        <g>
          <rect
            vectorEffect="non-scaling-stroke"
            x="204.4"
            y="341.2"
            width="10"
            height="5"
          />
        </g>
        <g>
          <rect
            vectorEffect="non-scaling-stroke"
            x="233.6"
            y="341.2"
            width="10"
            height="5"
          />
        </g>
        <g>
          <rect
            vectorEffect="non-scaling-stroke"
            x="175.1"
            y="341.2"
            width="10"
            height="5"
          />
        </g>
      </g>
      <path
        id="Lettera-M-move"
        d="M224,291.7c-5.8,0-11.1,2.6-14.7,6.6c-3.6-4.1-8.8-6.6-14.6-6.6c-10.8,0-19.6,8.8-19.6,19.6v34.9h10
v-34.9c0-5.2,4.4-9.6,9.6-9.6c5.3,0,9.7,4.3,9.7,9.6l0,0v34.9h10v-34.9c0-5.3,4.3-9.6,9.6-9.6s9.6,4.3,9.6,9.6v34.9h10v-34.9
C243.6,300.5,234.8,291.7,224,291.7z"
      />

      <g id="Lettera-i-fix_00000039113136045550796670000002999954877469910186_">
        <g>
          <rect
            vectorEffect="non-scaling-stroke"
            x="263.6"
            y="319.4"
            width="10"
            height="35.2"
          />
        </g>
      </g>
      <g id="Lettera-i-stretch_00000028283990039241877230000008293429734191623811_">
        <g>
          <rect
            vectorEffect="non-scaling-stroke"
            x="263.6"
            y="319.4"
            width="10"
            height="5"
          />
        </g>
      </g>
      <g id="Lettera-i-move_00000133531103568938591630000007737024166947349123_">
        <g>
          {outline && (
            <circle
              cx="268.65"
              cy="297.3"
              r="20"
              className="outline-pallino-i"
            ></circle>
          )}
          <path d="M263.4,296.9c0-6.8,10.5-6.8,10.5,0C273.9,303.8,263.4,303.8,263.4,296.9z" />
        </g>
      </g>

      <g id="Lettera-L-fix_00000177451248097963969380000000491300031849607605_">
        <g>
          <path d="M326,354.6h-27.7c-2.8,0-5-2.2-5-5v-57.5h10v52.5H326V354.6z" />
        </g>
      </g>
      <g id="Lettera-L-stretch_00000062889576357596688730000008347679765829315726_">
        <g>
          <rect
            vectorEffect="non-scaling-stroke"
            x="293.3"
            y="292.1"
            width="10"
            height="5"
          />
        </g>
      </g>
      <g id="Lettera-A-fix">
        <g>
          <rect
            vectorEffect="non-scaling-stroke"
            x="338"
            y="341.2"
            width="10"
            height="13.5"
          />
        </g>
        <g>
          <rect
            vectorEffect="non-scaling-stroke"
            x="372.4"
            y="341.2"
            width="10"
            height="13.5"
          />
        </g>
      </g>
      <g id="Lettera-A-stretch_00000178885650139355755220000007730063134551048067_">
        <g>
          <rect
            vectorEffect="non-scaling-stroke"
            x="338"
            y="341.2"
            width="10"
            height="5"
          />
        </g>
        <g>
          <rect
            vectorEffect="non-scaling-stroke"
            x="372.4"
            y="341.2"
            width="10"
            height="5"
          />
        </g>
      </g>
      <path
        id="Lettera-A-move"
        d="M360.2,291.7c-12.2,0-22.2,10-22.2,22.2v32.3h10v-13.6h24.4v13.6h10v-32.3
C382.4,301.7,372.4,291.7,360.2,291.7z M348,322.6v-8.7c0-6.7,5.5-12.2,12.2-12.2s12.2,5.5,12.2,12.2v8.7H348z"
      />
      <g id="Lettera-N-fix">
        <g>
          <rect
            vectorEffect="non-scaling-stroke"
            x="399.4"
            y="341.2"
            width="10"
            height="13.5"
          />
        </g>
        <g>
          <rect
            vectorEffect="non-scaling-stroke"
            x="433.8"
            y="341.2"
            width="10"
            height="13.5"
          />
        </g>
      </g>
      <g id="Lettera-N-stretch_00000125601926862837303920000003832936342903754657_">
        <g>
          <rect
            vectorEffect="non-scaling-stroke"
            x="399.4"
            y="341.2"
            width="10"
            height="5"
          />
        </g>
        <g>
          <rect
            vectorEffect="non-scaling-stroke"
            x="433.8"
            y="341.2"
            width="10"
            height="5"
          />
        </g>
      </g>
      <g id="Lettera-N-move_00000016058411278864535180000001036714866436003747_">
        <g>
          <path
            d="M443.8,346.2h-10v-32.3c0-6.7-5.5-12.2-12.2-12.2c-6.7,0-12.2,5.5-12.2,12.2v32.3h-10v-32.3c0-12.2,10-22.2,22.2-22.2
c12.2,0,22.2,10,22.2,22.2V346.2z"
          />
        </g>
      </g>
      <path
        id="Lettera-O-fix"
        d="M501.9,321v5c0,1.4,0,2.6,0,3.5c0,1.6-0.1,3.1-0.6,4.6c-0.8,2.8-2.2,5.1-4.4,7
c-2.9,2.6-6.2,3.8-10.4,3.8s-7.7-1.2-10.6-3.8c-2.2-1.9-3.6-4.2-4.4-7c-0.4-1.5-0.6-3.1-0.6-4.6c0-0.9,0-2,0-3.5v-5h-10v5
c0,3.7,0,5.8,0,5.9c0.3,6.7,3.5,12.4,8.3,16.7c4.8,4.2,10.5,6.3,17.2,6.3s12.4-2.1,17.2-6.3c4.8-4.2,7.9-9.9,8.3-16.7
c0-0.1,0-2.2,0-5.9v-5H501.9z"
      />
      <path
        id="Lettera-O-move"
        d="M511.9,314.7c-0.4-7-3.5-12.5-8.4-16.7c-4.8-4.2-10.6-6.3-17.1-6.3s-12.2,2.1-17.1,6.3
c-4.9,4.2-8,9.7-8.4,16.7c0,0.3,0,2.3,0,6.3v5h10v-5c0-1.6,0-2.9,0-3.9c0-1.6,0.1-3.1,0.6-4.6c0.8-2.8,2.2-5.1,4.4-7
c3-2.6,6.5-3.9,10.5-3.9c4.1,0,7.5,1.3,10.5,3.8c2.2,1.9,3.7,4.2,4.4,7c0.4,1.5,0.6,3.1,0.6,4.6c0,0.9,0,2.2,0,3.8v5.2h10v-5.2
C511.9,317,511.9,314.8,511.9,314.7z"
      />
      <g id="Lettera-O-stretch">
        <rect
          vectorEffect="non-scaling-stroke"
          x="501.9"
          y="321"
          width="10"
          height="5"
          strokeDasharray={`0 10 1000 0`}
        />
        <rect
          vectorEffect="non-scaling-stroke"
          x="460.9"
          y="321"
          width="10"
          height="5"
          strokeDasharray={`0 10 1000 0`}
        />
      </g>
      <path
        id="Numero-0-fix"
        d="M410.5,407.1c0-7.4,0.1-9.7,0.2-10.4c0.3-3,1.4-5.2,3.4-6.9c2.1-1.8,4.4-2.6,7.2-2.6s5.4,0.9,7.4,2.6
s3.1,3.9,3.4,6.9c0.1,0.7,0.2,3.1,0.2,10.4c0,0.9,0,4.9,0,11.8c0,1.5,0,3.2,0,5h10c0-1.8,0-3.5,0-5c0-6.9,0-10.9,0-11.8
c0-8-0.1-10.3-0.2-11.4c-0.5-5.5-2.8-10.1-6.8-13.5c-3.9-3.4-8.7-5.1-13.9-5.1s-9.8,1.7-13.7,5.1c-4,3.4-6.3,8-6.8,13.5
c-0.1,1-0.2,3.4-0.2,11.4c0,0.9,0,4.9,0,11.8v5h10v-5C410.5,411.9,410.5,407.9,410.5,407.1z"
      />
      <path
        id="Numero-0-move"
        d="M432.3,418.9v5c0,2.8-0.1,4-0.2,4.5c-0.3,3-1.4,5.2-3.4,6.9c-2.1,1.8-4.4,2.6-7.2,2.6s-5.2-0.9-7.2-2.6
s-3.1-3.9-3.4-6.9c0-0.5-0.1-1.7-0.2-4.5l0-5h-10v5c0,3.4,0.2,4.8,0.2,5.5c0.5,5.5,2.8,10.1,6.8,13.5c3.9,3.4,8.5,5.1,13.7,5.1
s10-1.7,13.9-5.1c4-3.4,6.3-8,6.8-13.5c0.1-0.7,0.2-2.1,0.2-5.5v-5H432.3z"
      />
      <g id="Numero-0-stretch">
        <rect
          vectorEffect="non-scaling-stroke"
          x="432.3"
          y="418.9"
          width="10"
          height="5"
        />
        <rect
          vectorEffect="non-scaling-stroke"
          x="400.7"
          y="418.9"
          width="10"
          height="5"
        />
      </g>
      <g id="Numero-punto-move_00000174595291317710820110000001798979602325153704_">
        <g>
          <path d="M455.4,443.2c0-6.8,10.5-6.8,10.5,0S455.4,450,455.4,443.2z" />
        </g>
      </g>
      <g id="Numero-1-fix_00000099647166791542781500000017630300347976929965_">
        <g>
          <polygon points="495.5,447.7 485.5,447.7 485.5,387.4 477,387.4 477,377.4 495.5,377.4 		" />
        </g>
      </g>
      <g id="Numero-1-stretch_00000001654808832813447230000018399881607072128938_">
        <g>
          <rect
            vectorEffect="non-scaling-stroke"
            x="485.5"
            y="442.7"
            width="10"
            height="5"
          />
        </g>
      </g>
      <path
        id="Numero-8-fix_00000111150226053667004420000003394061751990607506_"
        d="M554.7,415.7c-0.5-5.2-2.7-9.5-6.4-12.7
c-0.3-0.2-0.5-0.4-0.8-0.6c2.4-2.8,3.6-6.1,3.6-9.9c0-4.3-1.6-8-4.7-10.9c-2.9-2.9-6.8-4.4-11.1-4.4c-4.4,0-8.2,1.5-11.2,4.4
c-3,2.9-4.6,6.7-4.6,10.9c0,3.7,1.2,7.1,3.6,9.9c-0.3,0.2-0.5,0.4-0.8,0.6c-3.8,3.2-5.9,7.5-6.4,12.7c0,0,0,0,0,0c0,0.2,0,3.3,0,8.9
v5.1h10v-5.1c0-4.6,0-7.4,0-8.1c0.3-2.5,1.2-4.4,3-5.9c1.9-1.6,3.9-2.3,6.4-2.3s4.6,0.7,6.4,2.3c1.7,1.5,2.7,3.4,3,5.9
c0,0.7,0,3.5,0,8.1v5.1h10v-5.1C554.7,419,554.7,415.9,554.7,415.7z M531,388.8c1.1-1.1,2.4-1.6,4.3-1.6c2.3,0,3.5,0.9,4.1,1.5
c0,0,0.1,0.1,0.1,0.1c1.1,1,1.5,2.1,1.5,3.7c0,1.5-0.5,2.7-1.6,3.8c-1.1,1.1-2.5,1.6-4.2,1.6s-3.1-0.5-4.2-1.6
c-1.1-1.1-1.6-2.3-1.6-3.8C529.5,391,530,389.8,531,388.8z"
      />
      <path
        id="Numero-8-move"
        d="M544.7,424.7v4.7c0,0.1,0,0.2,0,0.3c-0.3,2.4-1.3,4.3-3,5.7c-1.8,1.6-3.8,2.3-6.4,2.3s-4.7-0.7-6.4-2.3
c-1.6-1.4-2.6-3.3-3-5.7c0-0.1,0-0.3,0-0.3l0-4.7h-10v5c0,0.3,0,0.5,0,0.5c0.5,5.2,2.7,9.4,6.3,12.7c3.6,3.2,8,4.8,13.1,4.8
s9.4-1.6,13.1-4.8c3.7-3.2,5.8-7.5,6.4-12.7c0,0,0-0.3,0-0.5v-5H544.7z"
      />
      <g id="Numero-8-stretch">
        <g>
          <rect
            vectorEffect="non-scaling-stroke"
            x="515.9"
            y="424.7"
            width="10"
            height="5"
          />
        </g>
        <g>
          <rect
            vectorEffect="non-scaling-stroke"
            x="544.7"
            y="424.7"
            width="10"
            height="5"
          />
        </g>
      </g>
      <g className="riferimenti" style={{ display: "none" }}>
        <g id="Lettera-M-riferimento">
          <circle cx="238.60001" cy="346.19608" r="2.5" />
        </g>
        <g id="Lettera-i-riferimento">
          <circle cx="268.60001" cy="324.39999" r="2.5" />
        </g>
        <g id="Lettera-L-riferimento">
          <circle cx="298.29999" cy="297.10001" r="2.5" />
        </g>
        <g id="Lettera-A-riferimento">
          <circle cx="377.39999" cy="346.19608" r="2.5" />
        </g>
        <g id="Lettera-N-riferimento">
          <circle cx="438.79999" cy="346.19608" r="2.5" />
        </g>
        <g id="Lettera-O-riferimento">
          <circle cx="507" cy="326" r="2.5" />
        </g>
        <g id="Numero-0-riferimento">
          <circle cx="405.70001" cy="418.8504" r="2.5" />
        </g>
        <g id="Numero-1-riferimento">
          <circle cx="490.5" cy="442.70001" r="2.5" />
        </g>
        <g id="Numero-8-riferimento">
          <circle cx="520.80511" cy="424.70001" r="2.5" />
        </g>
        <g id="Maschera-punto-riferimento">
          <circle cx="460.64999" cy="443.20001" r="2.5" />
        </g>
        <g id="Maschera-0-superiore-riferimento">
          <circle cx="421.50003" cy="396.05038" r="2.5" />
        </g>
        <g id="Maschera-0-centrale-riferimento">
          <circle cx="421.50003" cy="418.8504" r="2.5" />
        </g>
        <g id="Maschera-0-inferiore-riferimento">
          <circle cx="421.50003" cy="429.70001" r="2.5" />
        </g>
        <g id="Maschera-8-superiore-riferimento">
          <circle cx="535.30005" cy="392.60001" r="2.5" />
        </g>
        <g id="Maschera-8-centrale-riferimento">
          <circle cx="535.30005" cy="424.70001" r="2.5" />
        </g>
        <g id="Maschera-8-inferiore-riferimento">
          <circle cx="535.30005" cy="429.70251" r="2.5" />
        </g>
      </g>
    </g>
  );
}

function Outlines({ id, outlineNeeds8Rect, outlineNeeds0Rect }) {
  return (
    <g className="clip-background">
      <g className="Maschera-punto-clip-outline">
        <g className="Maschera-punto-clip">
          <path
            vectorEffect="non-scaling-stroke"
            d="M460.60001,388.29999c-30.29999,0-54.89999,24.60001-54.89999,54.89999
  s24.60001,54.89999,54.89999,54.89999S515.5,473.49997,515.5,443.19998S490.89999,388.29999,460.60001,388.29999
  L460.60001,388.29999z"
          />
        </g>
      </g>

      <g className="Maschera-0-superiore-clip">
        <path
          vectorEffect="non-scaling-stroke"
          d="M356.40686,418.8504
    v-22.83401c0-36.11429,29.27643-65.39072,65.39072-65.39072h0.00012c36.11429,0,65.39072,29.27643,65.39072,65.39072v22.83401
    H350z"
        />
      </g>

      <g className="Maschera-0-centrale-clip">
        {outlineNeeds0Rect && <rect
          vectorEffect="non-scaling-stroke"
          x="356.40686"
          y="418.8504"
          className="st6 outline outline-8-centrale"
          width="130.78154"
          height="4"
        />}
      </g>

      <g className="Maschera-0-inferiore-clip-outline">
        <g className="Maschera-0-inferiore-clip">
          <path
            vectorEffect="non-scaling-stroke"
            d="M487.18842,418.04883
v11.37497c0,36.11429-29.27643,65.39069-65.39072,65.39069h-0.00012c-36.11429,0-65.39072-29.2764-65.39072-65.39069v-11.37497
H487.18842z"
          />
        </g>
      </g>

      <g className="Maschera-8-superiore-clip">
        <path
          vectorEffect="non-scaling-stroke"
          d="M595.60004,424.70001
    v-27.40002c0-33.29999-27-60.29999-60.29999-60.29999l0,0c-33.29999,0-60.29999,27-60.29999,60.29999v27.40002H590'.60004z"
        />
      </g>

      <g className="Maschera-8-centrale-clip">
        {outlineNeeds8Rect && <rect
          vectorEffect="non-scaling-stroke"
          x="475.00006"
          y="424.70001"
          className="st6 outline outline-8-centrale"
          width="120.60001"
          height="4"
        />}
      </g>
      <g className="Maschera-8-inferiore-clip-outline">
        <g className="Maschera-8-inferiore-clip">
          <path
            transform="translate(0.6, 0)"
            vectorEffect="non-scaling-stroke"
            d="M474.55963,428.41653v1.63309c0,33.29999,27,60.29999,60.29999,60.29999l0,0
c33.29999,0,60.29999-27,60.29999-60.29999v-1.63309H474.55963z"
          />
        </g>
      </g>
    </g>
  );
}

function Etichetta({
  svgWidth,
  x,
  y,
  text,
  textColor,
  bgColor,
  strokeColor,
  fontWeight,
  outline = false,
  secondLine = false,
  etichettaAsMask = false,
  isLong = false,
}) {
  const textRef = useRef(null);
  const [rect, setRect] = useState(null);

  useEffect(() => {
    function calcRect() {
      const textNode = textRef.current;
      const textRect = textNode.getBoundingClientRect();
      const scaledWidth = (textRect.width * 800) / svgWidth;
      const scaledHeight = (textRect.height * 800) / svgWidth;
      setRect({
        width: scaledWidth,
        height: scaledHeight,
      });
    }
    let canceled = false;
    document.fonts.ready.then(() => {
      if (!canceled) {
        calcRect();
      }
    });
    return () => {
      canceled = true;
    };
  }, [svgWidth, text, bgColor]);

  const outlineHeight = secondLine ? 20 : 30;
  const outlineY = secondLine ? isLong ? 10 : 5 : 15;

  const maskId = useId().replace(/:/g, "___");

  return (
    <g className="etichetta">
      {rect && outline && (
        <rect
          x={x - rect.width - 44}
          y={y - 32 - outlineY}
          width={rect.width + 29.5}
          height={rect.height + 10 + outlineHeight}
          className="outline"
        />
      )}
      {rect && (
        <rect
          fill={outline ? "none" : bgColor}
          stroke={outline ? "none" : strokeColor}
          x={x - rect.width - 27}
          y={y - 32}
          width={rect.width + 29.5}
          height={rect.height + 10}
          mask={etichettaAsMask ? `url(#mask-${maskId})` : undefined}
        ></rect>
      )}
      {rect && etichettaAsMask && (
        <mask id={`mask-${maskId}`}>
          <rect
            fill={"#fff"}
            stroke={"#fff"}
            x={x - rect.width - 27}
            y={y - 32}
            width={rect.width + 29.5}
            height={rect.height + 10}
          ></rect>
          <text
            fill="#000"
            ref={textRef}
            textAnchor="end"
            className="etichetta-text"
            fontWeight={700}
            style={{ fontWeight: `${fontWeight}` }}
            x={x - 10}
            y={y}
          >
            {text}
          </text>
        </mask>
      )}

      <text
        fill={outline || etichettaAsMask ? "none" : textColor}
        ref={textRef}
        textAnchor="end"
        className="etichetta-text"
        fontWeight={700}
        style={{ fontWeight: `${fontWeight}` }}
        x={x - 10}
        y={y}
      >
        {text}
      </text>
    </g>
  );
}

const BaseSVG = React.forwardRef(
  (
    {
      width,
      height,
      etichettaPrimaRiga,
      etichettaSecondaRiga,
      etichettaColor,
      etichettaBackgroundColor,
      etichettaStrokeColor,
      etichettaFontWeight,
      mixBlendModeRaggiera,
      outline = false,
      outlineColor = "#ffffff",
      etichettaAsMask = false,
      outlineNeeds8Rect = false,
      outlineNeeds0Rect = false,
    },
    ref
  ) => {
    const id = useId().replace(/:/g, "___");

    return (
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 800 800"
        xmlSpace="preserve"
        ref={ref}
        width={width}
        height={height}
      >
        <style>
          {`.st0 {
          fill: none;
        }

        .st3 {
          fill: url(#Raggiera-0-centrale_00000118393638505736042490000007288196373620702110_);
        }
        .st5 {
          fill: url(#Raggiera-8-centrale_00000158030732983757355720000013739136579426263973_);
        }
        .st6 {
          fill: none;
          stroke: none;
          stroke-miterlimit: 10;
        }
        .st7 {
          fill: none;
          stroke: #c6c6c6;
          stroke-miterlimit: 10;
        }
        .st8 {
          fill: none;
          stroke: #000000;
          stroke-width: 10;
          stroke-linejoin: round;
        }
        .st9 {
          fill: none;
          stroke: #000000;
          stroke-width: 10;
        }
        #Maschera-punto {
          transform-origin: 455.353363px 443.1902466px;
          /* transition: transform 0.2s linear; */
        }

        #Raggiera-punto_${id} {
          clip-path: url(#Maschera-punto-clip_${id});
        }

        #Raggiera-0-superiore_${id} {
          clip-path: url(#Maschera-0-superiore-clip_${id});
        }

        #Raggiera-0-centrale_${id} {
          clip-path: url(#Maschera-0-centrale-clip_${id});
        }

        #Raggiera-0-inferiore_${id} {
          clip-path: url(#Maschera-0-inferiore-clip_${id});

        }

        #Raggiera-8-superiore_${id} {
          clip-path: url(#Maschera-8-superiore-clip_${id});
        }

        #Raggiera-8-centrale_${id} {
          clip-path: url(#Maschera-8-centrale-clip_${id});
        }

        #Raggiera-8-inferiore_${id} {
          clip-path: url(#Maschera-8-inferiore-clip_${id});
        }

        [id^="Maschera-"],
        [id^="Maschera-"] path {
          stroke: none;
        }

        .lettere-outline path, .lettere-outline rect {
          stroke:${outlineColor};
          stroke-width: 40px;
        }

        .outline-pallino-i {
          fill: ${outlineColor};
        }

        .clip-background path {
          fill: ${outlineColor};
          stroke: ${outlineColor};
          stroke-width: 40px;
        }
        .clip-background rect.outline{
          stroke: ${outlineColor};
          fill: ${outlineColor};
          stroke-width:40px;
        }

        .etichetta-text {
          font-family: "Barlow", sans-serif;
          font-size: 28.68px;
          letter-spacing: 0.16em;
          text-transform: uppercase;
        }

        .etichetta .outline {
          fill: ${outlineColor};
        }

        `}
        </style>
        <defs>
          <clipPath
            id={`Maschera-punto-clip_${id}`}
            className="Maschera-punto-clip"
          >
            <path
              className="st8"
              d="M460.60001,388.29999c-30.29999,0-54.89999,24.60001-54.89999,54.89999
					s24.60001,54.89999,54.89999,54.89999S515.5,473.49997,515.5,443.19998S490.89999,388.29999,460.60001,388.29999
					L460.60001,388.29999z"
            />
          </clipPath>

          <clipPath
            id={`Maschera-0-superiore-clip_${id}`}
            className="Maschera-0-superiore-clip"
          >
            <path
              d="M356.40686,418.8504
            v-22.83401c0-36.11429,29.27643-65.39072,65.39072-65.39072h0.00012c36.11429,0,65.39072,29.27643,65.39072,65.39072v22.83401
            H356.40686z"
            />
          </clipPath>

          <clipPath
            id={`Maschera-0-centrale-clip_${id}`}
            className="Maschera-0-centrale-clip"
          >
            <rect
              x="356.40686"
              y="418.8504"
              className="st6"
              width="130.78154"
              height="5"
            />
          </clipPath>

          <clipPath
            id={`Maschera-0-inferiore-clip_${id}`}
            className="Maschera-0-inferiore-clip"
          >
            <path
              d="M487.18842,418.04883
	v11.37497c0,36.11429-29.27643,65.39069-65.39072,65.39069h-0.00012c-36.11429,0-65.39072-29.2764-65.39072-65.39069v-11.37497
	H487.18842z"
            />
          </clipPath>

          <clipPath
            id={`Maschera-8-superiore-clip_${id}`}
            className="Maschera-8-superiore-clip"
          >
            <path
              d="M595.60004,424.70001
            v-27.40002c0-33.29999-27-60.29999-60.29999-60.29999l0,0c-33.29999,0-60.29999,27-60.29999,60.29999v27.40002H595.60004z"
            />
          </clipPath>

          <clipPath
            id={`Maschera-8-centrale-clip_${id}`}
            className="Maschera-8-centrale-clip"
          >
            <rect
              x="475.00006"
              y="424.70001"
              className="st6"
              width="120.60001"
              height="5"
            />
          </clipPath>

          <clipPath
            id={`Maschera-8-inferiore-clip_${id}`}
            className="Maschera-8-inferiore-clip"
          >
            <path
              d="M474.55963,428.41653v1.63309c0,33.29999,27,60.29999,60.29999,60.29999l0,0
	c33.29999,0,60.29999-27,60.29999-60.29999v-1.63309H474.55963z"
            />
          </clipPath>
        </defs>

        <rect
          className="background"
          width={800}
          height={800}
          fill="white"
        ></rect>

        {outline && <Lettere outline />}
        {outline && <Outlines id={id} outlineNeeds8Rect={outlineNeeds8Rect} outlineNeeds0Rect={outlineNeeds0Rect}/>}

        {outline && (
          <g className="etichetta">
            {etichettaPrimaRiga && (
              <Etichetta
                textColor={etichettaColor}
                bgColor={etichettaBackgroundColor}
                x={380}
                y={409}
                svgWidth={width}
                text={etichettaPrimaRiga}
                strokeColor={etichettaStrokeColor}
                fontWeight={etichettaFontWeight}
                outline={outline}
              />
            )}
            {etichettaSecondaRiga && (
              <Etichetta
                textColor={etichettaColor}
                bgColor={etichettaBackgroundColor}
                x={380}
                y={456}
                isLong={etichettaSecondaRiga.length > 7}
                svgWidth={width}
                text={etichettaSecondaRiga}
                strokeColor={etichettaStrokeColor}
                fontWeight={etichettaFontWeight}
                outline={outline}
                secondLine
              />
            )}
          </g>
        )}

        <pattern
          y="800"
          width="5"
          height="5"
          patternUnits="userSpaceOnUse"
          id={`Nuovo_campione_pattern_3_${id}`}
          viewBox="0 -5 5 5"
          style={{ overflow: "visible" }}
        >
          <g>
            <rect y="-5" className="st0" width="5" height="5" />
            <rect y="-2" className="st1" width="5" height="2" />
            <rect y="-5" className="st0" width="5" height="5" />
          </g>
        </pattern>
        <pattern
          y="800"
          width="5"
          height="5"
          patternUnits="userSpaceOnUse"
          id={`Nuovo_pattern_${id}`}
          viewBox="0 -5 5 5"
          style={{ overflow: "visible" }}
        >
          <g>
            <rect y="-5" className="st0" width="5" height="5" />
            <rect y="-2" className="st2" width="5" height="2" />
            <rect y="-5" className="st0" width="5" height="5" />
          </g>
        </pattern>
        <pattern
          id={`Raggiera-0-centrale_00000074432701056997702660000000890961181962530716_${id}`}
          xlinkHref={`#Nuovo_campione_pattern_3_${id}`}
          patternTransform="matrix(0.99846 0 0 1 -9.12471 3.84948)"
        ></pattern>
        <rect
          id={`Raggiera-0-centrale_${id}`}
          className="Raggiera-0-centrale"
          x="205.35657"
          y="418.8504"
          style={{
            fill: `url(#Raggiera-0-centrale_00000074432701056997702660000000890961181962530716_${id})`,
            mixBlendMode: mixBlendModeRaggiera,
          }}
          width="432.28693"
          height="380"
        />
        <g
          id={`Raggiera-0-superiore_${id}`}
          className="Raggiera-0-superiore"
          style={{ mixBlendMode: mixBlendModeRaggiera }}
        >
          <rect
            x="205.4"
            y="411.7"
            className="st1"
            width="432.3"
            height="2.2"
          />
          <rect
            x="205.4"
            y="416.7"
            className="st1"
            width="432.3"
            height="2.2"
          />
          <rect
            x="205.4"
            y="406.5"
            className="st1"
            width="432.3"
            height="2.2"
          />
          <path
            className="st1"
            d="M449,186c-5.1-0.6-10.3-1.1-15.5-1.4l-10.8,195.2c0.6,0.1,1.1,0.1,1.5,0.2L449,186z"
          />
          <path
            className="st1"
            d="M431.1,382.4L534.3,216c-5.3-3.2-10.7-6.2-16.2-9l-88.3,174.7C430.2,381.9,430.6,382.1,431.1,382.4z"
          />
          <path
            className="st1"
            d="M427.8,380.8l64-184.8c-5.7-2-11.5-3.7-17.4-5.2l-48.1,189.6C426.8,380.5,427.3,380.7,427.8,380.8z"
          />
          <path
            className="st1"
            d="M434,384.6l135.5-141.7c-4.2-3.9-8.5-7.7-13-11.3l-123.6,152C433.2,383.9,433.6,384.2,434,384.6z"
          />
          <path
            className="st1"
            d="M438.2,390.5l184.4-69.8c-2.3-5.8-4.8-11.4-7.6-16.9l-177.5,85.3C437.8,389.7,438,390.1,438.2,390.5z"
          />
          <path
            className="st1"
            d="M439.3,394.1l195-32.6c-1-5.3-2.1-10.4-3.5-15.6l-191.9,46.8C439.1,393.1,439.2,393.6,439.3,394.1z"
          />
          <path
            className="st1"
            d="M406.3,389.3l-177.2-87.8c-2.8,5.5-5.4,11.1-7.8,16.9l184.4,72.2C405.9,390.1,406.1,389.7,406.3,389.3z"
          />
          <path
            className="st1"
            d="M405,392.6l-192.3-49c-1.4,5.2-2.6,10.4-3.7,15.7L404.6,394C404.7,393.5,404.9,393,405,392.6z"
          />
          <path
            className="st1"
            d="M417.2,380.5l-46-190.3c-5.9,1.4-11.7,3-17.4,4.9l62,185.8C416.2,380.8,416.7,380.7,417.2,380.5z"
          />
          <path
            className="st1"
            d="M420.7,379.9l-8.9-195.4c-5.2,0.2-10.3,0.6-15.3,1.2L419.3,380C419.7,380,420.2,379.9,420.7,379.9z"
          />
          <path
            className="st1"
            d="M410.8,383.8L288.6,229.9c-4.5,3.5-8.9,7.2-13.1,11.1l134.3,143.7C410.1,384.4,410.5,384.1,410.8,383.8z"
          />
          <path
            className="st1"
            d="M413.8,381.8l-86.3-176.1c-5.6,2.7-11.1,5.7-16.4,8.8l101.5,168C413,382.3,413.4,382.1,413.8,381.8z"
          />
          <rect
            x="205.4"
            y="401.3"
            className="st1"
            width="432.3"
            height="2.2"
          />
          <path
            className="st1"
            d="M637.6,397.5v-1.4l0,0c-0.1-3.2-0.2-9.9-0.4-11.7l-197.7,11.7h-35.1l-198.5-13c-0.2,2-0.3,9.5-0.4,13h-0.1v1.4
		v0.8h432.2V397.5z"
          />
          <rect x="405.7" y="390.9" className="st1" width="31.6" height="2.2" />
          <path
            className="st1"
            d="M405.7,387.9h31.6v-1.2l163-107.9c-3.6-5.3-7.5-10.4-11.5-15.4L436.1,385.7h-28.6L256,261.3
		c-4.1,4.9-8,9.9-11.7,15.2l161.4,109.7V387.9z"
          />
        </g>
        <g
          id={`Raggiera-0-inferiore_${id}`}
          className="Raggiera-0-inferiore"
          style={{ mixBlendMode: mixBlendModeRaggiera }}
        >
          <path
            className="st1"
            d="M394,639.3c5.1,0.6,10.3,1.1,15.5,1.4l10.8-195.2c-0.6-0.1-1.1-0.1-1.5-0.2L394,639.3z"
          />
          <path
            className="st1"
            d="M411.9,442.9L308.7,609.3c5.3,3.2,10.7,6.2,16.2,9l88.3-174.7C412.8,443.4,412.4,443.2,411.9,442.9z"
          />
          <path
            className="st1"
            d="M415.2,444.5l-64.1,184.8c5.7,2,11.5,3.7,17.4,5.2l48.1-189.6C416.2,444.8,415.7,444.6,415.2,444.5z"
          />
          <path
            className="st1"
            d="M409,440.7L273.6,582.5c4.2,3.9,8.5,7.7,13,11.3l123.5-152.1C409.8,441.4,409.4,441.1,409,440.7z"
          />
          <path
            className="st1"
            d="M404.8,434.8l-184.4,69.8c2.3,5.8,4.8,11.4,7.6,16.9l177.5-85.3C405.2,435.7,405,435.2,404.8,434.8z"
          />
          <path
            className="st1"
            d="M403.7,431.3l-195,32.6c1,5.3,2.1,10.4,3.5,15.6l191.9-46.8C403.9,432.2,403.8,431.7,403.7,431.3z"
          />
          <path
            className="st1"
            d="M436.7,436l177.2,87.8c2.8-5.5,5.4-11.1,7.8-16.9l-184.4-72.2C437.1,435.2,436.9,435.6,436.7,436z"
          />
          <path
            className="st1"
            d="M438,432.7l192.3,49c1.4-5.2,2.6-10.4,3.7-15.7l-195.6-34.7C438.3,431.8,438.1,432.3,438,432.7z"
          />
          <path
            className="st1"
            d="M425.8,444.8l46,190.3c5.9-1.4,11.7-3,17.4-4.9l-62-185.8C426.8,444.5,426.3,444.7,425.8,444.8z"
          />
          <path
            className="st1"
            d="M422.3,445.4l8.9,195.4c5.2-0.2,10.3-0.6,15.3-1.2l-22.8-194.3C423.3,445.3,422.8,445.4,422.3,445.4z"
          />
          <path
            className="st1"
            d="M432.2,441.5l122.2,153.9c4.5-3.5,8.9-7.2,13.1-11.1L433.2,440.6C432.9,440.9,432.5,441.2,432.2,441.5z"
          />
          <path
            className="st1"
            d="M429.2,443.5l86.3,176.1c5.6-2.7,11.1-5.7,16.4-8.8l-101.5-168C430,443,429.6,443.2,429.2,443.5z"
          />
          <path
            className="st1"
            d="M205.4,426.5v1.3v1.5l0,0c0.1,3.2,0.2,9.8,0.4,11.6l196-11.6h38.3l197,12.9c0.2-2,0.3-9.3,0.4-12.9h0.1v-1.5
		v-1.3H205.4z"
          />
          <rect x="405.7" y="432.3" className="st1" width="31.6" height="2.2" />
          <path
            className="st1"
            d="M437.3,437.5h-31.6v1.1l-163,107.9c3.6,5.3,7.5,10.4,11.5,15.4l152.7-122.2h28.7L587,564
		c4.1-4.9,8-9.9,11.7-15.2L437.3,439.1V437.5z"
          />
        </g>
        <g
          id={`Raggiera-punto_${id}`}
          className="Raggiera-punto"
          style={{ mixBlendMode: mixBlendModeRaggiera }}
        >
          <path
            className="st4"
            d="M458.50446,438.7995c0.00903-0.00439,0.0177-0.00861,0.02679-0.01294
		c0.00568-0.00275,0.01141-0.00549,0.01709-0.00818c0.01312-0.00629,0.02625-0.01245,0.03943-0.01855
		c0.00195-0.00092,0.00391-0.00183,0.00586-0.00275c0.10052-0.04663,0.20288-0.0899,0.30701-0.12976L348.06152,149.12
		c-17.94611,6.87079-35.35767,15.43927-51.75,25.46851l161.78772,264.43304
		C458.23047,438.94141,458.36566,438.86725,458.50446,438.7995z"
          />
          <path
            className="st4"
            d="M457.11902,439.81055c0.00385-0.00397,0.00775-0.00812,0.0116-0.01208
		c0.00555-0.00568,0.01105-0.01141,0.0166-0.01709c0.00922-0.0094,0.01825-0.01862,0.02753-0.02789
		c0.00323-0.0033,0.00641-0.00653,0.00964-0.00977c0.01093-0.01093,0.02185-0.02179,0.03284-0.03259
		c0.00177-0.00171,0.00354-0.00348,0.00525-0.00519c0.05231-0.05127,0.10602-0.10168,0.16058-0.15063L250.38617,208.79077
		c-14.16846,12.7085-27.25385,26.78845-38.89307,41.84845L456.7746,440.20923
		C456.88251,440.07007,456.99738,439.93707,457.11902,439.81055z"
          />
          <path
            className="st4"
            d="M456.29541,440.96307L180.66998,299.08002c-8.81689,17.12842-16.0946,35.16919-21.63153,53.62073
		l296.92273,89.0907C456.04761,441.5033,456.15997,441.2262,456.29541,440.96307z"
          />
          <path
            className="st4"
            d="M462.72015,438.76349l131.27448-280.8327c-17.46466-8.16388-35.78308-14.75232-54.44617-19.58234
		l-77.6684,300.11285C462.17145,438.53668,462.45251,438.63837,462.72015,438.76349z"
          />
          <path
            className="st4"
            d="M456.21606,445.27557c-0.00574-0.01221-0.01147-0.0246-0.01709-0.0368
		c-0.00317-0.00684-0.00623-0.01367-0.00934-0.02051c-0.00226-0.00494-0.00458-0.01013-0.00677-0.01508
		c-0.10986-0.2442-0.2002-0.4989-0.26898-0.76239l-299.92621,78.38538c4.87463,18.65149,11.50696,36.95386,19.71155,54.39844
		l280.51923-131.94385C456.21765,445.27905,456.21686,445.27728,456.21606,445.27557z"
          />
          <path
            className="st4"
            d="M465.50348,442.56262c0.00012,0.00128,0.00031,0.0025,0.00049,0.00378
		c0.00232,0.01782,0.00452,0.03571,0.00659,0.05365c0.00006,0.00012,0.00006,0.00037,0.00012,0.00055
		c0.01526,0.12952,0.02551,0.2608,0.03046,0.39325l309.78424-11.56848c-0.71387-19.1015-3.18768-38.24615-7.35461-56.89996
		l-302.54382,67.58307C465.4588,442.271,465.48438,442.41571,465.50348,442.56262z"
          />
          <path
            className="st4"
            d="M464.83362,440.65814c0.00134,0.00226,0.00269,0.00446,0.00403,0.00671
		c0.00604,0.00995,0.01208,0.01996,0.01807,0.02997c0.0047,0.00793,0.00952,0.01599,0.01422,0.02393
		c0.00336,0.0058,0.00677,0.01154,0.01013,0.01733c0.00635,0.01093,0.0127,0.02179,0.01898,0.03278
		c0.00323,0.00568,0.00647,0.01135,0.0097,0.01703c0.00586,0.01031,0.01172,0.02075,0.01752,0.03113
		c0.00385,0.00684,0.00763,0.01373,0.01147,0.02057c0.00354,0.00647,0.0069,0.01263,0.01044,0.0191
		c0.00659,0.01202,0.01306,0.02405,0.01953,0.03607c0.00018,0.00024,0.00037,0.00061,0.00049,0.00092
		c0.06702,0.12506,0.12885,0.25336,0.18488,0.38477l285.06-121.82227c-7.53925-17.64233-16.75079-34.68768-27.37769-50.66309
		L464.72845,440.49231C464.76459,440.54688,464.79962,440.60217,464.83362,440.65814z"
          />
          <path
            className="st4"
            d="M463.81866,439.46948c0.00513,0.00433,0.01019,0.00867,0.01526,0.013
		c0.00519,0.00446,0.01038,0.00891,0.0155,0.01337c0.01111,0.00958,0.02203,0.0191,0.03302,0.02881
		c0.00177,0.00153,0.00348,0.00305,0.00525,0.00464c0.09692,0.08551,0.19025,0.17468,0.28009,0.26752l222.79224-215.5545
		c-13.24158-13.68616-27.81311-26.23694-43.31-37.30383L463.49158,439.21387
		C463.60431,439.29449,463.71338,439.37982,463.81866,439.46948z"
          />
          <path
            className="st4"
            d="M455.75568,443.24884c-0.00006-0.00244-0.00006-0.00482-0.00006-0.0072
		c-0.00012-0.01385-0.00024-0.02777-0.00024-0.04163c0-0.01093,0.00006-0.02161,0.00012-0.03247
		c0.00006-0.00311,0.00006-0.00623,0.00012-0.00934c0.00006-0.00824,0.00012-0.01678,0.00024-0.02502
		c0-0.00116,0.00006-0.00226,0.00006-0.00342c0.00153-0.10822,0.00653-0.21558,0.01501-0.32196
		c0.00037-0.005,0.00073-0.01001,0.00116-0.01495c0.00006-0.00049,0.00006-0.00079,0.00012-0.00128
		c0.00342-0.04175,0.00726-0.08331,0.01166-0.12463l-308.19464-33.40308c-1.21692,11.2254-1.83386,22.64307-1.83386,33.93616
		c0,7.58307,0.2785,15.26306,0.82849,22.82538L455.76849,443.56C455.76099,443.45715,455.75671,443.35339,455.75568,443.24884z"
          />
          <path
            className="st4"
            d="M463.05084,447.46625l152.26764,270.02759c16.6369-9.38226,32.5061-20.34692,47.16687-32.58923
		L463.78546,446.95856C463.55725,447.14911,463.31146,447.31921,463.05084,447.46625z"
          />
          <path
            className="st4"
            d="M464.82556,445.755c-0.0033,0.00537-0.00659,0.01074-0.00995,0.01611
		c-0.00342,0.00555-0.0069,0.01117-0.01031,0.01672c-0.00854,0.01367-0.01727,0.02747-0.02594,0.04102
		c-0.00061,0.00098-0.00122,0.00195-0.00189,0.00293c-0.11017,0.17236-0.23096,0.33765-0.36127,0.49438L702.83002,644.4646
		c12.20923-14.69073,23.1369-30.58765,32.47998-47.24841L464.9223,445.59076
		C464.89111,445.64618,464.85876,445.70093,464.82556,445.755z"
          />
          <path
            className="st4"
            d="M465.43549,444.23254c-0.00079,0.00354-0.00153,0.00708-0.00232,0.01062
		c-0.00275,0.01288-0.00568,0.02594-0.00854,0.03876c-0.00171,0.00763-0.00348,0.0152-0.00519,0.02283
		c-0.00061,0.00244-0.00116,0.00488-0.00171,0.00732c-0.03644,0.15717-0.08038,0.31146-0.13159,0.46252l293.53387,99.68695
		c6.19385-18.23773,10.73151-37.15076,13.48615-56.21387l-306.81213-44.34149
		C465.47815,444.01611,465.45856,444.12512,465.43549,444.23254z"
          />
          <path
            className="st4"
            d="M461.61072,448.00031c-0.00171,0.00037-0.00323,0.00067-0.00494,0.00104
		c-0.00909,0.00177-0.01825,0.00354-0.02734,0.00531c-0.01172,0.00226-0.0235,0.00446-0.03522,0.00665
		c-0.00562,0.00098-0.01117,0.00201-0.01672,0.00299c-0.01459,0.00269-0.02954,0.00531-0.04413,0.00781
		c-0.00244,0.00043-0.00488,0.00079-0.00726,0.00122c-0.03558,0.00604-0.07159,0.01178-0.10736,0.01703l45.06842,306.70612
		c19.05847-2.79999,37.96307-7.38306,56.18768-13.62079L462.23541,447.83228
		C462.03296,447.90167,461.8244,447.95789,461.61072,448.00031z"
          />
          <path
            className="st4"
            d="M460.65002,128.30542c-19.02924,0-38.1662,1.74231-56.87927,5.17847l55.98999,304.9021
		c0.16199-0.02972,0.3266-0.05145,0.49371-0.06482c0.00067-0.00006,0.00128-0.00012,0.00189-0.00018
		c0.01776-0.0014,0.03589-0.00275,0.05371-0.00397c0.0011-0.00006,0.00226-0.00012,0.00336-0.00018
		c0.03534-0.00244,0.07062-0.00446,0.10614-0.0061c0.00195-0.00006,0.00391-0.00018,0.00586-0.00024
		c0.01648-0.00079,0.03308-0.0014,0.04962-0.00201c0.00293-0.00012,0.00586-0.00018,0.00879-0.00031
		c0.01617-0.00055,0.03223-0.00098,0.04846-0.0014c0.00317-0.00006,0.00635-0.00012,0.00952-0.00018
		c0.01624-0.00037,0.03265-0.00061,0.04895-0.00085c0.00244,0,0.00482,0,0.00726-0.00006
		c0.01727-0.00018,0.03467-0.00031,0.052-0.00031c0.01233,0,0.02448,0.00006,0.03687,0.00018
		c0.0061,0.00006,0.01227,0.00012,0.01843,0.00018c0.00507,0.00006,0.01019,0.00012,0.01526,0.00018
		c0.07092,0.00098,0.14148,0.00342,0.21179,0.00751c0.00085,0.00006,0.00177,0.00006,0.00256,0.00012
		c0.0083,0.00049,0.01654,0.00098,0.02484,0.00153c0.01282,0.00079,0.02588,0.00165,0.0387,0.00256l21.73999-309.23682
		C475.41925,128.56616,467.98773,128.30542,460.65002,128.30542z"
          />
          <path
            className="st4"
            d="M458.67712,447.68066c-0.25348-0.11182-0.49579-0.24451-0.72455-0.39587
		c-0.00006-0.00006-0.00018-0.00012-0.00024-0.00018L286.86462,705.79773c16.00153,10.58997,33.07001,19.76154,50.7323,27.25995
		L458.73926,447.7077C458.71844,447.69885,458.69775,447.68982,458.67712,447.68066z"
          />
          <polygon
            className="st4"
            points="459.58997,447.97949 459.58997,447.97949 459.59003,447.97925 	"
          />
          <path
            className="st4"
            d="M460.19543,448.07373c-0.00134-0.00012-0.00262-0.00024-0.00391-0.00037
		c-0.01678-0.00153-0.0332-0.00311-0.04993-0.00488c-0.00342-0.00031-0.0069-0.00073-0.01031-0.00104
		c-0.01477-0.00159-0.02942-0.00317-0.04413-0.00488c-0.0058-0.00061-0.01154-0.00134-0.01733-0.00201
		c-0.0094-0.0011-0.01904-0.00226-0.02844-0.00342c-0.01117-0.0014-0.0224-0.00287-0.03357-0.00433
		c-0.00653-0.00085-0.01263-0.00165-0.01917-0.0025c-0.01306-0.00177-0.02606-0.0036-0.03906-0.00543
		c-0.00635-0.00092-0.01227-0.00177-0.01862-0.00269c-0.00854-0.00128-0.01703-0.00256-0.02557-0.00385
		c-0.01123-0.00171-0.02222-0.00342-0.03345-0.00525c-0.00739-0.00116-0.01483-0.00238-0.02228-0.0036
		c-0.01245-0.00208-0.0249-0.00415-0.03729-0.00629c-0.00464-0.00079-0.00922-0.00159-0.01385-0.00244
		c-0.07007-0.01227-0.13959-0.02606-0.20856-0.04126l-66.86224,302.70361c18.65918,4.12152,37.80457,6.54999,56.90381,7.21844
		l10.84387-309.81c-0.00055-0.00006-0.0011-0.00006-0.00171-0.00006C460.38031,448.08813,460.28741,448.08221,460.19543,448.07373z"
          />
          <path
            className="st4"
            d="M457.24005,446.71118c-0.00507-0.00488-0.01013-0.00989-0.0152-0.01477
		c-0.0047-0.00464-0.0094-0.00922-0.01404-0.01385c-0.01025-0.01007-0.02026-0.02008-0.0304-0.03027
		c-0.00256-0.00256-0.00513-0.00519-0.00769-0.00775c-0.01129-0.01141-0.02246-0.02277-0.03363-0.0343
		c-0.00153-0.00159-0.00305-0.00311-0.00458-0.0047c-0.01172-0.01208-0.02332-0.02417-0.03485-0.03638
		c-0.00122-0.00128-0.00244-0.00256-0.00366-0.00385c-0.01166-0.01227-0.02325-0.02466-0.03479-0.03711
		c-0.00122-0.00128-0.00244-0.00262-0.0036-0.00391c-0.01154-0.01245-0.02283-0.02472-0.03424-0.03729
		c-0.0014-0.00159-0.00281-0.00311-0.00415-0.00464c-0.01111-0.01227-0.02191-0.02435-0.0329-0.03668
		c-0.00189-0.00214-0.00378-0.00427-0.00568-0.00641c-0.00958-0.01093-0.01898-0.02161-0.0285-0.03253
		c-0.00366-0.00427-0.00732-0.00854-0.01099-0.01276c-0.0072-0.00836-0.01422-0.0166-0.02136-0.02496
		c-0.00604-0.00714-0.01215-0.01428-0.01813-0.02142c-0.00525-0.00623-0.01038-0.01239-0.01556-0.01862
		c-0.00867-0.01038-0.01721-0.02081-0.02582-0.03125c-0.00256-0.00317-0.00513-0.00629-0.00775-0.00952
		c-0.06299-0.07727-0.12366-0.15643-0.18176-0.23743L204.82153,626.80463c11.10309,15.46997,23.68768,30.01154,37.4046,43.21997
		l215.02924-223.29846C457.25024,446.72119,457.24518,446.71619,457.24005,446.71118z"
          />
        </g>
        <pattern
          id={`Raggiera-8-centrale_00000089535806383151164080000000945628505423812738_${id}`}
          xlinkHref={`#Nuovo_pattern_${id}`}
          patternTransform="matrix(1 0 0 1 -0.27625 -0.29944)"
        ></pattern>
        <rect
          id={`Raggiera-8-centrale_${id}`}
          x="324.56259"
          y="424.70001"
          style={{
            fill: `url(#Raggiera-8-centrale_00000089535806383151164080000000945628505423812738_${id})`,
            mixBlendMode: mixBlendModeRaggiera,
          }}
          width="421.47491"
          height="370"
        />
        <g
          id={`Raggiera-8-superiore_${id}`}
          style={{ mixBlendMode: mixBlendModeRaggiera }}
        >
          <path
            className="st2"
            d="M542.5,384.9L665,227.8c-6.8-5.3-14-10.2-21.3-14.6L541.3,384.1C541.7,384.3,542.1,384.6,542.5,384.9z"
          />
          <path
            className="st2"
            d="M539.4,383.2l67.7-187.4c-8.2-3-16.6-5.4-25.1-7.3l-44,194.3C538.5,382.9,539,383,539.4,383.2z"
          />
          <path
            className="st2"
            d="M535.7,382.6c0.1,0,0.2,0,0.2,0l7.9-199.1c-2.8-0.1-5.7-0.2-8.5-0.2c-4,0-8.1,0.1-12.1,0.3l11.3,199
		C535,382.6,535.4,382.6,535.7,382.6z"
          />
          <path
            className="st2"
            d="M532.6,383l-44.1-194.5c-8.5,1.9-16.9,4.4-25.1,7.3l67.9,187.7C531.7,383.3,532.1,383.2,532.6,383z"
          />
          <path
            className="st2"
            d="M529.5,384.5L426.9,213.2c-7.4,4.4-14.5,9.3-21.3,14.6l122.9,157.6C528.8,385,529.2,384.8,529.5,384.5z"
          />
          <path
            className="st2"
            d="M525.2,390.8c0.1-0.4,0.2-0.8,0.4-1.2h-0.1l-0.2-0.1H546c0.1,0.4,0.2,0.8,0.4,1.1L738,335.7
		c-2.3-8.2-5.2-16.3-8.5-24.1l-182.6,77.3v-2h-1.1l164.1-111.2c-5.1-7.6-10.8-14.9-16.9-21.7L544,386.3c0.2,0.2,0.3,0.4,0.4,0.6H527
		c0,0,0-0.1,0.1-0.1L377.7,254c-6.1,6.8-11.7,14.1-16.9,21.7l164.1,111.2h-1.1v2l-182.5-77.2c-3.3,7.8-6.2,15.9-8.5,24.1l192.2,55
		L525.2,390.8L525.2,390.8z"
          />
          <rect x="324.6" y="417.7" className="st2" width="421.5" height="2" />
          <rect x="324.6" y="422.7" className="st2" width="421.5" height="2" />
          <rect x="324.6" y="412.7" className="st2" width="421.5" height="2" />
          <rect x="324.6" y="407.7" className="st2" width="421.5" height="2" />
          <rect x="324.6" y="402.7" className="st2" width="421.5" height="2" />
          <rect x="324.6" y="397.7" className="st2" width="421.5" height="2" />
          <path
            className="st2"
            d="M746,392.5c0-2.9-0.1-9.7-0.9-18.5v-0.4v-0.4c-0.1-1.4-0.3-2.9-0.5-4.3l-196.3,23.6h-26.2L326,368.9
		c-0.2,1.4-0.3,2.9-0.5,4.3v0.4v0.4c-0.8,8.8-0.9,15.6-0.9,18.5l0,0l0,0v2.2h421.5L746,392.5L746,392.5z"
          />
        </g>
        <g
          id={`Raggiera-8-inferiore_${id}`}
          className="Raggiera-8-inferiore"
          style={{ mixBlendMode: mixBlendModeRaggiera }}
        >
          <path
            className="st2"
            d="M528.12085,442.4826L405.60242,599.58752c6.78149,5.28864,13.95703,10.20795,21.32703,14.62067
		l102.3493-170.93176C528.87561,443.03528,528.48804,442.77222,528.12085,442.4826z"
          />
          <path
            className="st2"
            d="M531.1861,444.17786l-67.74872,187.36261c8.15796,2.95001,16.58862,5.41888,25.05774,7.33838
		L532.534,444.57153C532.0719,444.47101,531.62427,444.3349,531.1861,444.17786z"
          />
          <path
            className="st2"
            d="M534.85968,444.82611c-0.08289,0-0.16315-0.01056-0.24561-0.01239l-7.9411,199.13287
		c2.82178,0.11249,5.68665,0.16956,8.5144,0.16956c4.01831,0,8.08655-0.11523,12.09131-0.34229l-11.28802-199.00653
		C535.61884,444.80566,535.24164,444.82611,534.85968,444.82611z"
          />
          <path
            className="st2"
            d="M538.01758,444.35754l44.08752,194.5213c8.46899-1.91949,16.89966-4.38837,25.05762-7.33838
		l-67.85907-187.66785C538.88727,444.05896,538.45972,444.22388,538.01758,444.35754z"
          />
          <path
            className="st2"
            d="M541.07874,442.87128l102.59204,171.33691c7.36987-4.41272,14.54541-9.33203,21.3269-14.62067
		L542.12823,442.03247C541.79486,442.3316,541.44727,442.6145,541.07874,442.87128z"
          />
          <path
            className="st2"
            d="M545.43036,436.54272c-0.10419,0.42212-0.23224,0.83386-0.38403,1.23499h0.13397l184.18872,77.93909
		c3.30249-7.80408,6.1626-15.89771,8.50146-24.05566L545.6156,436.54272H545.43036z"
          />
          <path
            className="st2"
            d="M545.25665,437.91016h-20.53003c-0.14996-0.38458-0.28247-0.77765-0.38983-1.1817l-191.60718,54.93268
		c2.33887,8.15796,5.1991,16.25159,8.50159,24.05566l181.04108-76.60724v1.40784h2.58582L360.78784,551.69543
		c5.12231,7.55914,10.7959,14.85437,16.8634,21.68323l148.95239-132.34863c-0.13995-0.16248-0.25812-0.34259-0.38885-0.51263
		h17.30786c-0.01398,0.01831-0.02484,0.03894-0.03888,0.05713l149.46509,132.80414
		c6.06763-6.82886,11.74121-14.12408,16.86353-21.68323L545.25665,440.18842V437.91016z"
          />
          <path
            className="st2"
            d="M324.56262,432.67639v2.22363c0.02319,2.90222,0.07678,9.69861,0.91272,18.4527l0.04297,0.44843
		l0.0448,0.44843c0.14197,1.41907,0.30017,2.85608,0.47058,4.27112l196.2193-23.62067h26.09418l196.21936,23.62067
		c0.17041-1.41504,0.32861-2.85205,0.47046-4.27112l0.04492-0.44843l0.04297-0.44843
		c0.83594-8.75409,0.8894-15.55048,0.9126-18.4527v-2.22363H324.56262z"
          />
        </g>
        <path
          id="Maschera-0-inferiore-minima_00000111185551577147798050000013378788173143745962_"
          className="st6"
          d="M487.18842,423.8504
	v6.37479c0,36.11429-29.27643,65.39072-65.39072,65.39072h-0.00012c-36.11429,0-65.39072-29.27643-65.39072-65.39072v-6.37479
	H487.18842z"
        />
        <rect
          id="Maschera-0-centrale-minima"
          x="356.40686"
          y="418.8504"
          className="st6"
          width="130.78154"
          height="5"
        />
        <path
          id="Maschera-0-superiore-minima_00000085939419470493570340000003613624257466383786_"
          className="st6"
          d="M356.40686,418.8504
	v-22.83401c0-36.11429,29.27643-65.39072,65.39072-65.39072h0.00012c36.11429,0,65.39072,29.27643,65.39072,65.39072v22.83401
	H356.40686z"
        />
        <path
          id="Maschera-0-inferiore-massima_00000073697306812217020780000010615025469420714397_"
          className="st6"
          d="M638.18854,423.8504
	c0,119.50928-96.88141,216.67908-216.39069,216.67908h-0.00043c-119.50928,0-216.39069-97.1698-216.39069-216.67908H638.18854z"
        />
        <rect
          id="Maschera-0-centrale-massima_00000047740813035729247230000000678712764585161111_"
          x="205.40674"
          y="418.8504"
          className="st6"
          width="432.7818"
          height="380"
        />
        <path
          id="Maschera-0-superiore-massima_00000119828049477208034260000015577402818321665673_"
          className="st6"
          d="M205.40674,418.8504
	v-18.61472c0-119.50928,96.88141-216.39069,216.39069-216.39069h0.00043c119.50928,0,216.39069,96.88141,216.39069,216.39069
	v18.61472H205.40674z"
        />
        <g id="Maschera-punto-minima_00000134952678605246840630000018183163314790338720_">
          <g id="Maschera-punto-minima">
            <g id="Maschera-2-minima">
              <g>
                <path
                  className="st6"
                  d="M460.60001,388.29999c-30.29999,0-54.89999,24.60001-54.89999,54.89999
					s24.60001,54.89999,54.89999,54.89999S515.5,473.49997,515.5,443.19998S490.89999,388.29999,460.60001,388.29999
					L460.60001,388.29999z"
                />
              </g>
            </g>
          </g>
        </g>
        <g id="Maschera-punto-massima">
          <g id="Maschera-punto-minima_00000095315860056052214360000014071749895769759124_">
            <g id="Maschera-2-minima_00000179634724401089442700000008679143500624124582_">
              <g>
                <path
                  className="st6"
                  d="M460.60001,129.08081c-173.36627,0-314.11917,140.7529-314.11917,314.11917
					s140.7529,314.11917,314.11917,314.11917s314.11917-140.75293,314.11917-314.11917S633.96625,129.08081,460.60001,129.08081
					L460.60001,129.08081z"
                />
              </g>
            </g>
          </g>
        </g>
        <path
          id="Maschera-8-inferiore-massima_00000115512998203317100430000018165140412644371852_"
          className="st6"
          d="M324.56259,429.67639
	v3.5524c0,116.38693,94.35028,210.73721,210.73727,210.73721h0.00037c116.38696,0,210.73724-94.35028,210.73724-210.73721v-3.5524
	H324.56259z"
        />
        <rect
          id="Maschera-8-centrale-massima_00000014632400058821318700000016715465308501560231_"
          x="324.56262"
          y="424.70001"
          className="st6"
          width="421.47485"
          height="370"
        />
        <path
          id="Maschera-8-superiore-massima_00000079478024807415609690000007008558029451656326_"
          className="st6"
          d="M746.03748,424.70001
	v-32.2803c0-116.38696-94.35028-210.73724-210.73724-210.73724h-0.00037c-116.38699,0-210.73727,94.35028-210.73727,210.73724
	v32.2803H746.03748z"
        />
        <path
          id="Maschera-8-inferiore-minima_00000163051776143940696350000004469667323628956069_"
          className="st6"
          d="M475.00006,429.70001
	c1.42648,32.03564,27.88065,57.55154,60.29999,57.55154l0,0c32.41937,0,58.87354-25.5159,60.29999-57.55154H475.00006z"
        />
        <rect
          id="Maschera-8-centrale-minima_00000121979194282318522900000014179824266714551458_"
          x="475.00006"
          y="424.70001"
          className="st6"
          width="120.60001"
          height="5"
        />
        <path
          id="Maschera-8-superiore-minima_00000067215807685876091870000011077538458581324452_"
          className="st6"
          d="M595.60004,424.70001
	v-27.40002c0-33.29999-27-60.29999-60.29999-60.29999l0,0c-33.29999,0-60.29999,27-60.29999,60.29999v27.40002H595.60004z"
        />

        <Lettere />

        <g className="etichetta">
          {etichettaPrimaRiga && (
            <Etichetta
              textColor={etichettaColor}
              etichettaAsMask={etichettaAsMask}
              bgColor={etichettaBackgroundColor}
              x={380}
              y={409}
              svgWidth={width}
              text={etichettaPrimaRiga}
              strokeColor={etichettaStrokeColor}
              fontWeight={etichettaFontWeight}
            />
          )}
          {etichettaSecondaRiga && (
            <Etichetta
              textColor={etichettaColor}
              etichettaAsMask={etichettaAsMask}
              bgColor={etichettaBackgroundColor}
              x={380}
              y={456}
              svgWidth={width}
              text={etichettaSecondaRiga}
              strokeColor={etichettaStrokeColor}
              fontWeight={etichettaFontWeight}
              secondLine
            />
          )}
        </g>

        {/* <g id="linea-base-018_00000154399046954910048310000000508018513892564908_">
	<g id="linea-base-018_00000103974634521602812640000005283546025182266528_">
		<g id="linea-base-018">
			<line className="st7" x1="0" y1="377.29999" x2="800" y2="377.29999"/>
		</g>
	</g>
</g>
<g id="linea-base-milano_00000017516168565991192790000006977210964084462783_">
	<g id="linea-base-milano">
		<g>
			<line className="st7" x1="0" y1="354.70001" x2="800" y2="354.70001"/>
		</g>
	</g>
</g> */}
      </svg>
    );
  }
);

export default React.memo(BaseSVG);
