import styles from "./BoxTextTool.module.css";

export default function BoxTextTool({ type }) {
  return (
    <div className="mt-4 pt-4 pt-md-0 mt-md-0">
      <div className={styles.Title}>Tool generativo</div>
      <div className={`mt-4 ${styles.Subtitle}`}>
      Interagisci con il tool e genera una nuova versione del marchio Milano 0.18.
      </div>
      <div className={`mt-3 ${styles.Text}`}>
        Registra la tua voce,{" "}
        {type === "pro" && "gestisci i parametri manualmente,"} oppure utilizza
        la funzione di generazione randomica per creare una declinazione
        personalizzata del marchio.
      </div>
      <div className={`mt-3 ${styles.Text}`}>
        Successivamente puoi scaricare il pacchetto con le diverse versioni del
        marchio realizzato.
      </div>
    </div>
  );
}
