// import * as Sentry from '@sentry/react'
import EazyAuth, { useAuthActions } from 'use-eazy-auth'
import { ConfigureRj } from 'react-rocketjump'
import { api } from './api'

const loginCall = (params) =>
  api
    .mapResponse((r) => ({
      accessToken: r.response.access,
      refreshToken: r.response.refresh,
    }))
    .post('/token', params)

const meCall = (token) => api.auth(token).get('/me')

const refreshTokenCall = (refreshToken) =>
  api
    .mapResponse((r) => ({
      accessToken: r.response.access,
      refreshToken: refreshToken,
    }))
    .post('/token/refresh', {
      refresh: refreshToken,
    })

function InnerAuth({ children }) {
  const { callAuthApiObservable } = useAuthActions()
  return (
    <ConfigureRj effectCaller={callAuthApiObservable}>{children}</ConfigureRj>
  )
}

function Auth({ children }) {
  return (
    <EazyAuth
      // onAuthenticate={(user) => {
      //   if (process.env.REACT_APP_SENTRY_RELEASE) {
      //     // Sentry.setUser({
      //     //   id: user.id,
      //     //   email: user.email,
      //     // })
      //   }
      // }}
      // onLogout={() => {
      //   if (process.env.REACT_APP_SENTRY_RELEASE) {
      //     // Sentry.setUser(null)
      //   }
      // }}
      loginCall={loginCall}
      meCall={meCall}
      refreshTokenCall={refreshTokenCall}
    >
      <InnerAuth>{children}</InnerAuth>
    </EazyAuth>
  )
}

export default Auth
