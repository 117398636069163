import {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from "react";
import {
  getBaseScale,
  getBaseScaleRays,
  stretchBase,
  DEFAULT_RAY_PARAMS,
} from "../../utils/params";
import SVGComponent from "./SVGComponent";
import { interpolatePath } from "d3-interpolate-path";
import { scaleLinear } from "d3-scale";
import { shallowEqualArrays } from "shallow-equal";

function useTransformLetterReact(
  domNode,
  selector,
  watchProps,
  expressionGenerator
) {
  const watchedPropsArray = Array.isArray(watchProps)
    ? watchProps
    : [watchProps];

  useEffect(() => {
    if (!domNode.current) {
      return;
    }

    const targetNodes = domNode.current.querySelectorAll(selector);

    window.requestAnimationFrame(() => {
      targetNodes.forEach((targetNode) => {
        const value = expressionGenerator(...watchedPropsArray);
        targetNode.setAttribute("transform", value);
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [domNode, expressionGenerator, selector, ...watchedPropsArray]);
}

function imperativeTransformLetter() {
  const q = [];
  function hook(domNode, selector, watchProps, expressionGenerator) {
    const watchedPropsArray = Array.isArray(watchProps)
      ? watchProps
      : [watchProps];
    q.push([
      () => {
        const targetNodes = domNode.current.querySelectorAll(selector);

        targetNodes.forEach((targetNode) => {
          const value = expressionGenerator(...watchedPropsArray);
          targetNode.setAttribute("transform", value);
        });
      },
      [domNode, expressionGenerator, selector, ...watchedPropsArray],
    ]);
  }
  hook.get = () => q;
  return hook;
}
function useSetAttributeReact(
  domNode,
  selector,
  sourceSelector,
  attributesGenerator
) {
  useEffect(() => {
    if (!domNode.current) {
      return;
    }

    const targetNodes = domNode.current.querySelectorAll(selector);
    const sourceNode = sourceSelector
      ? domNode.current.querySelector(sourceSelector)
      : null;
    const targetAttrs = attributesGenerator(sourceNode);

    window.requestAnimationFrame(() => {
      targetNodes.forEach((targetNode) => {
        Object.keys(targetAttrs).forEach((attr) => {
          targetNode.setAttribute(attr, targetAttrs[attr]);
        });
      });
    });
  }, [attributesGenerator, domNode, selector, sourceSelector]);
}

function imperativeAttribute() {
  const q = [];
  function hook(domNode, selector, sourceSelector, attributesGenerator) {
    q.push([
      () => {
        const targetNodes = domNode.current.querySelectorAll(selector);
        const sourceNode = sourceSelector
          ? domNode.current.querySelector(sourceSelector)
          : null;
        const targetAttrs = attributesGenerator(sourceNode);

        targetNodes.forEach((targetNode) => {
          Object.keys(targetAttrs).forEach((attr) => {
            targetNode.setAttribute(attr, targetAttrs[attr]);
          });
        });
      },
      [domNode, selector, sourceSelector, attributesGenerator],
    ]);
  }
  hook.get = () => q;
  return hook;
}

function useSetStyle(domNode, selector, value) {
  useEffect(() => {
    if (!domNode.current) {
      return;
    }

    const targetNodes = domNode.current.querySelectorAll(selector);

    window.requestAnimationFrame(() => {
      targetNodes.forEach((targetNode) => {
        targetNode.setAttribute("style", value);
      });
    });
  }, [domNode, selector, value]);
}

function useSetAttributeDeps(
  domNode,
  selector,
  watchProp,
  attributesGenerator
) {
  useEffect(() => {
    if (!domNode.current) {
      return;
    }
    const targetNodes = domNode.current.querySelectorAll(selector);
    const targetAttrs = attributesGenerator(watchProp);

    window.requestAnimationFrame(() => {
      targetNodes.forEach((targetNode) => {
        Object.keys(targetAttrs).forEach((attr) => {
          targetNode.setAttribute(attr, targetAttrs[attr]);
        });
      });
    });
  }, [attributesGenerator, domNode, selector, watchProp]);
}

function useGetInterpolator(domNode, selectorLow, selectorHi) {
  const interpolator = useRef();

  useEffect(() => {
    if (!domNode.current) {
      return;
    }
    const targetLow = domNode.current.querySelector(selectorLow);
    const targetHi = domNode.current.querySelector(selectorHi);
    const targetLowD = targetLow.getAttribute("d");
    const targetHiD = targetHi.getAttribute("d");
    interpolator.current = interpolatePath(targetLowD, targetHiD);
  });

  return interpolator;
}

const extractAttrs = (domNode) => {
  const circle = domNode.querySelector("circle");
  const cx = circle.getAttribute("cx");
  const cy = circle.getAttribute("cy");

  return {
    "transform-origin": `${cx} ${cy}`,
  };
};

function useAnimateLogo(
  mainRef,
  transformScales,
  imperative,
  // Params
  {
    letteraM = 1,
    letteraI = 1,
    letteraL = 1,
    letteraA = 1,
    letteraN = 1,
    letteraO = 1,
    lettera0 = 1,
    lettera1 = 1,
    lettera8 = 1,
    maschera0 = 1,
    mascheraPunto = 1,
    maschera8 = 1,
  },
  // Helpers
  {
    extractColorsMask0,
    extractColorsMaskPunto,
    extractColorsMask8,
    extractColorsLettere,
    extractColorsBackground,
  }
) {
  const useSetAttribute = imperative
    ? imperativeAttribute()
    : useSetAttributeReact;
  const useTransformLetter = imperative
    ? imperativeTransformLetter()
    : useTransformLetterReact;

  useSetAttribute(
    mainRef,
    "[id^=Lettera-M-stretch]",
    "#Lettera-M-riferimento",
    extractAttrs
  );
  useTransformLetter(
    mainRef,
    "[id^=Lettera-M-stretch]",
    letteraM,
    transformScales.mutateScale
    // paramsHistory,
    // ["letteraM"]
  );
  useTransformLetter(
    mainRef,
    "[id^=Lettera-M-move]",
    letteraM,
    transformScales.mutateTranslate
  );

  useSetAttribute(
    mainRef,
    "[id^=Lettera-i-stretch]",
    "#Lettera-i-riferimento",
    extractAttrs
  );
  useTransformLetter(
    mainRef,
    "[id^=Lettera-i-stretch]",
    letteraI,
    transformScales.mutateScaleLetteraI
  );
  useTransformLetter(
    mainRef,
    "[id^=Lettera-i-move]",
    letteraI,
    transformScales.mutateTranslate
  );

  useSetAttribute(
    mainRef,
    "[id^=Lettera-L-stretch]",
    "#Lettera-L-riferimento",
    extractAttrs
  );
  useTransformLetter(
    mainRef,
    "[id^=Lettera-L-stretch]",
    letteraL,
    transformScales.mutateScale
  );
  useTransformLetter(
    mainRef,
    "[id^=Lettera-L-move]",
    letteraL,
    transformScales.mutateTranslate
  );

  useSetAttribute(
    mainRef,
    "[id^=Lettera-A-stretch]",
    "#Lettera-A-riferimento",
    extractAttrs
  );
  useTransformLetter(
    mainRef,
    "[id^=Lettera-A-stretch]",
    letteraA,
    transformScales.mutateScale
  );
  useTransformLetter(
    mainRef,
    "[id^=Lettera-A-move]",
    letteraA,
    transformScales.mutateTranslate
  );

  useSetAttribute(
    mainRef,
    "[id^=Lettera-N-stretch]",
    "#Lettera-N-riferimento",
    extractAttrs
  );
  useTransformLetter(
    mainRef,
    "[id^=Lettera-N-stretch]",
    letteraN,
    transformScales.mutateScale
  );
  useTransformLetter(
    mainRef,
    "[id^=Lettera-N-move]",
    letteraN,
    transformScales.mutateTranslate
  );

  useSetAttribute(
    mainRef,
    "[id^=Lettera-O-stretch]",
    "#Lettera-O-riferimento",
    extractAttrs
  );
  useTransformLetter(
    mainRef,
    "[id^=Lettera-O-stretch]",
    letteraO,
    transformScales.mutateScale
  );
  useTransformLetter(
    mainRef,
    "[id^=Lettera-O-move]",
    letteraO,
    transformScales.mutateTranslate
  );

  useSetAttribute(
    mainRef,
    "[id^=Numero-0-stretch]",
    "#Numero-0-riferimento",
    extractAttrs
  );
  useTransformLetter(
    mainRef,
    "[id^=Numero-0-stretch]",
    lettera0,
    transformScales.mutateScale
  );
  useTransformLetter(
    mainRef,
    "[id^=Numero-0-move]",
    lettera0,
    transformScales.mutateTranslateAdd
  );

  useSetAttribute(
    mainRef,
    "[id^=Numero-1-stretch]",
    "#Numero-1-riferimento",
    extractAttrs
  );
  useTransformLetter(
    mainRef,
    "[id^=Numero-1-stretch]",
    lettera1,
    transformScales.mutateScale
  );
  useTransformLetter(
    mainRef,
    "[id^=Numero-punto-move]",
    lettera1,
    transformScales.mutateTranslateAdd
  );

  useSetAttribute(
    mainRef,
    "[id^=Numero-8-stretch]",
    "#Numero-8-riferimento",
    extractAttrs
  );
  useTransformLetter(
    mainRef,
    "[id^=Numero-8-stretch]",
    lettera8,
    transformScales.mutateScale
  );
  useTransformLetter(
    mainRef,
    "[id^=Numero-8-move]",
    lettera8,
    transformScales.mutateTranslateAdd
  );

  //raggiera 0
  //superiore
  useSetAttribute(
    mainRef,
    ".Maschera-0-superiore-clip",
    "#Maschera-0-superiore-riferimento",
    extractAttrs
  );
  useTransformLetter(
    mainRef,
    ".Maschera-0-superiore-clip",
    maschera0,
    transformScales.mutateScaleMask
  );

  //centrale
  useSetAttribute(
    mainRef,
    ".Maschera-0-centrale-clip",
    "#Maschera-0-centrale-riferimento",
    extractAttrs
  );

  useTransformLetter(
    mainRef,
    ".Maschera-0-centrale-clip",
    [maschera0, lettera0],
    transformScales.mutateScaleMaskZero
  );

  //inferiore
  useSetAttribute(
    mainRef,
    ".Maschera-0-inferiore-clip",
    "#Maschera-0-inferiore-riferimento",
    extractAttrs
  );
  useTransformLetter(
    mainRef,
    ".Raggiera-0-inferiore, .Maschera-0-inferiore-clip-outline",
    lettera0,
    transformScales.mutateTranslateAdd
  );
  useTransformLetter(
    mainRef,
    ".Maschera-0-inferiore-clip",
    maschera0,
    transformScales.mutateScaleMask
  );

  //raggiera punto
  useSetAttribute(
    mainRef,
    ".Maschera-punto-clip",
    "#Maschera-punto-riferimento",
    extractAttrs
  );
  useTransformLetter(
    mainRef,
    ".Maschera-punto-clip",
    mascheraPunto,
    transformScales.mutateScaleMask
  );
  useTransformLetter(
    mainRef,
    ".Raggiera-punto, .Maschera-punto-clip-outline",
    lettera1,
    transformScales.mutateTranslateAdd
  );

  //raggiera 8
  //superiore
  useSetAttribute(
    mainRef,
    ".Maschera-8-superiore-clip",
    "#Maschera-8-superiore-riferimento",
    extractAttrs
  );
  useTransformLetter(
    mainRef,
    ".Maschera-8-superiore-clip",
    maschera8,
    transformScales.mutateScaleMask
  );

  //centrale
  useSetAttribute(
    mainRef,
    ".Maschera-8-centrale-clip",
    "#Maschera-8-centrale-riferimento",
    extractAttrs
  );

  useTransformLetter(
    mainRef,
    ".Maschera-8-centrale-clip",
    [maschera8, lettera8],
    transformScales.mutateScaleMaskZero
  );

  //inferiore
  useSetAttribute(
    mainRef,
    ".Maschera-8-inferiore-clip",
    "#Maschera-8-inferiore-riferimento",
    extractAttrs
  );
  useTransformLetter(
    mainRef,
    ".Raggiera-8-inferiore, .Maschera-8-inferiore-clip-outline",
    lettera8,
    transformScales.mutateTranslateAdd
  );
  useTransformLetter(
    mainRef,
    ".Maschera-8-inferiore-clip",
    maschera8,
    transformScales.mutateScaleMask
  );

  //colori
  useSetAttribute(mainRef, ".st1", null, extractColorsMask0);
  useSetAttribute(mainRef, ".st4", null, extractColorsMaskPunto);
  useSetAttribute(mainRef, ".st2", null, extractColorsMask8);

  useSetAttribute(
    mainRef,
    ".lettere:not(.lettere-outline) path, .lettere:not(.lettere-outline) rect, .lettere:not(.lettere-outline) polygon",
    null,
    extractColorsLettere
  );
  useSetAttribute(mainRef, ".background", null, extractColorsBackground);

  if (imperative) {
    return useSetAttribute.get().concat(useTransformLetter.get());
  }
}

function Logo(
  {
    maxValue = 20,
    width = 800,
    height = 800,
    rayParams = DEFAULT_RAY_PARAMS,

    maschera0Color = "#fff100",
    mascheraPuntoColor = "#50ffff",
    maschera8Color = "#f48fff",
    lettereColor = "#000000",
    backgroundColor = "#ffffff",
    etichettaPrimaRiga,
    etichettaSecondaRiga,
    etichettaColor = "#000000",
    etichettaBackgroundColor = "#000000",
    etichettaStrokeColor = "none",
    etichettaFontWeight = "700",
    mixBlendModeRaggiera = "multiply",
    etichettaAsMask=false,
    paramsHistory,

    containerBackground,
    outline = false,
    imperative = false,
    ...animationParams
  },
  ref
) {
  const mainRef = useRef();

  const transformScales = useMemo(() => {
    return {
      mutateScale: (watchProp) =>
        `scale(1, ${getBaseScale(maxValue)(watchProp)})`,
      mutateScaleLetteraI: (watchProp) =>
        `scale(1, ${getBaseScale(maxValue)(watchProp) * 0.5})`,

      mutateScaleRay: (watchProp) =>
        `scale(1, ${getBaseScaleRays(maxValue, rayParams)(watchProp)})`,
      mutateScaleMask: (watchProp) =>
        `scale(${getBaseScaleRays(maxValue, rayParams)(watchProp)})`,

      mutateScaleMaskZero: (maschera0, lettera0) =>
        `scale(${getBaseScaleRays(
          maxValue,
          rayParams
        )(maschera0)}, ${getBaseScale(maxValue)(lettera0)})`,
      mutateTranslate: (watchProp) =>
        `translate(0, -${
          getBaseScale(maxValue)(watchProp) * stretchBase - stretchBase
        })`,
      mutateTranslateAdd: (watchProp) =>
        `translate(0, ${
          getBaseScale(maxValue)(watchProp) * stretchBase - stretchBase
        })`,

      mutateTranslateAddHalf: (watchProp) =>
        `translate(0, ${
          getBaseScale(maxValue)(watchProp) * 0.5 * stretchBase - stretchBase
        })`,
    };
  }, [maxValue, rayParams]);

  const extractColorsMask0 = useCallback(() => {
    return {
      fill: maschera0Color,
    };
  }, [maschera0Color]);

  const extractColorsMaskPunto = useCallback(() => {
    return {
      fill: mascheraPuntoColor,
    };
  }, [mascheraPuntoColor]);

  const extractColorsMask8 = useCallback(() => {
    return {
      fill: maschera8Color,
    };
  }, [maschera8Color]);

  const extractColorsLettere = useCallback(() => {
    return {
      fill: lettereColor,
    };
  }, [lettereColor]);

  const extractColorsBackground = useCallback(() => {
    return {
      fill: backgroundColor,
    };
  }, [backgroundColor]);

  const helpers = {
    extractColorsMask0,
    extractColorsMaskPunto,
    extractColorsMask8,
    extractColorsLettere,
    extractColorsBackground,
  };
  useAnimateLogo(
    mainRef,
    transformScales,
    imperative,
    animationParams,
    helpers
  );

  useImperativeHandle(ref, () => ({
    setParams: (params, prevParams) => {
      if (imperative) {
        const prevQ = prevParams
          ? // eslint-disable-next-line react-hooks/rules-of-hooks
            useAnimateLogo(
              mainRef,
              transformScales,
              imperative,
              prevParams,
              helpers
            )
          : null;
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const nextQ = useAnimateLogo(
          mainRef,
          transformScales,
          imperative,
          params,
          helpers
        );
        for (let i = 0; i < nextQ.length; i++) {
          const nextLayer = nextQ[i];
          if (!prevQ) {
            nextLayer[0]();
            continue;
          }
          const prevLayer = prevQ[i];
          if (!shallowEqualArrays(prevLayer[1], nextLayer[1])) {
            nextLayer[0]();
          }
        }
      }
    },
  }));

  const outlineNeeds8Rect = useMemo(() => {
    if(!outline) return false;
    return animationParams.lettera8 > 8;
  }, [outline, animationParams.lettera8]);

  const outlineNeeds0Rect = useMemo(() => {
    if(!outline) return false;
    return animationParams.lettera0 > 5;
  }, [outline, animationParams.lettera0]);

  return (
    <div className="svg-container" style={{ background: containerBackground }}>
      <SVGComponent
        width={width}
        height={height}
        ref={mainRef}
        etichettaPrimaRiga={etichettaPrimaRiga}
        etichettaSecondaRiga={etichettaSecondaRiga}
        etichettaColor={etichettaColor}
        etichettaBackgroundColor={etichettaBackgroundColor}
        etichettaStrokeColor={etichettaStrokeColor}
        etichettaFontWeight={etichettaFontWeight}
        etichettaAsMask={etichettaAsMask}
        mixBlendModeRaggiera={mixBlendModeRaggiera}
        outline={outline}
        outlineNeeds8Rect={outlineNeeds8Rect}
        outlineNeeds0Rect={outlineNeeds0Rect}
      />
    </div>
  );
}

export default memo(forwardRef(Logo));
