export const COLOR_THEMES = {
  "0-18": {
    maschera0Color: "#FFF200",
    mascheraPuntoColor: "#50FFFF",
    maschera8Color: "#F792FF",
    maschera0StrongColor: "rgba(255,207,3,0.7)",
    mascheraPuntoStrongColor: "rgba(11,201,205,0.7)",
    maschera8StrongColor: "rgba(240,97,255,0.7)",
    etichettaBackgroundColor: "#ffffff",
    etichettaColor: "#000000",
    etichettaStrokeColor: "#000000",
    etichettaFontWeight: "300",
  },

  "0-6": {
    maschera0Color: "#A8F980",
    mascheraPuntoColor: "#7CFCC0",
    maschera8Color: "#50FFFF",
    maschera0StrongColor: "rgba(43,204,138,0.7)",
    mascheraPuntoStrongColor: "rgba(27,203,172,0.7)",
    maschera8StrongColor: "rgba(11,201,205,0.7)",
    etichettaBackgroundColor: "#50FFFF",
    etichettaColor: "#000000",
    etichettaStrokeColor: "none",
    etichettaFontWeight: "700",
  },

  "6-14": {
    maschera0Color: "#FFC010",
    mascheraPuntoColor: "#FCD208",
    maschera8Color: "#FFF200",
    maschera0StrongColor: "rgba(255,128,16,0.7)",
    mascheraPuntoStrongColor: "rgba(255,167,10,0.7)",
    maschera8StrongColor: "rgba(255,207,3,0.7)",
    etichettaBackgroundColor: "#FFF200",
    etichettaColor: "#000000",
    etichettaStrokeColor: "none",
    etichettaFontWeight: "700",
  },

  "14-18": {
    maschera0Color: "#C1A1FF",
    mascheraPuntoColor: "#DC9AFF",
    maschera8Color: "#F792FF",
    maschera0StrongColor: "rgba(131,103,199,0.7)",
    mascheraPuntoStrongColor: "rgba(186,100,227,0.7)",
    maschera8StrongColor: "rgba(240,97,255,0.7)",
    etichettaBackgroundColor: "#F792FF",
    etichettaColor: "#000000",
    etichettaStrokeColor: "none",
    etichettaFontWeight: "700",
  },
};
