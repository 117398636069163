export default function SendEmail({ active }) {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_814_1119)">
        <path
          d="M0.503174 0.53125L2.83349 8.99279L0.503174 17.4683L18.4969 8.99279L0.503174 0.53125Z"
          stroke={active ? "black" : "#A0A0A0"}
          strokeWidth="0.75"
          strokeLinejoin="round"
        />
        <path
          d="M2.8335 8.99316H18.4969"
          stroke={active ? "black" : "#A0A0A0"}
          strokeWidth="0.75"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_814_1119">
          <rect width="19" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
