import { rj, useRunRj, useRj } from "react-rocketjump";
import { api } from "../api";
import rjList, {
  nextPreviousPaginationAdapter,
} from "react-rocketjump/plugins/list";
import { PAGE_SIZE } from "../utils/server";
import { useCallback, useContext, useRef } from "react";
import { FeaturesContext, ParametersContext, PlayerContext } from "../contexts";
import { useAuthCallObservable } from "use-eazy-auth";
import { getSuppertedVideoExtension } from "../utils/video";
import { makeLogoImage } from "../utils/downloads";
import { useParams } from "react-router-dom";

const liststate = rj(
  rjList({
    pageSize: PAGE_SIZE,
    pagination: nextPreviousPaginationAdapter,
  }),
  {
    effectCaller: "configured",
    effect: (token) => (filters) => {
      return api.auth(token).get("/logos", filters);
    },
    computed: {
      list: "getList",
      pagination: "getPagination",
      numPages: "getNumPages",
      pending: "isPending",
      error: "getError",
    },
    mutations: {
      removeLogo: {
        effect: (t) => (id) =>
          api
            .auth(t)
            .mapResponse(() => ({
              id,
            }))
            .delete(`/logos/${id}`),
        updater: "deleteItem",
      },
      setStar: {
        effect: (t) => (id, starred) =>
          api.auth(t).patch(`/logos/${id}`, {
            starred,
          }),
        optimisticResult: (id, starred) => ({ id, starred }),
        optimisticUpdater: (state, { id, starred }) => ({
          ...state,
          data: {
            ...state.data,
            list: state.data.list.map((l) =>
              l.id === id ? { ...l, starred } : l
            ),
          },
        }),
      },
    },
  }
);

export function useLogosList(params) {
  return useRunRj(liststate, [params], false);
}

const CreateLogoState = rj({
  effectCaller: "configured",
  effect: (t) => (data) => {
    return api.auth(t).post("/logos/", data);
  },
});

const ArchiveLogoInstallationState = rj({
  effect: (token, data) => {
    return api.post(`/installations/${token}/archive`, data);
  },
});

const SendLogoInstallationState = rj({
  effect: (token, data) => {
    return api.post(`/installations/${token}/send`, data);
  },
});

export function useMakeLogoFormData() {
  const {
    params,
    // paramsHistory,
    years,
    maxDelta,
    maxValue,
    colorTheme,
    etichetta,
  } = useContext(ParametersContext);
  const { audioBlob, totalTime } = useContext(PlayerContext);
  return async function makeLogoFormData() {
    const fd = new FormData();
    fd.append("params", JSON.stringify(params));
    // fd.append("params_history", JSON.stringify(paramsHistory.current));
    fd.append("params_history", JSON.stringify([]));
    if (years !== null) {
      fd.append("years", years);
    }
    fd.append("max_delta", maxDelta);
    fd.append("max_value", maxValue);
    fd.append("theme", colorTheme);
    if (etichetta !== null) {
      fd.append("etichetta", etichetta);
    }
    if (audioBlob && totalTime > 500) {
      fd.append("video", audioBlob, `video.${getSuppertedVideoExtension()}`);
      fd.append("duration", totalTime);
    }
    const svgNode = document.querySelector(".svg-container > svg");
    const image = await makeLogoImage(svgNode);
    fd.append("image", image, "logo.png");
    return fd;
  };
}

export function useMaybeSaveLogoFromInstallation() {
  const makeLogoFormData = useMakeLogoFormData()
  const { run } = useRj(ArchiveLogoInstallationState)[1];
  const { token } = useParams()
  const { archive } = useContext(FeaturesContext)
  return async function maybeSaveLogoFromInstallation() {
    if (archive && token) {
      const fd = await makeLogoFormData()
      return run.asPromise(token, fd)
    }
  }
}

export function useSendLogoFromInstallation() {
  const makeLogoFormData = useMakeLogoFormData()
  const { run } = useRj(SendLogoInstallationState)[1];
  const { token } = useParams()
  return async function sendLogoFromInstallation(email) {
    if (token && email) {
      const fd = await makeLogoFormData()
      fd.append('send_email_to', email)
      return run.asPromise(token, fd)
    }
  }
}

export function useLogoCreate() {
  return useRj(CreateLogoState);
}

const detailstate = rj({
  effectCaller: "configured",
  effect: (t) => (id) => {
    return api.auth(t).get(`/logos/${id}`);
  },
  mutations: {
    update: rj.mutation.single({
      effect: (token) => (id, data) => {
        return api.auth(token).patch(`/logos/${id}`, data);
      },
      updater: "updateData",
    }),
    destroy: rj.mutation.single({
      effect: (token) => (id) => {
        return api.auth(token).delete(`/logos/${id}`);
      },
      updater: (state, result) => ({ ...state, data: null }),
    }),
  },
});

export function useLogo(id) {
  return useRunRj(detailstate, [id], true);
}

export function useLoadRandomLogo() {
  const loadingRef = useRef(false);
  const { setParams, setParamsHistory, setMaxDelta, setMaxValue } =
    useContext(ParametersContext);

  const getRandomLogo = useAuthCallObservable(
    useCallback((t) => () => api.auth(t).get("/logos/random"), [])
  );

  return useCallback(async () => {
    if (loadingRef.current) {
      return;
    }
    loadingRef.current = false;
    try {
      const logo = await getRandomLogo().toPromise();
      setParams(logo.params);
      setParamsHistory(logo.params_history);
      setMaxDelta(logo.max_delta);
      setMaxValue(logo.max_value);
    } catch (e) {
      console.log("Error while loading random logo", e);
    }
    loadingRef.current = false;
  }, [getRandomLogo, setMaxDelta, setMaxValue, setParams, setParamsHistory]);
}
