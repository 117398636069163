import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import classNames from "classnames";
import BoxPersonalizzaMobile from "../../components/BoxPersonalizationMobile/BoxPersonalizationMobile";
import BoxTextAbout from "../../components/BoxTextAbout";
import RecordBox from "../../components/RecordBox/RecordBox";
import PrintingModal from "../../components/PrintingModal";
import BoxTextTool from "../../components/BoxTextTool";
import personalizationIcon from "../../assets/personalization-icon.svg";
import randomIcon from "../../assets/random.svg";
import arrowRight from "../../assets/right-arrow.svg";
import useDimensions from "react-use-dimensions";
import BoxSatelliteBrandMobile from "../../components/BoxSatelliteBrandMobile";
import Logo from "../../components/Logo";
import {
  FeaturesContext,
  ParametersContext,
  PlayerContext,
} from "../../contexts";
import { COLOR_THEMES } from "../../utils/colors";
import { paramsFromSound } from "../../utils/audioAnalysis";
import { CANONICAL_PARAMS } from "../../utils/params";
import BoxSave from "../../components/BoxSave";
import BoxExport from "../../components/BoxExport";
import { useLoadRandomLogo } from "../../hooks/logo";
import UploadModal from "../../components/UploadModal/UploadModal";
import findIndex from "lodash/findIndex";
import CheckBrowser from "../../components/CheckBrowser";
import SendModal from "../../components/SendModal/SendModal";
import { splitEtichetta, splitEtichettaMobile } from "../../utils/text";

const LOGO_ZOOM_PX = 200;
const LOGO_ZOOM_PX_SMALL = 150;

export default function AppMobile({ mode = "basic", imperativeLogo }) {
  const [about, setAbout] = useState(true);
  const [home, setHome] = useState(false);
  const [boxPersonalization, setBoxPersonalization] = useState(false);
  const [boxSatelliteBrand, setBoxSatelliteBrand] = useState(false);
  const [boxExport, setBoxExport] = useState(false);
  const [boxUpload, setBoxUpload] = useState(false);
  const [boxSave, setBoxSave] = useState(false);
  const [boxSend, setBoxSend] = useState(false);
  const [isPrinting, setIsPrinting] = useState(false);

  const {
    params,
    setParams,
    maxValue,
    colorTheme,
    setColorTheme,
    paramsStart,
    addParamsToHistory,
    paramsRef,
    etichetta,
    setEtichetta,
    years,
    setYears,
    maxDelta,
    paramsHistory,
    maxValueThreshold,
  } = useContext(ParametersContext);
  const features = useContext(FeaturesContext);

  const { audioBlob, isRecording, isPlaying } = useContext(PlayerContext);

  const [ref, dimensions] = useDimensions();
  let size = 0,
    left = 0,
    top = 0;
  if (dimensions.width > 0 && dimensions.height > 0) {
    const isSmallDevice = dimensions.height <= 480;
    const baseSize = Math.min(dimensions.width, dimensions.height);
    size = baseSize + (isSmallDevice ? LOGO_ZOOM_PX_SMALL : LOGO_ZOOM_PX);
    left = (dimensions.width - size) / 2.15;
    top = (dimensions.height - size) / (isSmallDevice ? 2.2 : 2);
  }

  // Quando il box satellite è aperto e e age range è 0-18
  let deEtichetta = etichetta;
  if (colorTheme === "0-18" && (boxSatelliteBrand || !boxPersonalization)) {
    deEtichetta = "";
  }

  const [etichettaPrimaRiga, etichettaSecondaRiga] = useMemo(
    () => splitEtichettaMobile(deEtichetta),
    [deEtichetta]
  );

  const logoRef = useRef();

  useEffect(() => {
    // In imperative mode sync params when no live
    // animation in place...
    if (imperativeLogo) {
      if (!isPlaying && !isRecording && logoRef.current) {
        logoRef.current.setParams(params);
      }
    }
  }, [
    isPlaying,
    isRecording,
    params,
    colorTheme,
    imperativeLogo,
    maxValue,
    maxDelta,
    home,
    size,
  ]);
  // In imperative avoid triggere rendering of <Logo />
  // while live animation in place...
  const logoParams = !imperativeLogo
    ? params
    : isPlaying || isRecording
    ? {}
    : params;

    const maxValueTh = useCallback((soundStats, mv) => {
      const v = soundStats.stats.volume <= maxValueThreshold ? mv : 50;
      return v
    }, [maxValueThreshold]);

  const transformParametersFromSound = useCallback(
    (nextSample, nextSoundData, soundStats, firstTick) => {
      const nextParams = paramsFromSound(
        nextSoundData,
        soundStats,
        paramsRef.current,
        maxValueTh(soundStats, maxValue),
        paramsStart.current
      );
      if (imperativeLogo) {
        logoRef.current.setParams(nextParams, paramsRef.current);
      }
      setParams(nextParams);
      if (!features.micAlwaysActive) {
        addParamsToHistory({ ...nextParams, timestamp: nextSample.timestamp });
      }
    },
    [
      paramsRef,
      maxValue,
      paramsStart,
      imperativeLogo,
      setParams,
      features.micAlwaysActive,
      addParamsToHistory,
    ]
  );

  const transformParametersFromSoundPlay = useCallback(
    (nextSample, nextSoundData, soundStats, firstTick, forceStop) => {
      if (firstTick) {
        if (imperativeLogo) {
          logoRef.current.setParams(CANONICAL_PARAMS, paramsRef.current);
        }
        setParams({ ...CANONICAL_PARAMS });
        return;
      }
      // console.log("paramsHistory", paramsHistory, nextSample)
      // console.log("sounda", soundStats, nextSoundData)
      const playTime =
        nextSample.timestamp.getTime() -
        soundStats.firstSample.timestamp.getTime();
      // console.log("playTime", playTime)
      const paramsHistoryStart = paramsHistory.current[0].timestamp.getTime();
      const nextParamsTsIndex = findIndex(
        paramsHistory.current,
        (p) => p.timestamp.getTime() - paramsHistoryStart >= playTime
      );
      const nextParamsTs = paramsHistory.current[nextParamsTsIndex];
      // console.log("vv", nextParamsTsIndex, paramsHistory.current.length)

      // const nextParams = paramsFromSound(
      //   nextSoundData,
      //   soundStats,
      //   paramsRef.current,
      //   maxValue,
      //   paramsStart.current
      // );
      // console.log("nextParams", nextParams)
      // console.log("nextParamsTs", nextParamsTs)

      if (nextParamsTs) {
        if (imperativeLogo) {
          logoRef.current.setParams(nextParamsTs, paramsRef.current);
        }
        setParams(nextParamsTs);
      } else {
        console.warn("Missing params ... force stop ...:", {
          nextSample,
          paramsHistory: paramsHistory.current,
        });
        forceStop()
      }
    },

    [paramsRef, imperativeLogo, setParams, paramsHistory]
  );

  const loadRandomLogo = useLoadRandomLogo();

  return (
    <div className="container-fluid h-100 ps-4 pe-4 ps-md-5 pe-md-5">
      <CheckBrowser />
      <div className="row h-100">
        {about && (
          <div className="col-md-12 h-100 d-flex flex-column justify-content-between">
            <div>
              {mode === "basic" && features.showDomanda && <BoxTextAbout />}
              {(mode === "tool" || mode === "pro") && <BoxTextTool />}
            </div>
            <div
              className="mt-5 mb-3 button-inizia"
              onClick={() => {
                setAbout(false);
                setHome(true);
              }}
            >
              Inizia{" "}
              <span className="ms-2">
                <img src={arrowRight} alt="Inizia" />
              </span>
            </div>
          </div>
        )}
        {home && (
          <div className="col-md-12 mb-3 pb-3 position-relative d-flex flex-1 flex-column justify-content-between">
            <div
              className="pt-3 slogan-mobile"
              onClick={() => {
                setAbout(true);
                setHome(false);
              }}
            >
              {features.domanda}
            </div>
            <div
              ref={ref}
              className="mt-3 w-100 flex-1 overflow-hidden position-relative xdimensions"
            >
              <div
                className="d-flex flex-column overflow-hidden"
                style={{
                  position: "absolute",
                  right: 0,
                  left,
                  bottom: 0,
                  top,
                }}
              >
                {size > 0 && (
                  <Logo
                    ref={logoRef}
                    {...logoParams}
                    width={size}
                    height={size}
                    maxValue={maxValue}
                    maschera0Color={COLOR_THEMES[colorTheme].maschera0Color}
                    mascheraPuntoColor={
                      COLOR_THEMES[colorTheme].mascheraPuntoColor
                    }
                    maschera8Color={COLOR_THEMES[colorTheme].maschera8Color}
                    etichettaPrimaRiga={etichettaPrimaRiga}
                    etichettaSecondaRiga={etichettaSecondaRiga}
                    etichettaColor={COLOR_THEMES[colorTheme].etichettaColor}
                    etichettaBackgroundColor={
                      COLOR_THEMES[colorTheme].etichettaBackgroundColor
                    }
                    etichettaStrokeColor={
                      COLOR_THEMES[colorTheme].etichettaStrokeColor || "none"
                    }
                    etichettaFontWeight={
                      COLOR_THEMES[colorTheme].etichettaFontWeight || "700"
                    }
                    imperative={imperativeLogo}
                  />
                )}
              </div>
            </div>

            <div>
              <div className="d-flex align-items-center justify-content-center w-100">
                {(mode === "tool" || mode === "pro") && (
                  <>
                    <div
                      className="mb-5 pointer"
                      onClick={() => setBoxSatelliteBrand(true)}
                    >
                      <img src={personalizationIcon} alt="Personalizza" />
                    </div>
                    <div
                      className="mb-5 ms-3 me-3"
                      style={{ height: 24, background: "black", width: 1 }}
                    ></div>
                    <div
                      className="pointer mb-5"
                      onClick={() => loadRandomLogo()}
                    >
                      <img src={randomIcon} alt="Random" />
                    </div>
                  </>
                )}
                <div
                  className={classNames("text-center ms-3 mt-4", {
                    "mb-3": mode === "basic",
                    "mb-5": mode !== "basic",
                  })}
                >
                  <RecordBox
                    type={mode}
                    dataCallback={transformParametersFromSound}
                    replayDataCallback={transformParametersFromSoundPlay}
                    // playStartCallback={playStartCallback}
                  />
                </div>
              </div>
              {mode === "basic" && Boolean(audioBlob) && !isRecording && (
                <div className="position-absolute links animated-logo-links">
                  {features.download && (
                    <div
                      className="pointer me-4"
                      onClick={() => setBoxSave(true)}
                    >
                      Scarica
                    </div>
                  )}
                  {features.customization && (
                    <div
                      className="me-4 pointer"
                      onClick={() => setBoxPersonalization(true)}
                    >
                      Personalizza
                    </div>
                  )}
                  {features.print && (
                    <div
                      className="pointer"
                      onClick={() => setIsPrinting(true)}
                    >
                      Stampa
                    </div>
                  )}
                  {features.send && (
                    <div
                      className="ms-4 pointer"
                      onClick={() => setBoxSend(true)}
                    >
                      Invia
                    </div>
                  )}
                </div>
              )}
              {(mode === "tool" || mode === "pro") && (
                <div className="position-absolute links">
                  <div className="pointer" onClick={() => setBoxExport(true)}>
                    Scarica versioni
                  </div>
                  <div
                    className="pointer ms-4"
                    onClick={() => setBoxUpload(true)}
                  >
                    Salva in archivio
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <BoxSatelliteBrandMobile
        name={etichetta}
        isOpen={boxSatelliteBrand}
        setName={setEtichetta}
        ageRange={colorTheme}
        setAgeRange={setColorTheme}
        onConfirm={(ageRange, labelText) => {
          setBoxSatelliteBrand(!boxSatelliteBrand);
        }}
        toggleBox={() => setBoxSatelliteBrand(!boxSatelliteBrand)}
      />
      <BoxPersonalizzaMobile
        name={etichetta || ""}
        setName={setEtichetta}
        years={years}
        isOpen={boxPersonalization}
        setYears={setYears}
        onConfirm={(name, years) => {
          setBoxPersonalization(!boxPersonalization);
        }}
        togglePersonalizza={() => setBoxPersonalization(!boxPersonalization)}
      />
      <PrintingModal
        isOpen={isPrinting}
        toggle={() => setIsPrinting(!isPrinting)}
      />
      <BoxSave
        etichettaPrimaRiga={etichettaPrimaRiga}
        etichettaSecondaRiga={etichettaSecondaRiga}
        isOpen={boxSave}
        isModal
        onConfirm={() => {
          setBoxSave(!boxSave);
        }}
        toggleBox={() => setBoxSave(!boxSave)}
      />
      <BoxExport
        isOpen={boxExport}
        etichettaPrimaRiga={etichettaPrimaRiga}
        etichettaSecondaRiga={etichettaSecondaRiga}
        onConfirm={() => {
          setBoxExport(!boxExport);
        }}
        isModal
        toggleBox={() => setBoxExport(!boxExport)}
      />
      <UploadModal
        isOpen={boxUpload}
        onConfirm={() => setBoxUpload(!boxUpload)}
        toggle={() => setBoxUpload(!boxUpload)}
      />
      <SendModal
        isOpen={boxSend}
        onConfirm={() => setBoxSend(!boxSend)}
        toggle={() => setBoxSend(!boxSend)}
      />
    </div>
  );
}
