export default function Download({ active }) {
  return (
    <svg
      width="11"
      height="18"
      viewBox="0 0 11 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_858_3871)">
        <path
          d="M5.18994 0V13.94"
          stroke={active ? "black" : "#A0A0A0"}
          strokeWidth="0.75"
        />
        <path
          d="M5.22998 14.3999C5.22998 11.5099 7.56998 9.16992 10.46 9.16992"
          stroke={active ? "black" : "#A0A0A0"}
          strokeWidth="0.75"
        />
        <path
          d="M0 9.16992C2.89 9.16992 5.23 11.5099 5.23 14.3999"
          stroke={active ? "black" : "#A0A0A0"}
          strokeWidth="0.75"
        />
        <path
          d="M10 17.3701H0"
          stroke={active ? "black" : "#A0A0A0"}
          strokeWidth="0.75"
        />
      </g>
      <defs>
        <clipPath id="clip0_858_3871">
          <rect width="10.45" height="17.75" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
