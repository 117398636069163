import { useMemo, useState } from "react";
import SliderYears from "../SliderYears";
import BoxWithBorders, { BoxContent } from "../BoxWithBorders";
import styles from "./BoxPersonalization.module.css";

function BoxPersonalizationBody({
  name,
  setName,
  years,
  setYears,
  toggleBox,
  onConfirm,
}) {
  const [reset, setReset] = useState(false);

  const initialEtichetta = useMemo(() => {
    return name || "";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialYears = useMemo(() => {
    return years;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function setDefaults() {
    setYears(null);
    setName("");
  }
  return (
    <BoxContent
      titleClass="normal"
      onConfirm={() => onConfirm(name, years)}
      resetButton={() => {
        setYears(initialYears);
        setName(initialEtichetta);
        toggleBox();
      }}
      title={"Personalizza"}
      isDisabled={(name === "" || years === null) && !reset}
      toggleBox={() => {
        setYears(initialYears);
        setName(initialEtichetta);
        toggleBox();
      }}
    >
      <div className="mt-4">
        <div className={styles.Question}>Come ti chiami?</div>
        <div className="mt-3">
          <textarea
            required
            rows={2}
            maxLength={
              name.indexOf("\n") === -1
                ? 30
                : name.split("\n")[0].length > 15
                ? 30
                : name.split("\n")[0].length + 16
            }
            className={"input-name w-100"}
            placeholder={"Inserisci qui il tuo nome..."}
            type={"text"}
            value={name}
            onChange={(e) => {
              if (name.indexOf("\n") !== -1) {
                if (e.nativeEvent.inputType === "insertLineBreak") return;
              }
              setName(e.target.value);
              setReset(false);
            }}
          />
        </div>
        <div className={`mt-5 ${styles.Question}`}>Quanti anni hai?</div>
        <div className={`mt-4 ${styles.SliderContainer}`}>
          <SliderYears setReset={setReset} years={years} setYears={setYears} />
          {years === null && (
            <div className={styles.SelectAgeText}>Seleziona la tua età</div>
          )}
        </div>
        <div
          className={styles.Reset}
          onClick={() => {
            setReset(true);
            setDefaults();
          }}
        >
          <div className={styles.ResetIcon} />
          <div className={styles.ResetText}>
            Ripristina marchio non
            <br />
            personalizzato
          </div>
        </div>
      </div>
    </BoxContent>
  );
}

export default function BoxPersonalization({
  toggleBox,
  onConfirm,
  name,
  setName,
  years,
  setYears,
  isOpen,
  isPro,
}) {
  return (
    <BoxWithBorders
      isOpen={isOpen}
      isPro={isPro}
      bodyBox={
        <BoxPersonalizationBody
          toggleBox={toggleBox}
          onConfirm={onConfirm}
          name={name}
          setName={setName}
          years={years}
          setYears={setYears}
        />
      }
    />
  );
}
