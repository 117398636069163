import ReactSlider from "react-slider";
import styles from "./SliderYears.module.css";

export default function SliderYears({ years, setYears, setReset }) {
  return (
    <div className="d-flex align-items-center">
      <div className={`me-2 ${styles.Extrem}`}>0</div>
      <ReactSlider
        min={0}
        max={18}
        pearling
        onChange={(value) => {
          setYears(value);
          if (setReset) {
            setReset(false);
          }
        }}
        className={styles.HorizontalSlider}
        thumbClassName={styles.ThumbSlider}
        value={years ?? 9}
        renderTrack={(props, state) => (
          <div className="d-flex" {...props}>
            <div className={styles.FirstStepSlider}></div>
            <div className={styles.SecondStepSlider}></div>
            <div className={styles.ThirdStepSlider}></div>
          </div>
        )}
        renderThumb={(props, state) => (
          <div {...props}>
            {years !== null && (
              <div className={`mt-4 text-nowrap ${styles.ValueSlider}`}>
                {state.valueNow} anni
              </div>
            )}
          </div>
        )}
      />
      <div className={`ms-2 ${styles.Extrem}`}>18</div>
    </div>
  );
}
