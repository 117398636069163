import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  useContext,
} from "react";
import { useIsMobileScreen } from "../../hooks/screen";
import {
  AudioGraph,
  SoundStats,
  Recorder,
  processAnalyserData,
} from "../../utils/audio";
import { FeaturesContext, PlayerContext } from "../../contexts";

export function drawOnCanvas(svgNode, canvas, context) {
  const svgString = new XMLSerializer().serializeToString(svgNode);
  const svg = new Blob([svgString], { type: "image/svg+xml;charset=utf-8" });
  const url = URL.createObjectURL(svg);
  const img = new Image();
  img.onload = () => {
    context.clearRect(0, 0, canvas.width, canvas.height);
    context.drawImage(img, 0, 0, canvas.width, canvas.height);
    URL.revokeObjectURL(url); // GC
  };
  img.src = url;
}

function getMicrophonePermission() {
  if (
    typeof navigator.permissions === "undefined" ||
    typeof navigator.permissions.query === "undefined"
  ) {
    return Promise.resolve({ state: "granted" });
  } else {
    return navigator.permissions.query({ name: "microphone" });
  }
}

const FIRST_TICK_FRAMES = 1;

function AudioManager({
  dataCallback,
  replayDataCallback,
  recordCallback,
  playStartCallback,
}) {
  const audioRef = useRef();
  const audioGraph = useRef();
  const track = useRef();
  const micTrack = useRef();
  const animationID = useRef();
  const soundData = useRef(new SoundStats());
  const recorder = useRef();
  const offCanvas = useRef(null);
  const offCanvasContext = useRef(null);
  const isMobile = useIsMobileScreen();

  const sizeCanvas = isMobile ? 800 : 1440;

  if (offCanvas.current === null) {
    offCanvas.current = document.createElement("canvas");
    offCanvas.current.width = sizeCanvas;
    offCanvas.current.height = sizeCanvas;
    offCanvasContext.current = offCanvas.current.getContext("2d");
  }

  const { isRecording, isPlaying, setIsPlaying, audioBlob } =
    useContext(PlayerContext);

  const { micAlwaysActive } = useContext(FeaturesContext);

  const [audioUrl, setAudioUrl] = useState(null);
  useEffect(() => {
    if (audioBlob) {
      const url =
        audioBlob instanceof Blob ? URL.createObjectURL(audioBlob) : audioBlob;
      setAudioUrl(url);
    } else {
      setAudioUrl(null);
    }
  }, [audioBlob]);

  const updateOffscreenCanvas = useCallback(() => {
    drawOnCanvas(
      document.querySelector(".svg-container > svg"),
      offCanvas.current,
      offCanvasContext.current
    );
  }, []);

  const timeoutIdCanvasRef = useRef(null);
  const tickCanvas = useCallback(() => {
    updateOffscreenCanvas();
    timeoutIdCanvasRef.current = setTimeout(() => tickCanvas(), 1000 / 30);
  }, [updateOffscreenCanvas]);

  const tick = useCallback(
    (cb, updateCanvas = false, firstTickThreshold = 0) => {
      animationID.current = requestAnimationFrame(() =>
        tick(cb, updateCanvas, Math.max(firstTickThreshold - 1, 0))
      );

      const bufferLength =
        audioGraph.current.graph.analyserNode.frequencyBinCount;
      let analyserData = new Uint8Array(bufferLength);
      audioGraph.current.graph.analyserNode.getByteFrequencyData(analyserData);

      const nextSoundData = processAnalyserData(analyserData);
      const nextSample = soundData.current.processSample(nextSoundData);

      if (updateCanvas && !micAlwaysActive) {
        const track = recorder.current.videoStream.getTracks()[0];
        if (typeof track.requestFrame === "function") {
          track.requestFrame();
        }
      }

      // updateCanvas && updateOffscreenCanvas();
      function forceStop() {
        setIsPlaying(false)
      }

      nextSample &&
        cb &&
        cb(
          nextSample,
          nextSoundData,
          soundData.current,
          firstTickThreshold > 0,
          forceStop
        );
    },
    [micAlwaysActive, setIsPlaying]
  );

  useEffect(() => {
    if (!audioGraph.current) {
      audioGraph.current = new AudioGraph();
      audioRef.current.onended = function () {
        setIsPlaying(false);
      };
      audioRef.current.onplay = function () {
        if (audioGraph.current.graph.audioContext.state === "suspended") {
          audioGraph.current.graph.audioContext.resume();
        }
      };
    }

    if (!track.current) {
      track.current =
        audioGraph.current.graph.audioContext.createMediaElementSource(
          audioRef.current
        );
    }

    audioGraph.current.connectSource(track.current, true);

    getMicrophonePermission().then(
      (r) => {
        if (r.state !== "granted") {
          navigator.mediaDevices
            .getUserMedia({ video: false, audio: true })
            .then((stream) => {
              stream.getAudioTracks().forEach((track) => {
                track.stop();
              });
            });
        }
      },
      (err) => {
        console.error("Mic permission error.", err);
      }
    );

    return () => {
      if (animationID.current) {
        cancelAnimationFrame(animationID.current);
        animationID.current = null;
      }
      if (timeoutIdCanvasRef.current) {
        clearTimeout(timeoutIdCanvasRef.current);
        timeoutIdCanvasRef.current = null;
      }
      audioGraph.current.cleanup();
    };
  }, [setIsPlaying]);

  const play = useCallback(async () => {
    const audioContext = audioGraph.current.graph.audioContext;
    if (audioContext.state === "suspended") {
      try {
        await audioContext.resume();
      } catch (e) {
        console.error('Failed to resume audio context', e)
      }
    }
    soundData.current.reset();
    audioGraph.current.connectSource(track.current, true);
    audioRef.current.currentTime = 0;

    try {
      await audioRef.current.play();
    } catch (e) {
      console.error('Failed to play current audio:', e)
      return
    }

    playStartCallback && playStartCallback();

    if (!animationID.current) {
      tick(replayDataCallback, false, FIRST_TICK_FRAMES);
    }
  }, [playStartCallback, replayDataCallback, tick]);

  const stop = useCallback(() => {
    audioRef.current.pause();
    if (animationID.current) {
      cancelAnimationFrame(animationID.current);
      animationID.current = null;
    }
    if (timeoutIdCanvasRef.current) {
      clearTimeout(timeoutIdCanvasRef.current);
      timeoutIdCanvasRef.current = null;
    }
  }, []);

  const micPlaybackStateRef = useRef(isRecording);

  const playMic = useCallback(() => {
    const audioContext = audioGraph.current.graph.audioContext;
    const micPlaybackState = micPlaybackStateRef.current;

    if (!micPlaybackState) {
      if (audioContext.state === "suspended") {
        audioContext.resume();
      }

      if (!micTrack.current) {
        navigator.mediaDevices
          .getUserMedia({ video: false, audio: true })
          .then((stream) => {
            if (audioGraph.current.graph.audioContext.state === "suspended") {
              audioGraph.current.graph.audioContext.resume();
            }
            const ctx = audioGraph.current.graph.audioContext;
            micTrack.current = ctx.createMediaStreamSource(stream);
            micTrack.current._stream = stream;

            soundData.current.reset();
            audioGraph.current.connectSource(micTrack.current);

            if (!micAlwaysActive) {
              recorder.current = new Recorder(stream, offCanvas.current);
            }
            if (!animationID.current) {
              tick(dataCallback, true, FIRST_TICK_FRAMES);
              if (!micAlwaysActive) {
                tickCanvas();
              }
            }
            if (!micAlwaysActive) {
              recorder.current.start();
            }
            micPlaybackStateRef.current = true;
          });
      }
    }
  }, [micAlwaysActive, tick, dataCallback, tickCanvas]);

  const stopMic = useCallback(() => {
    const micPlaybackState = micPlaybackStateRef.current;
    if (micPlaybackState) {
      audioRef.current.pause();
      if (animationID.current) {
        cancelAnimationFrame(animationID.current);
        animationID.current = null;
      }
      if (timeoutIdCanvasRef.current) {
        clearTimeout(timeoutIdCanvasRef.current);
        timeoutIdCanvasRef.current = null;
      }
      if (micTrack.current) {
        micPlaybackStateRef.current = false;
        micTrack.current._stream.getAudioTracks().forEach((track) => {
          track.stop();
          micTrack.current = null;
        });
      }

      if (recorder.current) {
        recorder.current.stop((blob) => {
          recorder.current = null;
          if (recordCallback) {
            recordCallback(blob, soundData.current);
          }
        });
      }
    }
  }, [recordCallback]);

  //reset soundData
  //   useEffect(() => {
  //     if (!isPlaying) {
  //       soundData.current = new SoundStats();
  //     }
  //   }, [isPlaying]);

  // useEffect(() => {
  //   if (isRecording) {
  //     playMic();
  //   } else {
  //     stopMic();
  //   }
  // }, [isRecording, playMic, stopMic]);

  const prevIsRecording = useRef(isRecording);
  useEffect(() => {
    if (prevIsRecording.current !== isRecording) {
      prevIsRecording.current = isRecording;
      if (isRecording) {
        playMic();
      } else {
        setTimeout(() => {
          stopMic();
        }, 250);
      }
    }
  }, [isRecording, playMic, stopMic]);

  useEffect(() => {
    if (isPlaying) {
      play();
    } else {
      stop();
    }
  }, [isPlaying, play, stop]);

  return (
    <div>
      <audio
        // controls
        crossOrigin="anonymous"
        ref={audioRef}
        src={audioUrl}
      ></audio>
    </div>
  );
}

export default React.memo(AudioManager);
