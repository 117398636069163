import { useEffect } from "react";
import ReactDOM from "react-dom/client";
import domtoimage from "dom-to-image";
import Logo from "../components/Logo";

async function makeLogoSvgString(svgNode) {
  // Add fonts ...
  const cssText = await domtoimage.impl.fontFaces.resolveAll();
  const styleNode = document.createElement("style");
  styleNode.appendChild(document.createTextNode(cssText));
  const node = svgNode.cloneNode(true);
  node.prepend(styleNode);
  // Extract svg
  const svgString = new XMLSerializer().serializeToString(node);
  return svgString;
}

export async function fromObjectURLToImage(url, width, height) {
  const canvas = document.createElement("canvas");
  canvas.width = width;
  canvas.height = height;
  const context = canvas.getContext("2d");
  const img = new Image();
  return new Promise((resolve, reject) => {
    img.onload = () => {
      context.clearRect(0, 0, canvas.width, canvas.height);
      context.drawImage(img, 0, 0, canvas.width, canvas.height);
      canvas.toBlob(resolve);
    };
    img.src = url;
    img.onerror = reject;
  });
}

export async function fromSvgStringToImage(svgString, width, height) {
  const svg = new Blob([svgString], { type: "image/svg+xml;charset=utf-8" });
  // Draw on canvas and export related canvas
  const canvas = document.createElement("canvas");
  canvas.width = width;
  canvas.height = height;
  const context = canvas.getContext("2d");
  const url = URL.createObjectURL(svg);
  const img = new Image();
  return new Promise((resolve, reject) => {
    img.onload = () => {
      context.clearRect(0, 0, canvas.width, canvas.height);
      context.drawImage(img, 0, 0, canvas.width, canvas.height);
      URL.revokeObjectURL(url);
      canvas.toBlob(resolve);
    };
    img.src = url;
    img.onerror = reject;
  });
}

export async function makeLogoImage(svgNode, width = 1500, height = 1500) {
  const svgString = await makeLogoSvgString(svgNode);
  return fromSvgStringToImage(svgString, width, height);
}

function Wrapper({ children, fn }) {
  useEffect(() => {
    fn();
    // I know what i am doing lol
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return children;
}

/**
 * @param {Record<string, any>[]} combos
 */
export async function* generateCombos(combos, size = 1500) {
  const container = document.createElement("div");
  const root = ReactDOM.createRoot(container);

  function getComboImage(props, i) {
    return new Promise((resolve) => {
      root.render(
        <Wrapper
          key={i}
          fn={() => {
            requestAnimationFrame(async () => {
              const image = await makeLogoImage(
                container.querySelector(".svg-container > svg"),
                size,
                size
              );
              resolve(image);
            });
          }}
        >
          <Logo {...props} />
        </Wrapper>
      );
    });
  }

  let i = 0;
  for (const props of combos) {
    const image = await getComboImage(props, i);
    i++;
    yield image;
  }

  root.unmount();
}

/**
 * @param {Record<string, any>[]} combos
 */
export async function* generateCombosSvgsString(combos, size = 1500) {
  const container = document.createElement("div");
  container.style.opacity = 0;
  container.style.position = "fixed";
  container.style.bottom = "0";
  container.style.zIndex = "-1";
  document.body.append(container);
  const root = ReactDOM.createRoot(container);

  function getComboImage(props, i) {
    return new Promise((resolve) => {
      root.render(
        <Wrapper
          key={i}
          fn={() => {
            requestAnimationFrame(async () => {
              const svgString = await makeLogoSvgString(
                container.querySelector(".svg-container > svg"),
                size,
                size
              );
              resolve(svgString);
            });
          }}
        >
          <Logo {...props} />
        </Wrapper>
      );
    });
  }

  let i = 0;
  for (const props of combos) {
    const svgString = await getComboImage(props, i);
    i++;
    yield svgString;
  }

  root.unmount();
  container.remove();
}
