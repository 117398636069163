import { getSupportedVideoMimeFormat } from "./video";

export const AUDIO_SAMPLES = ["man-scream.mp3"];

export function collectMean(sampleMean, value, sampleLen) {
  if (!sampleLen || sampleMean === undefined) {
    return value;
  }

  if (Array.isArray(sampleMean)) {
    return sampleMean.map(
      (x, i) => (sampleLen * x + value[i]) / (sampleLen + 1)
    );
  } else {
    return (sampleLen * sampleMean + value) / (sampleLen + 1);
  }
}

export function processAnalyserData(analyserData) {
  let sumSquares = 0.0;
  for (const amplitude of analyserData) {
    sumSquares += amplitude * amplitude;
  }
  const volume = Math.sqrt(sumSquares / analyserData.length);
  return {
    frequencyData: Array.from(analyserData),
    volume: Number(volume),
  };
}

export class SoundStats {
  constructor(numSamples = 20) {
    this.numSamples = numSamples;
    this.lastSamples = new Array(numSamples);
    this.stats = {};
    this.numSamplesProcessed = 0;
    this.allSamples = [];
    this.totalTime = 0;
    this.firstSample = null;
  }

  reset() {
    this.lastSamples = new Array(this.numSamples);
    this.stats = {};
    this.numSamplesProcessed = 0;
    this.allSamples = [];
    this.totalTime = 0;
    this.firstSample = null;
  }

  processSample = (sample) => {
    const lastSampleTime = new Date();

    this.lastSamples = this.lastSamples
      .slice(1, this.numSamples)
      .concat([{ ...sample }]);

    const newSample = { ...sample, timestamp: lastSampleTime };
    // this.allSamples = this.allSamples.concat([newSample]);
    if (this.numSamplesProcessed === 0) {
      this.firstSample = newSample;
    }
    if (this.numSamplesProcessed > 1) {
      // const firstSample = this.allSamples[0];
      this.totalTime = newSample.timestamp - this.firstSample.timestamp;
    }

    Object.keys(sample).forEach((key) => {
      const nextVal = collectMean(
        this.stats[key],
        sample[key],
        this.numSamplesProcessed
      );
      this.stats[key] = nextVal;
    });
    this.numSamplesProcessed += 1;
    return newSample;
  };
}

export class AudioGraph {
  constructor(graph) {
    if (graph) {
      this.graph = graph;
    } else {
      this.setupAudioGraphSync();
    }
    this.connectedSource = null;
  }

  setupAudioGraphSync = () => {
    const audioContext = new AudioContext();

    const analyserNode = audioContext.createAnalyser();
    analyserNode.fftSize = 32;
    analyserNode.connect(audioContext.destination);

    this.graph = {
      audioContext,
      analyserNode,
    };
  };

  connectSource = (source, connectDestination = false) => {
    this.connectedSource = source;
    this.connectedSource.connect(this.graph.analyserNode);
    if (connectDestination) {
      this.graph.analyserNode.connect(this.graph.audioContext.destination);
    } else {
      try {
        this.graph.analyserNode.disconnect(this.graph.audioContext.destination);
      } catch (err) {}
    }
  };

  cleanup = () => {
    if (this.connectedSource) {
      this.connectedSource.disconnect(this.graph.analyserNode);
      this.connectedSource = null;
    }
  };
}

export class Replayer {
  constructor(data) {
    this.data = data;
  }

  parseData = (data) => {
    if (!data.length) {
      return data;
    }
    const firstDatum = data[0];
    const firstTs = new Date(firstDatum.timestamp);
    const firstTime = firstTs.getTime();

    return data.map((datum) => {
      const ts = new Date(datum.timestamp);
      const time = ts.getTime();

      datum.schedule = time - firstTime;

      return datum;
    });
  };

  replay = (callback) => {
    if (Array.isArray(this.data)) {
      const parsedData = this.parseData(this.data);
      parsedData.forEach((datum) => {
        setTimeout(() => {
          callback(datum);
        }, datum.schedule);
      });
    }
  };
}

export class Recorder {
  constructor(stream, canvas) {
    this.videoStream = canvas.captureStream();

    this.stream = stream;
    this.chunks = [];

    const videoTrack = this.videoStream.getTracks()[0];
    const audioTrack = stream.getTracks()[0];

    const combinedStream = new MediaStream([audioTrack, videoTrack]);

    this.recorder = new MediaRecorder(combinedStream, {
      mimeType: getSupportedVideoMimeFormat(),
    });
    this.bindEvents();
  }

  bindEvents = () => {
    const _this = this;
    this.recorder.ondataavailable = function (e) {
      _this.addChunk(e.data);
    };
  };

  addChunk = (chunk) => {
    this.chunks.push(chunk);
  };

  start = () => {
    this.recorder.start();
  };

  stop = (callback) => {
    const _this = this;
    this.recorder.onstop = function (e) {
      const blob = _this.getBlob();
      callback(blob);
    };
    this.recorder.stop();
  };

  getBlob = () => {
    return new Blob(this.chunks, { type: "audio/mp3" });
  };
}
