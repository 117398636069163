export default function Printer({ active }) {
  return (
    <svg
      width="19"
      height="16"
      viewBox="0 0 19 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_814_1099)">
        <path
          d="M16.6826 3.5752H1.82714C1.16295 3.5752 0.624512 4.11466 0.624512 4.78013V10.1925C0.624512 10.8579 1.16295 11.3974 1.82714 11.3974H16.6826C17.3468 11.3974 17.8852 10.8579 17.8852 10.1925V4.78013C17.8852 4.11466 17.3468 3.5752 16.6826 3.5752Z"
          stroke={active ? "black" : "#A0A0A0"}
          strokeWidth="0.75"
        />
        <path
          d="M16.3968 0.365234H2.10327V3.57511H16.3968V0.365234Z"
          stroke={active ? "black" : "#A0A0A0"}
          strokeWidth="0.75"
        />
        <path
          d="M14.6323 9.29395H3.85791V15.6347H14.6323V9.29395Z"
          fill="white"
          stroke={active ? "black" : "#A0A0A0"}
          strokeWidth="0.75"
        />
        <path d="M2.72437 9.29395H15.766" stroke={active ? "black" : "#A0A0A0"} strokeWidth="0.75" />
        <path d="M5.97729 11.3877H12.5129" stroke={active ? "black" : "#A0A0A0"} strokeWidth="0.75" />
        <path d="M5.97729 13.0767H12.5129" stroke={active ? "black" : "#A0A0A0"} strokeWidth="0.75" />
      </g>
      <defs>
        <clipPath id="clip0_814_1099">
          <rect
            width="18"
            height="16"
            fill="white"
            transform="translate(0.25)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
