import { useMemo, useContext, useCallback, useEffect } from "react";
import recordIcon from "../../assets/record.svg";
import recordIconGif from "../../assets/record-gif.svg";
import recordSmallIconGif from "../../assets/record-small-gif.svg";
import recordIconActive from "../../assets/record-active.svg";
import recordSmallIcon from "../../assets/record-small.svg";
import recordSmallIconActive from "../../assets/record-small-active.svg";
import replayIcon from "../../assets/replay.svg";
import styles from "./RecordBox.module.css";
import AudioManager from "../AudioManager";
import {
  FeaturesContext,
  ParametersContext,
  PlayerContext,
} from "../../contexts";
import Duration from "dayjs/plugin/duration";
import dayjs from "dayjs";
import classNames from "classnames";
import { isTouchSupported } from "../../utils/mobile";
import { useIsMobileScreen } from "../../hooks/screen";
dayjs.extend(Duration);

export default function RecordBox({
  // isRecording = false,
  // recordAudio = false,
  type = "base",
  dataCallback,
  replayDataCallback,
  playStartCallback,
}) {
  const { snapshotVideoParams, isVideoInSyncWithLogo } =
    useContext(ParametersContext);
  const {
    isRecording,
    setIsRecording,
    isPlaying,
    setIsPlaying,
    audioBlob,
    setAudioBlob,
    setCurrentTime,
    currentTime,
    totalTime,
    setTotalTime,
    setAudioSamples,
  } = useContext(PlayerContext);
  const { micAlwaysActive } = useContext(FeaturesContext);

  const setIsRecordingReset = useCallback(
    (arg) => {
      if (arg) {
        setAudioBlob(null);
      }
      setIsRecording(arg);
    },
    [setIsRecording, setAudioBlob]
  );

  const displayTime = useMemo(() => {
    if (!isRecording && !isPlaying && !audioBlob) {
      return dayjs.duration(0).format("mm:ss");
    }

    if (isRecording || isPlaying) {
      return dayjs
        .duration(currentTime, "milliseconds")
        .format("mm:ss", { trim: false });
    } else {
      return dayjs
        .duration(totalTime, "milliseconds")
        .format("mm:ss", { trim: false });
    }
  }, [isRecording, isPlaying, audioBlob, currentTime, totalTime]);

  const dataCallbackInner = useCallback(
    (nextSample, nextSoundData, soundStats, firstTick) => {
      setCurrentTime(soundStats.totalTime);
      dataCallback(nextSample, nextSoundData, soundStats, firstTick);
    },
    [dataCallback, setCurrentTime]
  );

  const replayDataCallbackInner = useCallback(
    (nextSample, nextSoundData, soundStats, firstTick, forceStop) => {
      setCurrentTime(soundStats.totalTime);
      replayDataCallback(
        nextSample,
        nextSoundData,
        soundStats,
        firstTick,
        forceStop
      );
    },
    [replayDataCallback, setCurrentTime]
  );

  const canUseTouch = isTouchSupported();

  useEffect(() => {
    if (micAlwaysActive) {
      return;
    }
    if (isRecording && !canUseTouch) {
      function stopRecording() {
        setIsRecordingReset(false);
      }
      window.addEventListener("mouseup", stopRecording);
      return () => {
        window.removeEventListener("mouseup", stopRecording);
      };
    }
  }, [canUseTouch, isRecording, micAlwaysActive, setIsRecordingReset]);

  const isMobile = useIsMobileScreen();

  const micImageSize =
    type === "tool" || type === "pro" ? 38 : isMobile ? 72 : "auto";

  return (
    <div
      className={
        type !== "tool" && type !== "pro"
          ? styles.RecordBoxNormal
          : styles.RecordBox
      }
    >
      <div
        style={{ display: micAlwaysActive && isRecording ? "none" : undefined }}
        className={styles.RecordMic}
        onClick={
          micAlwaysActive
            ? () => {
                setIsRecording(true);
              }
            : undefined
        }
        onTouchStart={
          canUseTouch && !micAlwaysActive
            ? () => setIsRecordingReset(true)
            : undefined
        }
        onMouseDown={
          !canUseTouch && !micAlwaysActive
            ? () => setIsRecordingReset(true)
            : undefined
        }
        onTouchEnd={
          canUseTouch && !micAlwaysActive
            ? () => setIsRecordingReset(false)
            : undefined
        }
      >
        <div className={styles.Button}>
          {(type === "tool" || type === "pro") && !isMobile && (
            <div className={styles.TextButton}>genera con input vocale</div>
          )}
          <div className={styles.ContainerIconAndTime}>
            <div className={classNames(styles.RecordImageContainer, {})}>
              <img
                src={
                  type === "tool" || type === "pro"
                    ? isRecording
                      ? recordSmallIconActive
                      : !Boolean(audioBlob) && !isRecording
                      ? recordSmallIconGif
                      : recordSmallIcon
                    : isRecording
                    ? recordIconActive
                    : !Boolean(audioBlob) && !isRecording
                    ? recordIconGif
                    : recordIcon
                }
                height={micImageSize}
                width={micImageSize}
                alt="Record"
              />
            </div>
            <div className={styles.TimeRecording}>
              <span
                style={{
                  opacity: isRecording
                    ? 1
                    : Boolean(audioBlob) && isVideoInSyncWithLogo
                    ? 1
                    : 0,
                }}
              >
                {displayTime}
              </span>
              <div className={styles.TimeRecordingDot}>
                {isRecording && (
                  <svg width={6} height={6}>
                    <circle r={3} cx={3} cy={3}></circle>
                  </svg>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={classNames(styles.Replay, {
          [styles.ReplayHidden]: !Boolean(audioBlob) || !isVideoInSyncWithLogo,
          [styles.ReplayMobileTool]: type === "pro" || type === "tool",
        })}
      >
        <div
          className={`${
            type === "pro" || type === "tool"
              ? styles.ReplayIconTool
              : styles.ReplayIcon
          } ms-4 ms-md-2 pointer`}
          onClick={() => setIsPlaying(!isPlaying)}
        >
          <img src={replayIcon} alt="Replay" />
        </div>
        <div
          className={
            type === "pro" || type === "tool"
              ? styles.ReplayTextPro
              : styles.ReplayText
          }
        >
          REPLAY
        </div>
      </div>
      <AudioManager
        dataCallback={dataCallbackInner}
        playStartCallback={playStartCallback}
        replayDataCallback={replayDataCallbackInner}
        recordCallback={(blob, soundData) => {
          setTotalTime(soundData.totalTime);
          setAudioBlob(blob);
          setAudioSamples(soundData.allSamples);
          snapshotVideoParams();
        }}
      ></AudioManager>
    </div>
  );
}
