import { memo } from "react";
import Microphone from "./components/Microphone";
import Personalization from "./components/Personalization";
import Printer from "./components/Printer";
import SaveToArchive from "./components/SaveToArchive";
import SendEmail from "./components/SendEmail";
import styles from "./InstallationCard.module.css";
import ageRange06 from "./assets/0-6.svg";
import ageRange614 from "./assets/6-14.svg";
import ageRange1418 from "./assets/14-18.svg";
import ageRange018 from "./assets/0-18.svg";
import Download from "./components/Download";

function InstallationCard({ installation, onDelete, onUpdate }) {
  const url = `${window.location.origin}/${installation.token}`;
  return (
    <div className={`${styles.InstallationCard} w-100`}>
      <div className={styles.CardInfo}>
        <div className={styles.Name}>{installation.name}</div>
        <div className={styles.Years}>
          <div className="me-2">{installation.theme}</div>
          {installation.theme === "0-18" && (
            <div>
              <img src={ageRange018} width={23} alt={installation.theme} />
            </div>
          )}
          {installation.theme === "0-6" && (
            <div>
              <img src={ageRange06} width={23} alt={installation.theme} />
            </div>
          )}
          {installation.theme === "6-14" && (
            <div>
              <img src={ageRange614} width={23} alt={installation.theme} />
            </div>
          )}
          {installation.theme === "14-18" && (
            <div>
              <img src={ageRange1418} width={23} alt={installation.theme} />
            </div>
          )}
        </div>
        <div className={styles.Etichetta}>{installation.etichetta}</div>
        <div className="d-flex w-100 justify-content-between mt-3">
          <div>
            <Microphone active={installation.feature_mic_always_active} />
          </div>
          <div>
            <Printer active={installation.feature_print} />
          </div>
          <div>
            <Personalization active={installation.feature_customization} />
          </div>
          <div>
            <SaveToArchive active={installation.feature_archive} />
          </div>
          <div>
            <SendEmail active={installation.feature_send} />
          </div>
          <div>
            <Download active={installation.feature_download} />
          </div>
        </div>
        <div className={styles.Token}>
          <a title={url} target="_blank" href={url} rel="noreferrer">
            {url}
          </a>
        </div>
      </div>
      <div className="d-flex justify-content-between mt-2">
        <div className={styles.Delete} onClick={() => onDelete(installation)}>
          Elimina
        </div>
        <div className={styles.Edit} onClick={() => onUpdate(installation)}>
          Modifica
        </div>
      </div>
    </div>
  );
}

export default memo(InstallationCard);
