import { useMemo } from "react";
import { useParams } from "react-router-dom";
import PageNotFound from "../components/PageNotFound";
import { DEFAULT_FEATURES } from "../contexts";
import { useInstallationByToken } from "../hooks/installation";
import AnimatedLogo from "./AnimatedLogo";

export function AnimatedLogoInstallation() {
  const { token } = useParams();
  const [{ data: installation, error }] = useInstallationByToken(token);

  const features = useMemo(() => {
    if (!installation) {
      return null;
    }
    return {
      domanda: installation.domanda || DEFAULT_FEATURES.domanda,
      print: installation.feature_print,
      micAlwaysActive: installation.feature_mic_always_active,
      send: installation.feature_send,
      customization: installation.feature_customization,
      download: installation.feature_download,
      archive: installation.feature_archive,
      showDidascalia: installation.show_didascalia,
      showDomanda: installation.show_domanda
    };
  }, [installation]);

  if (error) {
    return <PageNotFound />;
  }
  if (!installation) {
    return null;
  }

  return (
    <AnimatedLogo
      features={features}
      initialColorTheme={installation.theme}
      initialEtichetta={installation.etichetta}
      mode="basic"
    />
  );
}
