import classNames from "classnames";
import { useEffect, useState } from "react";
import closeIcon from "../../assets/close.svg";
import styles from "./BoxWithBorders.module.css";

export function BoxContent({
  toggleBox,
  title = "",
  isDisabled = false,
  onConfirm,
  showButtons = true,
  resetButton = toggleBox,
  titleClass = "up",
  textButtonConfirm = "CONFERMA",
  children,
}) {
  return (
    <>
      {titleClass === "up" ? (
        <div className="d-flex justify-content-between">
          <div className={styles.TitleBoxUp}>{title}</div>
          <div className={styles.CloseBox} onClick={toggleBox}>
            <img src={closeIcon} alt="Close" />
          </div>
        </div>
      ) : (
        <>
          <div className={styles.CloseBox} onClick={toggleBox}>
            <img src={closeIcon} alt="Close" />
          </div>
          <div>
            <div className={styles.TitleBox}>{title}</div>
          </div>
        </>
      )}
      {children}
      {showButtons && (
        <div className={styles.Buttons}>
          <div className={styles.ButtonCancel} onClick={resetButton}>
            Annulla
          </div>
          <div
            className={
              isDisabled ? styles.ButtonConfirmDisabled : styles.ButtonConfirm
            }
            onClick={() => {
              if (!isDisabled) {
                onConfirm();
              }
            }}
          >
            {textButtonConfirm}
          </div>
        </div>
      )}
    </>
  );
}

export default function BoxWithBorders({
  bodyBox,
  isOpen = false,
  isModal = false,
  isPro = false,
}) {
  const [animation, setAnimation] = useState(isOpen ? "open" : "close");
  useEffect(() => {
    // Avoid extra render in modal mode ...
    if (isModal) {
      return
    }
    if (isOpen) {
      setAnimation("open");
    }
  }, [isModal, isOpen]);
  let klass;
  if (isModal) {
    klass = classNames(styles.BoxModal, {
      [styles.BoxClose]: !isOpen,
      [styles.BoxOpen]: isOpen,
    });
  } else {
    klass = classNames({
      [styles.BoxAnimation]: true,
      [styles.BoxRightDesktop]: !isPro,
      [styles.BoxRightDesktopPro]: isPro,
      [styles.BoxClosing]: !isOpen && animation !== "close",
      [styles.BoxClose]: !isOpen && animation === "close",
      [styles.BoxOpening]: isOpen,
      [styles.BoxOpen]: animation === "open",
    });
  }
  const shouldShowBodyBox = isModal ? isOpen : animation !== "close"
  return (
    <div
      className={klass}
      onTransitionEnd={() => {
        if (!isOpen) {
          setAnimation("close");
        }
      }}
    >
      {shouldShowBodyBox && bodyBox}
    </div>
  );
}
