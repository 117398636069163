import {
  startTransition,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { createSearchParams, useSearchParams } from "react-router-dom";
import useDebounceCallback from "magik-react-hooks/useDebounceCallback";

function useGetLastValue(value) {
  const valueRef = useRef(value);
  useEffect(() => {
    valueRef.current = value;
  });
  return useCallback(() => valueRef.current, []);
}

export function useQsFilters(initFn, debounceTime = 150) {
  const [searchParams, setSearchParams] = useSearchParams();

  const filtersFromParams = useMemo(
    () => initFn(searchParams),
    [initFn, searchParams]
  );

  const [filters, setFilters] = useState(filtersFromParams);
  const [uiFilters, setUiFilters] = useState(filtersFromParams);

  const queryStringFilters = useMemo(
    () => createSearchParams(filters).toString(),
    [filters]
  );
  const queryStringParams = useMemo(
    () => createSearchParams(filtersFromParams).toString(),
    [filtersFromParams]
  );
  if (queryStringFilters !== queryStringParams) {
    setUiFilters(filtersFromParams);
    setFilters(filtersFromParams);
  }
  const getLastFilters = useGetLastValue(filters);

  const setDataFilters = useCallback(
    (newFilters) => {
      setFilters((prevFilters) => ({
        ...prevFilters,
        ...newFilters,
      }));
      setSearchParams({
        ...getLastFilters(),
        ...newFilters,
      });
    },
    [getLastFilters, setSearchParams]
  );

  const setDataFiltersDebounced = useDebounceCallback(
    setDataFilters,
    debounceTime
  );

  const setAllFilters = useCallback(
    (newFilters) => {
      setUiFilters((prevUiFilters) => ({
        ...prevUiFilters,
        ...newFilters,
      }));
      startTransition(() => {
        setDataFilters(newFilters);
      });
    },
    [setDataFilters]
  );

  const setAllFiltersDebounced = useCallback(
    (newFilters) => {
      setUiFilters((prevUiFilters) => ({
        ...prevUiFilters,
        ...newFilters,
      }));
      startTransition(() => {
        setDataFiltersDebounced(newFilters);
      });
    },
    [setDataFiltersDebounced]
  );

  return useMemo(
    () => ({
      uiFilters,
      filters,
      setFilters: setAllFilters,
      setFiltersDebounced: setAllFiltersDebounced,
    }),
    [filters, setAllFilters, setAllFiltersDebounced, uiFilters]
  );
}
