import { useEffect, useState } from "react";
import { useAuthActions, useAuthState } from "use-eazy-auth";
import styles from "./Login.module.css";

export default function Login() {
  const { loginLoading, loginError } = useAuthState();
  const { login, clearLoginError } = useAuthActions();

  // Clear login error when Login component unmount
  useEffect(() => () => clearLoginError(), [clearLoginError]);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  return (
    <form
      className="row h-100 w-100 d-flex align-items-center p-2 position-relative"
      onSubmit={(e) => {
        e.preventDefault();
        if (username !== "" && password !== "") {
          login({ username, password });
        }
      }}
    >
      <div className={`${styles.Slogan} position-absolute px-5 pt-0 pt-md-5`} style={{ top: 0 }}>
        <div>
          <div className="m-0 p-0">
            <div className={styles.SloganMilano}>Milano 0.18</div>
            <div className={styles.SubSlogan}>
              Servizi per il benessere <br /> di bambine e bambini, <br />{" "}
              ragazze e ragazzi
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 col-md-5 offset-md-4">
        <div className={styles.CardLogin}>
          <div className="card-body">
            <h2 className={styles.Login}>Login</h2>
            <div className={styles.InputLogin}>
              <label className={styles.LabelInput}>Username</label>
              <div>
                <input
                  name="email"
                  className="input-name w-100"
                  type="text"
                  autoComplete="off"
                  autoCorrect="off"
                  placeholder="| Inserisci il tuo username"
                  value={username}
                  onChange={(e) => {
                    clearLoginError();
                    setUsername(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className={styles.InputLogin}>
              <label className={styles.LabelInput}>Password</label>
              <input
                name="password"
                className="input-name w-100"
                type="password"
                placeholder="| Inserisci la password"
                autoComplete="off"
                autoCorrect="off"
                value={password}
                onChange={(e) => {
                  clearLoginError();
                  setPassword(e.target.value);
                }}
              />
            </div>
            {/* <div className={styles.PasswordDimenticata}>
              Password dimenticata?
            </div> */}
            <div className="d-flex justify-content-between mt-5">
              <div className={styles.ButtonCancel}>Annulla</div>
              <button
                className={
                  loginLoading
                    ? styles.ButtonConfirmDisabled
                    : styles.ButtonConfirm
                }
                disabled={loginLoading}
              >
                Conferma
              </button>
            </div>
            {loginError && (
              <div className="alert alert-danger mt-3">Wrong credentials</div>
            )}
          </div>
        </div>
      </div>
    </form>
  );
}
